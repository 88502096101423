import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Collapse,
  Card,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";
import SavedRows from "../../../components/SavedRows";
import ApiService from "../../../config/restapi";
import Select from "react-select";
import common from "../../../helpers/common";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { StartYear, EndYear } from "../../../helpers/constant_variables";

const years = [
  {start_year:undefined,end_year:undefined},
  {start_year:undefined,end_year:undefined},
  {start_year:undefined,end_year:undefined},
  {start_year:undefined,end_year:undefined},
]

const DegreeDropDownData = [
  {
    value: 1,
    label: "B.Tech/B.E.",
    courses: [
      { label: "Civil Engineering", value: 1 },
      { label: "Electrical Engineering", value: 2 },
      { label: "Mechanical Engineering", value: 3 },
      { label: "Computer Science Engineering", value: 4 },
      { label: "Artificial Intelligence", value: 5 },
      { label: "Electronics and Communications Engineering", value: 6 },
      { label: "Chemical Engineering", value: 7 },
      { label: "Marine Engineering", value: 8 },
      { label: "Metallurgical Engineering", value: 9 },
      { label: "Petroleum Engineering", value: 10 },
      { label: "Oher", value: 11 },
    ],
  },
  {
    value: 2,
    label: "B.A",
    courses: [
      { label: "BA English", value: 1 },
      { label: "BA English Literature", value: 2 },
      { label: "BA Psychology", value: 3 },
      { label: "BA History", value: 4 },
      { label: "BA Economics", value: 5 },
      { label: "BA Sociology", value: 6 },
      { label: "BA Political Science", value: 7 },
      { label: "BA Journalism and Mass Communication", value: 8 },
      { label: "BA LLB", value: 9 },
      { label: "Other", value: 10 },
    ],
  },
  {
    value: 3,
    label: "BSc",
    courses: [
      { label: "BSc Agriculture", value: 12 },
      { label: "BSc Biotechnology", value: 12 },
      { label: "BSc Zoology", value: 12 },
      { label: "BSc Clinical Research & Healthcare Management", value: 12 },
      { label: "BSc Microbiology", value: 12 },
      { label: "BSc Nursing", value: 12 },
      { label: "B.Sc.Physiotherapy", value: 12 },
      { label: "B.Sc.Radiology", value: 12 },
      { label: "B.Sc.Physics", value: 12 },
      { label: "B.Sc.Chemistry", value: 12 },
      { label: "B.Sc.Botany", value: 12 },
      { label: "B.Sc.IT", value: 12 },
      { label: "B.Sc.Computer Science", value: 12 },
      { label: "Oher", value: 12 },
    ],
  },
  {
    value: 4,
    label: "B.Com",
    courses: [
      { label: "B.Com(Economics)", value: 12 },
      { label: "B.Com(Accounting & Finance)", value: 12 },
      { label: "B.Com(Financial Markets)", value: 12 },
      { label: "B.Com(Banking & Insurance)", value: 12 },
      { label: "B.Com(Taxation)", value: 12 },
      { label: "B.com Banking and Insurance", value: 12 },
      { label: "Corporate Accountancy", value: 12 },
      { label: "Advertising and Brand Management", value: 12 },
      { label: "Principles and Practice of Accountancy", value: 12 },
      { label: "Oher", value: 12 },
    ],
  },
  {
    value: 5,
    label: "10th",
    courses: [
      { label: "Science", value: 1 },
      { label: "Other", value: 2 },
    ],
  },
  {
    value: 5,
    label: "12th",
    courses: [
      { label: "Computers", value: 1 },
      { label: "Biology", value: 1 },
      { label: "Commerce", value: 1 },
      { label: "Other", value: 2 },
    ],
  },
];
const EducationalDetails = (props) => {
  const [editIndex, setEditIndex] = useState(-1);
  const [educationalRows, setEducationalRows] = useState([
    {
      college: "",
      degree: "",
      percentage: "",
      course: "",
      start_year: "",
      grad_year: "",
      stillStudying: false,
    },
  ]);
  const [coursesList, setCoursesList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showOverlapError, setOverlapError] = useState(false);
  const [educationalFields, setEducationalFields] = useState([
    {
      college: undefined,
      degree: undefined,
      percentage: undefined,
      course: undefined,
      start_year: undefined,
      grad_year: undefined,
      stillStudying: false,
      key: "10th",
      accordianExpand: false,
      hideRow: false,
    },
    {
      college: undefined,
      degree: undefined,
      percentage: undefined,
      course: undefined,
      start_year: undefined,
      grad_year: undefined,
      stillStudying: false,
      key: "12th",
      accordianExpand: false,
      hideRow: false,
    },
    {
      college: undefined,
      degree: undefined,
      percentage: undefined,
      course: undefined,
      start_year: undefined,
      grad_year: undefined,
      stillStudying: false,
      key: "Graduation",
      accordianExpand: false,
      hideRow: false,
    },
    {
      college: undefined,
      degree: undefined,
      percentage: undefined,
      course: undefined,
      start_year: undefined,
      grad_year: undefined,
      stillStudying: false,
      key: "Post Graduation",
      accordianExpand: false,
      hideRow: false,
    },
  ]);
  const [errorInvalid, setErrorInvalid] = useState("");

  useEffect(() => {
    props.onNextClickEduDataCallBack(educationalRows, editIndex);
  }, [educationalRows, editIndex]);

  function checkInvalidity(item) {
    console.log("Validation", item);

    if (item.college) {
      var RegEx = /^(?![0-9]+$)[a-zA-Z0-9 ]{2,}$/;
      var Valid = RegEx.test(item.college);
      if (!Valid) {
        setErrorInvalid("Numbers are not allowed");
      }
      console.log(Valid);
    }
    return (
      !Valid ||
      !item.college ||
      !item.degreeObj ||
      !item.start_year ||
      (!item.stillStudying &&
        (!item.grad_year || item.grad_year < item.start_year))
    );
  }

  function handleAddEducationalRow(index) {
    if (index > -1) {
      let EducationLevelData = {};
      EducationLevelData =
        index === 0
          ? educationalFields[0]
          : index === 1
          ? educationalFields[1]
          : index === 2
          ? educationalFields[2]
          : educationalFields[3];
      console.log(EducationLevelData);
      let currentYears = years[index];

      let overlap = true;

      overlap = years.map((year,index1)=>{
        if(index1!==index){
          if(currentYears.start_year === year.start_year || currentYears.end_year === year.end_year){
            return false;
          }
          if(currentYears.start_year > year.start_year && currentYears.start_year < year.end_year){
            return false;
          }
          if(currentYears.end_year > year.start_year && currentYears.end_year < year.end_year){
            return false;
          }
        }
      })
      console.log(overlap);
      console.log(years);
      if(overlap.includes(false)){
        setOverlapError(true);
        return;
      }
      if (checkInvalidity(EducationLevelData)) {
        setShowError(true);
        return;
      }

      ApiService.saveEducationDetailsOnBoarding([EducationLevelData]).then(
        (res) => {
          if (res && res.status === 200 && res.data) {
            props.updateEducationData();
            let tempData = [...educationalFields];
            tempData[index].college = "";
            tempData[index].degree = "";
            tempData[index].degreeObj = "";
            tempData[index].course = "";
            tempData[index].courseObj = "";
            tempData[index].start_year = "";
            tempData[index].startYearObj = "";
            tempData[index].grad_year = "";
            tempData[index].gradYearObj = "";
            tempData[index].stillStudying = false;
            tempData[index]["hideRow"] = true;
            setEducationalFields(tempData);
            setShowError(false);
            setOverlapError(false);
            // const item1 = { degreeObj: '', courseObj: '', college: "", degree: "", course: "", start_year: "", grad_year: "", stillStudying: false };
            // let updateERow = [...educationalFields];
            // updateERow.splice(updateERow.length - 1, 1);
            // let addIdx = updateERow.length > 0 ? updateERow.length - 1 : 0;
            // updateERow.splice(addIdx, 0, item1);
            // if (editIndex > -1) setEditIndex(-1);
            // setEducationalFields(updateERow);
          }
        }
      );
    }
  }

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  const editEducationalRow = (data, index) => {
    if (index > -1) {
      const tempRowData = [...props.getSavedRows];
      const tempEduData = [...educationalFields];
      let degreeIndex = findWithAttr(
        DegreeDropDownData,
        "label",
        tempRowData[index].degree
      );
      tempRowData[index].degreeObj = DegreeDropDownData[degreeIndex];
      if (degreeIndex > -1) {
        const courseIndex = findWithAttr(
          DegreeDropDownData[degreeIndex].courses,
          "label",
          tempRowData[index].course
        );
        if (courseIndex > -1)
          tempRowData[index].courseObj =
            DegreeDropDownData[degreeIndex].courses[courseIndex];
        setCoursesList(DegreeDropDownData[degreeIndex].courses);
      }
      let startYearIndex = findWithAttr(
        StartYear,
        "label",
        tempRowData[index].start_year
      );
      tempRowData[index].startYearObj = StartYear[startYearIndex];

      let GardYearIndex = findWithAttr(
        EndYear,
        "label",
        tempRowData[index].grad_year
      );
      tempRowData[index].gradYearObj = EndYear[GardYearIndex];

      tempRowData[index].hideRow = false;
      console.log(tempRowData[index]);

      console.log(tempEduData);

      Object.assign(tempEduData[index], tempRowData[index] || {});

      setEducationalFields(tempEduData);
      setEditIndex(index);
    }
  };

  function deleteEducationalRow(type, index) {
    const tempERow = [...props.getSavedRows];
    const tempEduData = [...educationalFields];
    ApiService.deleteEducationDetailsOnBoarding({
      id: Number(tempERow[index].id),
    }).then((res) => {
      if (res && res.status === 200) {
        props.updateEducationData();
        tempEduData[index].hideRow = false;
        setEducationalFields(tempEduData);
      }
    });
  }

  const handleSaveEditedData = (index) => {
    const tempRowData = [...props.getSavedRows];
    const tempEduData = [...educationalFields];
    let obj = {
      ...tempEduData[index],
      id: tempRowData[editIndex].id,
    };
    let currentYears = years[index];

      let overlap = true;

      overlap = years.map((year,index1)=>{
        if(index1!==index){
          if(currentYears.start_year === year.start_year || currentYears.end_year === year.end_year){
            return false;
          }
          if(currentYears.start_year > year.start_year && currentYears.start_year < year.end_year){
            return false;
          }
          if(currentYears.end_year > year.start_year && currentYears.end_year < year.end_year){
            return false;
          }
        }
      })
      console.log(overlap);
      console.log(years);
      if(overlap.includes(false)){
        setOverlapError(true);
        return;
      }
    if (checkInvalidity(obj)) {
      setShowError(true);
      return;
    }

    ApiService.editEducationDetailsOnBoarding(obj).then((res) => {
      if (res && res.status === 200 && res.data) {
        props.updateEducationData();
        setEditIndex(-1);
        tempEduData[index].hideRow = true;
        tempEduData[index].college = "";
        tempEduData[index].degree = "";
        tempEduData[index].course = "";
        tempEduData[index].start_year = "";
        tempEduData[index].grad_year = "";
        tempEduData[index].stillStudying = false;
        tempEduData[index].degreeObj = "";
        tempEduData[index].courseObj = "";
        tempEduData[index].startYearObj = "";
        tempEduData[index].gradYearObj = "";
        tempEduData[index].UserId = "";
        tempEduData[index].id = "";
        setEducationalRows(tempEduData);
        setShowError(false);
        setOverlapError(false);
      }
    });
  };

  const handleChange = (selectedOption, index, type) => {
    console.log(educationalFields);
    if (type === "degree") {
      let tempRow = [...educationalFields];
      tempRow[index].degree = selectedOption.label;
      tempRow[index]["degreeObj"] = selectedOption;
      tempRow[index]["courseObj"] = "";
      setCoursesList(selectedOption.courses);
      setEducationalFields(tempRow);
    } else if (type === "course") {
      let tempRow = [...educationalFields];
      tempRow[index].course = selectedOption.label;
      tempRow[index]["courseObj"] = selectedOption;
      setEducationalFields(tempRow);
    } else if (type === "start_year") {
      let tempRow = [...educationalFields];
      tempRow[index].start_year = selectedOption.label;
      tempRow[index]["startYearObj"] = selectedOption;
      years[index].start_year = selectedOption.label;
      setEducationalFields(tempRow);
    } else if (type === "grad_year") {
      let tempRow = [...educationalFields];
      tempRow[index].grad_year = selectedOption.label;
      tempRow[index]["gradYearObj"] = selectedOption;
      years[index].end_year = selectedOption.label;
      setEducationalFields(tempRow);
    } else if (type === "course") {
      let tempRow = [...educationalFields];
      tempRow[index].course = selectedOption.label;
      setEducationalFields(tempRow);
    }
  };

  const onChangeEducationalDetails = (value, type, index) => {
    let tempRow = [...educationalFields];
    switch (type) {
      case "college":
        value = value.replace(/[^a-zA-Z\s]/gi, "");
        tempRow[index].college = common.trimStart(value);
        setErrorInvalid("");
        break;
      case "stillstudying":
        tempRow[index].stillStudying = value;
        if (value === true) {
          tempRow[index].grad_year = "";
          tempRow[index].gradYearObj = "";
        }
        break;
      case "percentage":
        // tempRow[index].percentage = value;
        break;
      default:
        break;
    }
    setEducationalFields(tempRow);
  };

  function ToggleAccordian(index) {
    if (index > -1) {
      let tempData = [...educationalFields];
      tempData[index].accordianExpand = !tempData[index].accordianExpand;
      setEducationalFields(tempData);
    }
  }

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={2} className="educational-details text-left">
        {props.getSavedRows &&
          props.getSavedRows.length > 0 &&
          props.getSavedRows.map((data, index) => {
            return (
              <SavedRows
                data={data}
                index={index}
                editRow={editEducationalRow}
                deleteRow={deleteEducationalRow}
                type="educational"
                key={index}
              />
            );
          })}

        {educationalFields &&
          educationalFields.map((item, index) => {
            return (
              <div
                key={index}
                className={`accordian-container mt-2 ${
                  item.hideRow ? "hide" : "show"
                }`}
              >
                <Card className="mb-1">
                  <CardHeader id="headingOne">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center font-weight-medium">
                        Qualification : {item.key}
                      </div>
                      <div className="d-flex align-items-center">
                        <span
                          onClick={() => {
                            ToggleAccordian(index);
                          }}
                          className={`font-size-18 cursor-pointer`}
                        >
                          {item.accordianExpand ? (
                            <i className="bx bxs-chevron-up text-muted mt-2"></i>
                          ) : (
                            <i className="mt-2 text-muted bx bxs-chevron-down"></i>
                          )}
                        </span>
                      </div>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={item.accordianExpand}>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label>School/College</Label>
                            <Input
                              type="text"
                              maxLength="40"
                              value={item.college}
                              onChange={(event) =>
                                onChangeEducationalDetails(
                                  event.target.value,
                                  "college",
                                  index
                                )
                              }
                            />
                            {showError && !item.college && (
                              <label
                                style={{ "margin-bottom": "0", color: "red" }}
                              >
                                College name cannot be blank.
                              </label>
                            )}
                            {errorInvalid && (
                              <label
                                style={{ "margin-bottom": "0", color: "red" }}
                              >
                                {errorInvalid
                                  ? errorInvalid
                                  : "College name cannot be blank."}
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label>Degree</Label>
                            <Select
                              className="select-container"
                              value={item.degreeObj}
                              onChange={(selectedOption) =>
                                handleChange(selectedOption, index, "degree")
                              }
                              options={DegreeDropDownData}
                              isSearchable
                              innerProps={{ className: "test" }}
                              placeholder="Select"
                            />
                            {showError && !item.degreeObj && (
                              <label
                                style={{ "margin-bottom": "0", color: "red" }}
                              >
                                Choose a degree from the list.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label>Course</Label>
                            <Select
                              className="select-container"
                              value={item.courseObj}
                              onChange={(selectedOption) =>
                                handleChange(selectedOption, index, "course")
                              }
                              options={coursesList}
                              isSearchable
                              innerProps={{ className: "test" }}
                              placeholder="Select"
                            />
                            {showError && !item.degreeObj && (
                              <label
                                style={{ "margin-bottom": "0", color: "red" }}
                              >
                                Choose a degree from the list.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label>Start Year</Label>
                            <Select
                              className="select-container"
                              value={item.startYearObj}
                              onChange={(selectedOption) =>
                                handleChange(
                                  selectedOption,
                                  index,
                                  "start_year"
                                )
                              }
                              options={StartYear}
                              isSearchable
                              innerProps={{ className: "test" }}
                              placeholder="Select"
                            />
                            {showError && !item.start_year && (
                              <label
                                style={{ "margin-bottom": "0", color: "red" }}
                              >
                                Start year cannot be blank.
                              </label>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="text-left">
                            <Label>End Year</Label>
                            <Select
                              className="select-container"
                              value={item.gradYearObj}
                              onChange={(selectedOption) =>
                                handleChange(selectedOption, index, "grad_year")
                              }
                              options={EndYear}
                              isSearchable
                              innerProps={{ className: "test" }}
                              placeholder="Select"
                              isDisabled={item.stillStudying}
                            />
                            {showError &&
                              !item.grad_year &&
                              !item.stillStudying && (
                                <label
                                  style={{ "margin-bottom": "0", color: "red" }}
                                >
                                  End year cannot be blank.
                                </label>
                              )}
                            {showError &&
                              item.grad_year &&
                              item.start_year &&
                              item.grad_year < item.start_year && (
                                <label
                                  style={{ "margin-bottom": "0", color: "red" }}
                                >
                                  End year cannot be smller than Start year.
                                </label>
                              )}
                              {
                                showOverlapError && (
                                  <label
                                  style={{ "margin-bottom": "0", color: "red" }}
                                >
                                  Overlapping Years with other Degree
                                </label>
                                )
                              }

                            
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6" className="mb-2">
                          <div className="cursor-pointer add-other text-left">
                            {editIndex > -1 ? (
                              <button
                                className="waves-effect waves-light btn btn-outline-shadow w-md mr-2"
                                type="button"
                                onClick={() => handleSaveEditedData(index)}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                className="waves-effect waves-light btn btn-outline-shadow w-md mr-2"
                                type="button"
                                onClick={() => handleAddEducationalRow(index)}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Collapse>
                  <hr className="mt-1 mb-1"></hr>
                </Card>
              </div>
            );
          })}
      </TabPane>
    </TabContent>
  );
};

export default EducationalDetails;
