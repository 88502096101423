import React, { useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import cloudOutline from "../../assets/images/cloud-outline.svg";
import pdf_icon from "../../assets/images/pdf_icon.png";
import s3api from "../../helpers/s3apis";
import ApiService from "../../config/restapi";

const UploadFile = (props) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  function validateFileExtention(files) {
    var ext = files && files[0].name.split(".").pop();
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      return true;
    }
    return false;
  }
  function removeResumeFile() {
    setselectedFiles([]);
    props.removeResumeFile && props.removeResumeFile();
    props.setSelectedFiles && props.setSelectedFiles(undefined);
  }
  function handleAcceptedFiles(files) {
    if (validateFileExtention(files)) {
      props.uploadResumeCallback &&
        props.uploadResumeCallback(files.length && files[0]);
      props.setSelectedFiles && props.setSelectedFiles(files[0]);
      setselectedFiles(files);
      console.log("dfdfj");
      console.log(files[0].name);

      var ext = files[0].name.split(".").pop();
      if (files[0].size < 5242880) {
        s3api.upload("resume", ext, files[0]);
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
      }
    } else {
      toastr.error("Please Upload Valid Resume Format(Only pdf,docx and doc are supported)", "", { timeOut: 2000 });
    }
  }

  const files = selectedFiles.map((file) => (
    <span key={file.name}>{file.name}</span>
  ));

  return (
    <div className="upload-file">
      <Dropzone
        onDrop={(acceptedFiles) => {
          handleAcceptedFiles(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dz-message needsclick">
                <div>
                  {" "}
                  <img src={cloudOutline} alt="cloud" />{" "}
                </div>
                <div className="font-size-16 mt-2">
                  {/* <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i> */}
                  Drag and drop file here
                </div>
                <div className="mt-2 font-size-12 mb-2">Or</div>
                <div className="upload-resume-text">
                  <button
                    className="btn bg-green waves-effect waves-light w-md mr-2 font-size-16"
                    type="button"
                  >
                    {props.label ? props.label : "Upload Resume"}
                  </button>
                </div>
              </div>
            </div>
            <aside>
              {files.length != 0 && (
                <div className="apply_resume_wrapper">
                  <div className="company-logo-img rounded mx-auto d-block">
                    <img src={pdf_icon} alt="" className="" />
                    <span
                      className="img-close"
                      onClick={() => removeResumeFile()}
                    >
                      &times;
                    </span>
                  </div>
                  <div className="mt-2">
                    <p>{files}</p>
                  </div>
                </div>
              )}
            </aside>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadFile;
