import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Alert, Row, Col } from "reactstrap";
import Card from "../../components/Cards/Card";
import GoogleCard from "../../components/Cards/GoogleCard";
import FaceCard from "../../components/Cards/Facecard";
import SideNav from "../../components/SideNav";

//Redux Actions
import {
  fetchDashboardDetails,
  fetchJobs,
  getBgDetails,
} from "../../redux/actions/dashboard";
const Dashboard = (props) => {

  useEffect(() => {
    props.fetchDashboardDetails();
    // props.getBgDetails();
    props.fetchJobs(props.jobs.length);

  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  })

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight ||
      props.loading_jobs ||
      props.all_jobs_fetched
    )
      return;
    props.fetchJobs(props.jobs.length);
  };


  if (props.loading) {
    return <Fragment></Fragment>;
  } else if (props.error != null) {
    //Replace with network error
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="d-flex  justify-content-center">
              <Alert color="danger">{props.error}</Alert>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
  else if (props.user && !props.userDetails.is_profile_created) {
    props.history.push("/aboutyourself")
  }

  console.log(props.recommendedJobs.filter((e) => { return (!(e === null || e === undefined)); }).length);
  console.log(props.jobs);
  return (
    <React.Fragment>
      <div className="page-content dashboard-page">
        <Container fluid>
          <Row className="green-text-color">
            <Col md="5" xs="12" xl={3} lg={4} >
              <SideNav user={props.userDetails} props={props.bg_details} />
            </Col>
            <Col sm="8" md="7" xl={9} lg={8}>
              <div className="f-18 mb-4">Recommended by Zunavish</div>
              <div className="row mb-2">
                {

                  props.recommendedJobs.filter((e) => { return (e !== null && e !== undefined); }).length !== 0 ? (
                    props.recommendedJobs.map((e, index) => {
                      if (e !== null) {
                        return (
                          <div key={index} className={`col-sm-6 col-md-6 col-xl-4 col-lg-6 col-xs-12 pr-4`}>
                            <Card job={e} isCompanyLogo={e.organization?e.organization.logo_url:false} />
                          </div>
                        );
                      }
                      else {
                        return (<div key={index}></div>);
                      }

                    })
                  ) : (
                    <div className='col font-size-14 no-result ml-2'>No recommended Jobs right now</div>
                  )}
              </div>
              <div className="mt-2 f-18 mb-2">Based on Your Profile</div>
              <br></br>
              <div className="row">
                {props.jobs.length != 0 ? (
                  props.jobs.map((e, index) => {

                    return (
                      <div key={index} className={`col-sm-6 col-md-6 col-xl-4 col-lg-6 col-xs-12 pr-4 pb-4`}>
                        <Card job={{ ...e.job }} isCompanyLogo={e.job.organization?e.job.organization.logo_url:false} />
                      </div>
                    );
                  })
                ) : (
                  <div className='col font-size-14 no-result ml-2'>No Jobs right now</div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  //return {...state.dashboard,jobs : [],recommendedJobs:[]}
  return state.dashboard;
};
const mapDispatchToProps = {
  fetchDashboardDetails,
  fetchJobs,
  getBgDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
