import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "reactstrap";
import AboutYourselfWizard from './AboutYourselfWizard';
import UploadFile from '../../../components/UploadFile';
import MoreQuestionsWizard from '../MoreQuestionsWizard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logo from '../../../assets/images/Zunavish_Logo_SVG.svg';
import { getAllUserData } from '../../../redux/actions/user';
import ApiService from '../../../config/restapi';

const AboutYourself = (props) =>{
const [isManualDetailSection, setIsManualDetailSection] = useState(false)
const [isCompleteProfile, setIsCompleteProfile] = useState(false);

    function fillDetailsManual(){
        setIsManualDetailSection(true);
    }

    function uploadResumeCallback(f){
        const formData = new FormData();
        formData.append("file", f);
        ApiService.uploadJobResume(formData).then((res) => {
            if (res && res.data) {
                ApiService.editProfileData({resume_url:res.data.data.Location}).then((res) => {
                    setIsManualDetailSection(true);
                });

            }});
    }

    function removeResumeFile() {
        if(props.user.resume_url) {
            ApiService.editProfileData({resume_url:""}).then((res) => {
            }); 
        }
    }

    function onCompleteAboutYourselfCallBack(){
        setIsCompleteProfile(true);
    }

    useEffect(() => {
        props.getAllUserData();

     }, []);
    return(
        <React.Fragment>
            <div className="page-content mt-0">
                <div className='logo-fixed'>
                    <img src={logo} alt='logo'></img>
                </div>
                <Container className="about-page-auth" fluid>
                    <Row>
                        <Col xs="12" xl="3" sm="3"></Col>
                        <Col xs="12" xl="6" sm="6" className="text-center">
                            <h5 className="mb-0 font-size-27">Welcome{props.user && props.user.name?<span>{`, ${props.user.name}` }</span>:" to Zunavish"} </h5>
                            <div className="mt-3">
                                {!isManualDetailSection ? <p className="pb-3 font-size-16"> Upload your resume and make the process of filling details easy</p> : 
                                    (!isCompleteProfile ? <p className="pb-3 font-size-16"> Complete your profile and start exploring jobs that suit you the best </p> :
                                    <p className="pb-3 font-size-11"> You have successfully completed filling your profile. Answer these simple questions and start exploring the platform </p>) }
                            </div>
                            {!isManualDetailSection ? <UploadFile
                                    title="Upload your resume and make the process of filling details easy"
                                    uploadResumeCallback={(f)=>uploadResumeCallback(f)}
                                    removeResumeFile={()=>removeResumeFile()}
                                    previousResumeurl={props.user.resume_url}
                                /> : (!isCompleteProfile ? <AboutYourselfWizard
                                    title="Complete your profile and get more opportunities to connect with people"
                                    uploadResumeCallback={(f)=>uploadResumeCallback(f)}
                                    onCompleteAboutYourselfCallBack={()=>onCompleteAboutYourselfCallBack()}
                                    previousResumeurl={props.user.resume_url}
                                /> : <MoreQuestionsWizard/>)
                            }
                            {!isManualDetailSection ?
                                <div className="text-center mt-3">
                                    <button
                                        className="waves-effect waves-light btn btn-outline-green font-size-16"
                                        type="submit"
                                        onClick={() => fillDetailsManual()}
                                    >
                                        I'll fill details manually
                                    </button>
                                </div> : ''
                            }
                        </Col>
                        <Col xs="12" xl="3" sm="3"></Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.user
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        getAllUserData:getAllUserData
      },
      dispatch
    );
  };


export default connect(mapStateToProps,mapDispatchToProps)(AboutYourself);