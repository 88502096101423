import api from "../../config/axios";
import {
  DASHBOARD_JOBS_ERROR,
  DASHBOARD_JOBS_LOADING,
  DASHBOARD_JOBS_SUCCESS,
  DASHBOARD_PROFILE_LOADING,
  DASHBOARD_PROFILE_SUCCESS,
  DASHBOARD_PROFILE_ERROR,
  DASHBOARD_UPLOADING,
  DASHBOARD_UPLOAD_ERROR,
  DASHBOARD_UPLOAD_SUCCESS,
  DASHBOARD_BACKGROUND_LOADING,
  DASHBOARD_BACKGROUND_SUCCESS,
  DASHBOARD_BACKGROUND_ERROR,
  DASHBOARD_DELETE_SUCCESS,
  DASHBOARD_DELETE_ERROR,
  DASHBOARD_DELETE_LOADING,
  UPDATEPHOTO_ERROR,
  UPDATEPHOTO_SUCCESS,
  UPDATEPHOTO
} from "../dashboard";

export const fetchDashboardDetails = () => async (dispatch) => {
  dispatch({
    type: DASHBOARD_PROFILE_LOADING,
  });
  const profileRequest = api.get("/api/dashboard", {
    headers: {
      token: `${localStorage.getItem("token")}`,
    },
  });
  // const jobsRequest = api.get("/api/dashboard/jobs", {
  //   headers: {
  //     token: `${localStorage.getItem("token")}`,
  //   }
  // });
  try {
    const responses = await Promise.all([profileRequest]);
    if (responses[0].status === 200) {
      const user = {
        ...responses[0].data.user,
        percentComplete: responses[0].data.perCentComplete,
      };
      console.log(responses);
      const recommendedJobs = responses[0].data.rec_jobs;
      // const jobs = responses[1].data.jobs;
      dispatch({
        type: DASHBOARD_PROFILE_SUCCESS,
        payload: {
          user,
          recommendedJobs,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: DASHBOARD_PROFILE_ERROR,
      payload: "Something went wrong. Please Try again Later",
    });
  }
};

export const getBgDetails = () => async (dispatch) => {
  dispatch({
    type: DASHBOARD_BACKGROUND_LOADING,
  });
  try {
    const response = await api.get(`/api/dashboard/documents`, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: DASHBOARD_BACKGROUND_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: DASHBOARD_BACKGROUND_ERROR,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: DASHBOARD_BACKGROUND_ERROR,
      payload: "Something went wrong. Please Try again Later",
    });
  }
};

export const deleteDoc = (id, type) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_DELETE_LOADING,
    });
    const data = { id };
    const response = await api.post(`/api/dashboard/delete/documents`, data, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });
    // const response = await api.delete("/api/dashboard/delete/documents", {
    //   data: { foo: "bar" },
    //   headers: {
    //     token: `${localStorage.getItem("token")}`,
    //   },
    // });
    const payload = {
      message: "Deleted",
      type,
    };
    if (response.status === 200) {
      dispatch({
        type: DASHBOARD_DELETE_SUCCESS,
        payload,
      });
    } else {
      dispatch({
        type: DASHBOARD_DELETE_ERROR,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: DASHBOARD_DELETE_ERROR,
      payload: "Something went wrong. Please Try again Later",
    });
  }
};

export const updatephoto = (data) => async (dispatch) => {
  dispatch({
    type: UPDATEPHOTO,
  });

  try {
    const response = await api.post(
      `api/profile/add/profile_image`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch({
        type: UPDATEPHOTO_SUCCESS,
        payload: response.data.link,
      });
    } else {
      dispatch({
        type: UPDATEPHOTO_ERROR,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: UPDATEPHOTO_ERROR,
      payload: "Something went wrong. Please Try again Later",
    });
  }
};
export const uploadDoc = (formData, doctype) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_UPLOADING,
  });

  try {
    const response = await api.post(
      `/api/dashboard/upload/documents`,
      formData,
      {
        params: {
          doctype,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          token: `${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch({
        type: DASHBOARD_UPLOAD_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: DASHBOARD_UPLOAD_ERROR,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: DASHBOARD_UPLOAD_ERROR,
      payload: "Something went wrong. Please Try again Later",
    });
  }
};
export const fetchJobs = (offset) => async (dispatch) => {
  dispatch({
    type: DASHBOARD_JOBS_LOADING,
  });
  try {
    const jobsRequest = await api.get(`/api/dashboard/jobs?offset=${offset}`, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });
    if (jobsRequest.status === 200) {
      dispatch({
        type: DASHBOARD_JOBS_SUCCESS,
        payload: {
          jobs: jobsRequest.data.jobs,
        },
      });
    } else {
      dispatch({
        type: DASHBOARD_JOBS_ERROR,
        payload: { error: "Unable to load dfgjobs" },
      });
    }
  } catch (e) {
    dispatch({
      type: DASHBOARD_JOBS_ERROR,
      payload: { error: "Unable to load jobs" },
    });
  }
};
// export const userdetail = () => async (dispatch) => {
//   dispatch({
//     type: USER_DETAILS_LOADING,
//   });
//   try {
//     const response = await api.get("/api/users/user", {
//       headers: {
//         token: `${localStorage.getItem("token")}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch({
//         type: USER_DETAILS,
//         payload: response.data,
//       });
//     } else {
//       dispatch({
//         type: USER_DETAILS_ERROR,
//         payload: response.data,
//       });
//     }
//   } catch (e) {
//     dispatch({
//       type: USER_DETAILS_ERROR,
//       payload: e.data,
//     });
//   }
// };
// export const jobs = () => async (dispatch) => {
//   dispatch({
//     type: DASHBOARD_JOBS_LOADING,
//   });
//   try {
//     const response = await api.get("/api/jobs/", {
//       headers: {
//         token: `${localStorage.getItem("token")}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch({
//         type: JOBS,
//         payload: response.data,
//       });
//     } else {
//       dispatch({
//         type: JOBS_ERROR,
//         payload: response.data,
//       });
//     }
//   } catch (e) {
//     dispatch({
//       type: JOBS_ERROR,
//       payload: e.data,
//     });
//   }
// };
// export const recommendedjobs = () => async (dispatch) => {
//   dispatch({
//     type: RECOMMENDED_JOBS_LOADING,
//   });
//   try {
//     const response = await api.get("/api/jobs/", {
//       headers: {
//         token: `${localStorage.getItem("token")}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch({
//         type: JOBS_RECOMMENDED,
//         payload: response.data,
//       });
//     } else {
//       dispatch({
//         type: RECOMMENDED_JOBS_ERROR,
//         payload: response.data,
//       });
//     }
//   } catch (e) {
//     dispatch({
//       type: RECOMMENDED_JOBS_ERROR,
//       payload: e.data,
//     });
//   }
// };
// export const jobdetail = (id) => async (dispatch) => {
//   dispatch({
//     type: JOB_DETAIL_LOADING,
//   });
//   try {
//     const response = await api.get(`/api/jobs/${id}`, {
//       headers: {
//         token: `${localStorage.getItem("token")}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch({
//         type: JOB_DETAIL,
//         payload: response.data,
//       });
//     } else {
//       dispatch({
//         type: JOB_DETAIL_ERROR,
//         payload: response.data,
//       });
//     }
//   } catch (e) {
//     dispatch({
//       type: JOB_DETAIL_ERROR,
//       payload: e.data,
//     });
//   }
// };
// export const jobapply = (id) => async (dispatch) => {
//   dispatch({
//     type: JOB_APPLY_LOADING,
//   });
//   try {
//     const response = await api.get(`/api/jobs/${id}`, {
//       headers: {
//         token: `${localStorage.getItem("token")}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch({
//         type: JOB_APPLY,
//         payload: response.data,
//       });
//     } else {
//       dispatch({
//         type: JOB_APPLY_ERROR,
//         payload: response.data,
//       });
//     }
//   } catch (e) {
//     dispatch({
//       type: JOB_APPLY_ERROR,
//       payload: e.data,
//     });
//   }
// };
