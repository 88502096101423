import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import ResumeRow from "../../components/ResumeRow";
import divider from '../../assets/images/divider.svg';
import api from '../../config/axios';
import toastr from 'toastr';


const ResumeDashboard = (props) => {
  const [resumes, setResumes] = useState(undefined);

  const createResume = () => {
    api.post("/api/resume/new", {
      theme: "default",
      about: "",
      resume_name: "New Resume"
    }, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        props.history.push(`/resumebuilder/${res.data.id}`);
      }
    });
  }
  const editResume = (id) => {
    console.log(id);
    props.history.push(`/resumebuilder/${id}`);
  }
  const deleteResume = (id) => {
    api.delete(`/api/resume/${id}`, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    },
    ).then((res) => {
      if (res.status === 200) {
        let newResumes = resumes.filter(e => e.id != id);
        console.log(newResumes);
        setResumes(newResumes);
      }
      else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });

      }
    }).catch((e) => {
      toastr.error("Something Went Wrong", "", { timeOut: 2000 });

    });
  }
  useEffect(() => {
    api.get("/api/resume", {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setResumes(res.data);
      }
      else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }

    }).catch((e) => {
      console.log(e.response.data.message);
      if (e.response.data.message === "No resumes found") {
        setResumes([]);
      }
      else {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }

    });
  }, []);


  const emptyPage = () => {
    return (<React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="9" md={{ span: 1, offset: 1 }}>
              <p className="resume-heading mb-0"> Build Your Resume </p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="10" md={{ span: 1, offset: 1 }}>
              <div className="top-border-box-g" style={{ backgroundColor: "#E2EAE7" }}>

                <div className="font-size-14 pt-1 font-weight-100" style={{ color: "#111111", textAlign: "justify" }}>
                  We help you be a confident YOU! Our resume writers who are skilled and adept would help you stay ahead of the competition.
                  <br />
                  Our bespoke services ensure relevance and result as we focus on your strengths, attributes & experiences.
                  <br />
                  Features:
                  <br />
                  <ul>
                    <li>Industry specific</li>
                    <li>Formatted versions</li>
                    <li>You can choose to review</li>
                    <li>Standard templates or customized version can be chosen</li>
                    <li>Valid upto 1 year</li>
                    <li>Personal confidentiality assured</li>
                  </ul>

                </div>

              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="10" md={{ span: 1, offset: 1 }}>
              <Card>
                <CardBody className="pt-3 pb-0">
                  <Row className="m-0 mt-2 mb-4" style={{ justifyContent: "center" }}>
                    <Col style={{ flex: "11" }} className="added-row">
                      <Row>
                        <Col lg="8" className="">
                          <h5 className="font-size-18"> Resume Builder </h5>

                          <div className="font-weight-medium">
                            <span> {props.data && props.data.degree} </span> {props.data && props.data.course && <span> | {props.data.course} </span>}
                          </div>

                          <div className="text-muted font-weight-light font-size-14 mt-3">
                            Build your resume by providing details and download by applying premium resume templates
                          </div>
                        </Col>
                      </Row>

                      <Row style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}>
                        <Col
                          md={{ span: 1, offset: 8 }}
                        >
                          <button
                            type="button"
                            onClick={createResume}
                            className={'btn font-light-green btn-outline-light-green waves-effect waves-light w-lg mr-2'}
                            data-dismiss="modal"
                          >
                            Build Resume
                          </button>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="mobile-resume-builder" style={{ flex: "1" }}>
                      <div style={{
                        display: "flex",
                        justifyContent: "space-around",
                        justifyItems: "center",
                        alignItems: "center",
                        height: "100%",
                      }}>
                        <img src={divider} />
                      </div>
                    </Col>
                    <Col style={{ flex: "11" }} className="added-row">
                      <Row>
                        <Col lg="8" className="">
                          <h5 className="font-size-18"> Enhance your resume </h5>

                          <div className="font-weight-medium">
                            <span> {props.data && props.data.degree} </span> {props.data && props.data.course && <span> | {props.data.course} </span>}
                          </div>

                          <div className="text-muted font-weight-light font-size-14 mt-3">
                            Upload your resume and get them reviewed and enhanced by professionals at very affordable prices
                          </div>
                        </Col>
                      </Row>
                      <Row style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}>
                        <Col
                          md={{ span: 1, offset: 8 }}
                        >
                          <button
                            disabled
                            type="button"
                            className={'btn font-light-green btn-outline-light-green waves-effect waves-light w-lg mr-2'}
                            data-dismiss="modal"
                          >
                            Enhance Resume
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>);
  }
  const filledPage = () => {
    return (<React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="align-items-center">
            <Col>
              <p className="resume-heading mb-0" style={{ verticalAlign: "middle" }}> Your Resumes </p>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}>
              <button
                className={'btn font-dark-green btn-outline-green waves-effect waves-light w-lg mr-2'}
                type="submit"
                disabled

              >Enhance Resume
              </button>
              <button className="btn bg-green waves-effect waves-light w-lg"
                type="submit"
                onClick={createResume}
              > Build New Resume</button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="pt-3 pb-0">
                  {resumes.map((e) => <ResumeRow data={e} editResume={editResume} deleteResume={deleteResume} />)}
                </CardBody>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>);
  }
  if (resumes === undefined) {
    return ("");
  }
  else if (resumes.length === 0)
    return (
      emptyPage()
    );
  else {
    return (filledPage());
  }
};

export default ResumeDashboard;
