import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Label,
  InputGroup,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
//Redux actions
import {
  signupWithEmail,
  signupWithGoogle,
  signupwithLinkedIn,
  newPage,
} from "../../redux/actions/auth";
import { GoogleLoginButton } from "../../components/AuthButton/Google";
import { LinkedInLoginButton } from "../../components/AuthButton/LinkedIn";

import text_logo from "../../assets/images/Zunavish Logo_white_svg.svg";
import logo from "../../assets/images/Zunavish_text.svg";

const SignUp = (props) => {
  useEffect(() => props.newPage(), []);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState();
  // handleValidSubmit

  function handleValidSubmit(event, values) {
    props.signupWithEmail(email, props.history);
  }
  function responseGoogle(response) {
    const tokenid = response.tokenObj.id_token;
    props.signupWithGoogle(tokenid, props.history);
  }
  function responseLinkedin(response) {
    console.log(response);
    props.signupwithLinkedIn(response.code, props.history);
    // const tokenid = response.tokenObj.id_token;
    // props.signupWithGoogle(tokenid, props.history);
  }

  function onFailure(response) {
    console.log(response);
    setCode(response.code);
    // const tokenid = response.tokenObj.id_token;
    // props.signupWithGoogle(tokenid, props.history);
  }

  return (
    <React.Fragment>
      <div className="account-pages">
        <div className="float-left left-section">
          <Row className="">
            <Col md={12} lg={12}>
              <div className="pl-4 pr-4 pb-2">
                <h5 className="font-size-36 font-weight-bold login-heading">
                  Join <img src={logo} alt="" height="35" className="pb-2" />
                </h5>
                <div className="text-left signup-description custom-signup-description font-size-16">
                  Create an account to receive great stories in your inbox,
                  personalize your homepage, and follow authors and topics that
                  you love.
                </div>
              </div>

              <div className="pl-4 pr-4">
                <AvForm
                  className="form-horizontal login-form"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v);
                  }}
                >
                  {props.error && props.error ? (
                    <Alert color="danger">{props.error}</Alert>
                  ) : null}
                  {/* <AvField name="email" label="Email" className="form-control" placeholder="Eg:john@gmail.com"
                                        type="email" required onChange={({ target }) => { setEmail(target.value); }} /> */}
                  <AvGroup>
                    <Label for="email" className="font-dark-green">
                      Email
                    </Label>
                    <InputGroup>
                      <AvInput
                        name="email"
                        type="email"
                        placeholder="Eg: john@gmail.com"
                        required
                        onChange={({ target }) => {
                          target.value = target.value
                            .replace(/[^\w\s\.\,@]/gi, "")
                            .trimStart();
                          setEmail(target.value);
                        }}
                      />

                      <AvFeedback>{email===""?"Email cannot be blank.":"Invalid email."}</AvFeedback>
                    </InputGroup>
                  </AvGroup>
                  <div className="mt-3 text-left pb-3 border-bottom auth-form-submit">
                    <button
                      className="btn bg-green waves-effect waves-light w-lg mr-2"
                      type="submit"
                    >
                      Continue
                    </button>
                    <span
                      className="mr-2"
                      style={{ color: "#97A4B0", fontSize: "12px" }}
                    >
                      Or
                    </span>
                    <GoogleLoginButton onSuccess={responseGoogle} />
                    <LinkedInLoginButton onSuccess={responseLinkedin} />
                  </div>

                  <div
                    className="mt-2 "
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: "300",
                      color: "#174731",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      to="/login"
                      className="font-weight-semibold green-text-color"
                    >
                      Sign In
                    </Link>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Row>
        </div>
        <div className="float-left right-section">
          <div className="text-container">
            <div className="company-text-bold">
              <img src={text_logo} alt="text" />
            </div>
            <div className="mt-2 text-center">
              {/* vivify your business and People */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};
const mapDispatchToProps = {
  signupWithEmail,
  signupWithGoogle,
  signupwithLinkedIn,
  newPage,
};

// export default withRouter(SignUp);
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(SignUp));
