import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from "reactstrap";
import TextArea from '../../components/TextArea';


const EditBioModal = (props) => {

    const [biovalue, setBiovalue] = useState();
    const [bioCharComplete, setBioCharComplete] = useState();

    const onChangeTextareaHandler = (event) => {
        // const charCount = event.target.value.length;
        event.target.value = event.target.value.replace(/[^\w\s]/gi, '')

        var regex = /\s+/gi;
        var WordCount = event.target.value === "" ? 0 : event.target.value.trim().replace(regex, ' ').split(' ').length;
        if (WordCount <= 500) {
            setBiovalue(event.target.value);
            setBioCharComplete(WordCount);
        }
    }

    useEffect(() => {
        if (props.editedData) {
            setBiovalue(props.editedData.val);
        }
    }, [props.editedData])

    return (
        <Modal
            isOpen={props.modal_center}
            centered={props.centered}
            className="edit-bio-modal"
            size="lg"
        >
            <div className="modal-header">
                <div className="modal-title mt-0 font-weight-medium" id="myModalLabel">
                    Edit Bio
                </div>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="">
                    <Col lg="12" >
                        <TextArea
                            labelName="Tell us about yourself"
                            onChangeTextareaHandler={(event) => onChangeTextareaHandler(event)}
                            isCharLimitShow={true}
                            charlimitLength="500"
                            value={biovalue}
                            charComplete={bioCharComplete}
                            placeholder="Let others know what type of person you are"
                        />
                        {!isNaN(biovalue?.trim()) && (
                            <label style={{ "margin-bottom": "0", color: "red" }}>
                                Bio cannot be Number.
                            </label>
                        )}
                    </Col>

                </Row>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn bg-cancel btn-lg  waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn bg-light-green btn-lg waves-effect waves-light"
                    disabled={!isNaN(biovalue?.trim()) || !biovalue ? true : false}
                    onClick={() => props.onModalSubmit(biovalue)}
                >Save Changes</button>

            </div>
        </Modal>
    );
}

export default EditBioModal;