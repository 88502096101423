import api from "../../config/axios";
import {
    SIGNUP_EMAIL,
    SIGNUP_GOOGLE,
    CREATE_PASSWORD,
    FORGOT_PASSWORD,
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_SET_SUCCESS,
    NEW_PAGE,
} from "../auth";

export const signupWithEmail = (email, history) => async (dispatch) => {
    localStorage.setItem("token", "");
    dispatch({
        type: SIGNUP_EMAIL,
    });
    const response = await api
        .post("api/users/signup/email/", { email, role: "candidate" })
        .then((response) => {
            // console.log(response);
            dispatch({
                type: SIGNUP_EMAIL,
                payload: email,
            });
            if (response.status === 200 && response.data.success === "true") {
                history.push("/verification");
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: response.data.message,
                });
            }
        })
        .catch((err) => {
            // console.log("   ");
            // console.log(err.response);
            if (err.response) {
                dispatch({
                    type: API_ERROR,
                    payload: err.response.data.message,
                });
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: "Unable to reach to Servers",
                });
            }
        });
};
export const forgotpassword = (email, history) => async (dispatch) => {
    localStorage.setItem("token", "");
    dispatch({
        type: FORGOT_PASSWORD,
    });
    const response = await api
        .post("api/users/password/reset/", { email, role: "candidate" })
        .then((response) => {
            // console.log(response);
            dispatch({
                type: FORGOT_PASSWORD,
                payload: email,
            });
            if (response.status === 200 && response.data.success === "true") {
                history.push("/passwordreset");
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: response.data.message,
                });
            }
        })
        .catch((err) => {
            // console.log(err.response);
            if (err.response) {
                dispatch({
                    type: API_ERROR,
                    payload: err.response.data.message,
                });
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: "Unable to reach to Servers",
                });
            }
        });
};
export const newPage = () => (dispatch) => {
    dispatch({
        type: NEW_PAGE,
    });
};

export const setpassword = (
    password,
    confirmpassword,
    token,
    history
) => async (dispatch) => {
    dispatch({
        type: PASSWORD_SET_SUCCESS,
    });

    try {
        const response = await api.post("api/users/signup/email/check_password/", {
            password: password,
            confirmPassword: confirmpassword,
            token: token,
            role: "candidate",
        });
        if (response.status === 200) {
            if (response.data.success) {
                localStorage.setItem("token", response.data.authToken);
                history.push("/aboutyourself");
            } else {
                dispatch({
                    type: API_ERROR,
                    payload:response.data.reason || response.data.message
                });
            }
            // history.push('/aboutyourself');
        } else {
            dispatch({
                type: API_ERROR,
            });
        }
    } catch {
        dispatch({
            type: API_ERROR,
        });
    }
};
export const ResetPassword = (
    password,
    confirmpassword,
    token,
    history
) => async (dispatch) => {
    dispatch({
        type: PASSWORD_RESET_SUCCESS,
    });

    try {
        const response = await api.post(
            "api/users/password/reset/check_password/",
            {
                password: password,
                confirmPassword: confirmpassword,
                token: token,
                role: "candidate",
            }
        );
        if (response.status === 200) {
            if (response.data.success) {
                localStorage.setItem("token", response.data.authToken);
                history.push("/dashboard");
            } else {
                dispatch({
                    type: API_ERROR,
                });
            }
            // history.push('/aboutyourself');
        } else {
            dispatch({
                type: API_ERROR,
            });
        }
    } catch {
        dispatch({
            type: API_ERROR,
        });
    }
};

export const signupWithGoogle = (idToken, history) => async (dispatch) => {
    dispatch({
        type: SIGNUP_GOOGLE,
    });

    const response = await api
        .post("api/users/signup/google/", {
            idToken,
            role: "candidate",
        })
        .then((res) => {
            const token = res.data.authToken;
            localStorage.setItem("token", token);
            history.push("/aboutyourself");
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: API_ERROR,
                    payload: err.response.data.message,
                });
            } else if (err.request) {
                // Request was made but no response
                dispatch({
                    type: API_ERROR,
                    payload: "Unable to reach to servers",
                });
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: "Unable to reach to servers",
                });
            }
        });
};
export const signupwithLinkedIn = (idToken, history) => async (dispatch) => {
    dispatch({
        type: SIGNUP_GOOGLE,
    });

    const response = await api
        .get(`api/linkedin/?code=${idToken}&role=candidate`)
        .then((res) => {
            const token = res.data.authToken;
            localStorage.setItem("token", token);
            history.push("/aboutyourself");
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: API_ERROR,
                    payload: err.response.data.message,
                });
            } else if (err.request) {
                // Request was made but no response
                dispatch({
                    type: API_ERROR,
                    payload: "Unable to reach to servers",
                });
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: "Unable to reach to servers",
                });
            }
        });
};

export const loginwithGoogle = (idToken, history) => async (dispatch) => {
    dispatch({
        type: SIGNUP_GOOGLE,
    });
    const response = await api
        .post("api/users/login/google/", {
            idToken,
            role: "candidate",
        })
        .then((res) => {
            const token = res.data.authToken;
            localStorage.setItem("token", token);
            if (res.data.is_profile_created) {
                history.push("/dashboard");
            } else {
                history.push("/aboutyourself");
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 404) {
                    dispatch({
                        type: API_ERROR,
                    });
                } else {
                    dispatch({
                        type: API_ERROR,
                    });
                }
            } else if (err.request) {
                dispatch({
                    type: API_ERROR,
                });
                // Request was made but no response
                console.log(err.request);
            } else {
                dispatch({
                    type: API_ERROR,
                });
                console.log(err.message);
            }
        });
};
export const loginwithLinkedIn = (idToken, history) => async (dispatch) => {
    dispatch({
        type: SIGNUP_GOOGLE,
    });
    const response = await api
    .get(`api/linkedin/?code=${idToken}&role=candidate`)
        .then((res) => {
            const token = res.data.authToken;
            localStorage.setItem("token", token);
            if (res.data.is_profile_created) {
                history.push("/dashboard");
            } else {
                history.push("/aboutyourself");
            }
        })
        .catch((err) => {
            if (err.response) {
                if (err.response.status === 404) {
                    dispatch({
                        type: API_ERROR,
                    });
                } else {
                    dispatch({
                        type: API_ERROR,
                    });
                }
            } else if (err.request) {
                dispatch({
                    type: API_ERROR,
                });
                // Request was made but no response
                console.log(err.request);
            } else {
                dispatch({
                    type: API_ERROR,
                });
                console.log(err.message);
            }
        });
};

export const loginUser = (email, password, history) => async (dispatch) => {
    dispatch({
        type: LOGIN_USER,
    });

    const response = api
        .post(
            "api/users/login/email",
            {
                email: email,
                password: password,
                role: "candidate",
            },
            {
                headers: {
                    "content-type": "application/json",
                    "Cache-Control": "no-cache",
                },
            }
        )
        .then((res) => {
            if (res.status === 200 && res.data.success === true) {
                dispatch({
                    type: LOGIN_SUCCESS,
                });
                localStorage.setItem("token", res.data.authToken);
                if (res.data.is_profile_created) {
                    history.push("/dashboard");
                } else {
                    history.push("/aboutyourself");
                }
            } else {
                dispatch({
                    type: API_ERROR,
                    payload: res.data.message,
                });
            }
        })
        .catch((err) => {
            console.log(err.response.data);
            dispatch({
                type: API_ERROR,
                payload: err.response.data.message,
            });
            if (err.response) {
                if (err.response.status === 404) {
                    console.log("Error: Page Not Found");
                } else {
                    console.log(err.response.data.reason);
                }
            } else if (err.request) {
                // Request was made but no response
                console.log(err.request);
            } else {
                console.log(err.message);
            }
        });

    //Add async action
};

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    };
};

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history },
    };
};

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
    };
};

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error,
    };
};
