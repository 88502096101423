import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Label, InputGroup, CustomInput } from "reactstrap";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import blankImg from "../../assets/images/user.png";
import pdf_icon from "../../assets/images/pdf_icon.png";
import word_icon from "../../assets/images/word_icon.png";
import ApiService from "../../config/restapi";
import cloudOutline from "../../assets/images/cloud-outline.svg";

const ApplyJobModal = (props) => {
  const [invalidFile, setInvalidFile] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [resumeVal, setResumeVal] = useState("");
  const [userData, setUserData] = useState({});
  const [resumeFile, setResumeFile] = useState("");
  const [fileSize, setFileSize] = useState(false);

  function validateFileExtention(files) {
    let ext = files && files[0].name.split(".").pop();
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      return true;
    }
    return false;
  }

  useEffect(() => {
    ApiService.getProfileData().then((res) => {
      if (res && res.data && res.data) {
        setEmail(res.data.email);
        setPhoneNumber(res.data.phone);
        setUserData({ ...res.data });
      }
    });
  }, []);

  function validateFile(value, ctx, input, cb) {
    if (!value) {
      setInvalidFile(true);
      cb("Required field");
      return;
    }

    setInvalidFile(false);
    cb(true);
  }

  function handleUpload(file) {
    try {
      if (!file) {
        throw new Error("Select a file first!");
      }
      if (validateFileExtention(file)) {
        setInvalidFile(false);
      } else {
        console.log("False");
        setInvalidFile(true);
        return;
      }
      if (file[0].size < 5242880) {
        setFileSize(false);
      } else {
        setFileSize(true);
        return;
      }
      const formData = new FormData();
      formData.append("file", file[0]);
      setResumeVal(formData);
      setResumeFile(file[0]);
      // this.setState({ ...this.state, formData });
      // setTimeout(() => {}, 100);
      // console.log(this.state);
      // handle success
    } catch (error) {
      console.log(error);
    }
  }

  function handleValidSubmit() {
    try {
      let id = props.selectedId;
      if(!resumeVal){
        toastr.error("Please Upload Your Resume", "", { timeOut: 2000 });
        return false;
      }
      ApiService.uploadJobResume(resumeVal).then((res) => {
        if (res && res.data) {
          let obj = {
            contact: phoneNumber,
            email,
            jobId: id,
            link: res.data.data.Location,
          };
          ApiService.applyJob(obj).then((res2) => {
            if (res2 && res2.data) {
              props.onModalSubmit();
            }
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      className="apply-job-modal"
      size="md"
    >
      <AvForm
        className="f"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div className="modal-header">
          <div className="modal-title mt-0 font-weight-bold" id="myModalLabel">
            Apply to Zunavish
          </div>
          <button
            type="button"
            onClick={() => {
              props.tog_center(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h4 className="font-size-18 font-weight-normal">Applying as</h4>
          <Row className="">
            <Col lg="12">
              <div className="d-flex">
                <div
                  className="profile-logo-container"
                  style={{ padding: "1em" }}
                >
                  <img
                    src={(userData && userData.image_url) || blankImg}
                    alt=""
                    className="avatar-md img-thumbnail rounded-circle"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "1em",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="font-size-18 font-weight-normal">
                    {userData.name}
                  </h4>
                  <div className="font-size-14 font-weight-100">
                    {userData.city ? <span>{userData.city}</span> : ""}
                    {userData.city && userData.country ? (
                      <span>,</span>
                    ) : (
                      ""
                    )}{" "}
                    {userData.country ? <span>{userData.country}</span> : ""}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <h4 className="font-size-18 mt-3 font-weight-normal">Contact Info</h4>
          <Row>
            <Col lg="6">
              <AvField
                name="email"
                value={email}
                label={
                  <>
                    Email Address
            
                  </>
                }
                placeholder="Eg:john@gmail.com"
                type="email"
                required
                onChange={({ target }) => {
                  target.value = target.value
                    .replace(/[^\w\s\.\,@]/gi, "")
                    .trimStart();

                  setEmail(target.value);
                }}
              />
            </Col>
            <Col lg="6">
              <AvGroup>
                <Label>
                  Phone Number
                
                </Label>
                <InputGroup style={{ align: "center" }}>
                  <AvInput
                    name="tel"
                    value={phoneNumber}
                    maxLength={10}
                    required
                    validate={{
                      minLength: { value: 10 },
                      maxLength: { value: 10 },
                      required: { value: true },
                      pattern: { value: "^[0-9]+$" },
                    }}
                    onChange={({ target }) => {
                      setPhoneNumber(target.value);
                    }}
                  />

                  <AvFeedback>This field is invalid</AvFeedback>
                </InputGroup>
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <AvGroup>
                <Label>
                  Upload Resume
                  
                </Label>
                {!resumeFile ? (
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleUpload(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        <div className=" needsclick" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="needsclick">
                            <button
                              type="button"
                              className="d-flex btn btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                            >
                              {" "}
                              <img
                                className="float-left"
                                src={cloudOutline}
                                alt="cloud"
                              />
                              <div className="font-size-11 ml-2"> UPLOAD</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div className="apply_resume_wrapper">
                    <div className="company-logo-img">
                      <img src={resumeFile.name.indexOf("pdf")>-1?pdf_icon:word_icon} alt="" className="" />
                      <span
                        className="img-close"
                        onClick={() => {
                          setResumeFile("");
                          setInvalidFile(false);
                          setFileSize(false);
                        }}
                      >
                        &times;
                      </span>
                    </div>
                    <div className="mt-2">{resumeFile.name}</div>
                  </div>
                )}
                {invalidFile && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    File format not supported(only pdf,doc and docx are
                    supported).
                  </label>
                )}
                {fileSize && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    File must be less than 5 MB
                  </label>
                )}
              </AvGroup>
            </Col>
          </Row>
        </div>
        {!email || !phoneNumber || !resumeFile ? (
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                props.tog_center();
              }}
              className="btn  font-light-green btn-outline-light-green  waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn bg-light-green waves-effect waves-light"
            >
              Apply
            </button>
          </div>
        ) : (
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                props.tog_center();
              }}
              className="btn  btn-outline-green font-dark-green waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn bg-green waves-effect waves-light"
            >
              Apply
            </button>
          </div>
        )}
      </AvForm>
    </Modal>
  );
};

export default ApplyJobModal;
