import React from "react";
import { Redirect } from "react-router-dom";
import SideNav from '../components/SideNav';
//DashBoard
import Dashboard from "../pages/Dashboard/index";
import Jobs from "../components/Jobs/Jobs"
//Authentication
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import Login from "../pages/Authentication/Login";
import SignUP from "../pages/Authentication/SignUp";
import SetPassword from "../pages/Authentication/SetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Assessment from "../components/Assessment/Assessment";
import Verification from "../pages/Authentication/Verification";
import AboutYourself from "../pages/Authentication/AboutYourself";
import UserProfile from "../pages/Profile";
import ResumeBuilder from "../pages/ResumeDashboard/ResumeBuilder.js";
import ResumeDashboard from "../pages/ResumeDashboard";
import AccountSetting from "../pages/AccountSetting";
import PublicProfile from '../pages/PublicProfile';
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetVerification from "../pages/Authentication/ResetVerification";
import Notifications from "../pages/Notifications/Notifications";
import SignUp from "../pages/Authentication/SignUp";

const userRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/jobs/:id", exact: true, component: Jobs },
    { path: "/test", exact: true, component: SideNav },
    { path: "/assessment", exact: true, component: Assessment },
    { path: "/profile", component: UserProfile },
    { path: "/linkedin", component: LinkedInPopUp },
    { path: "/resumebuilder/:id", component: ResumeBuilder },
    { path: "/resumeDashboard", component: ResumeDashboard },
    { path: "/setting", component: AccountSetting },
    { path: "/aboutyourself", component: AboutYourself },
  {path:"/notifications",component:Notifications},
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => localStorage.getItem("token") ? <Redirect to="/dashboard" /> : <Redirect to="/login" /> }

];

const authRoutes = [
    { path: "/public/profile/:id", exact:true ,component: (props) => <PublicProfile {...props}/>},
    
    //Authentication
    { path: "/login", component: Login },
    { path: "/signup", component: SignUp },
    { path: "/setpassword/", component: SetPassword },
    { path: "/resetpassword/", component: ResetPassword },
    { path: "/verification", component: Verification },
    { path: "/forgotpassword", component: ForgotPassword },
    { path: "/passwordreset", component: ResetVerification },
    {path:"/linkedin/callback" , component:LinkedInPopUp}, 
]

export { userRoutes, authRoutes };