import React, { useState,useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';

// Redux
import { withRouter, Link } from 'react-router-dom';


// users
import user1 from '../../../assets/images/users/blank_profile.svg';

const ProfileMenu = (props) => {

   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);

  console.log(props);
  return (
    <React.Fragment>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="header-profile-user" src={props.image || user1} alt="Header Avatar" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        
                        <DropdownItem tag="a" onClick={()=>{
                            props.history.push('/profile')
                        }} to="/profile"> <i className="bx bx-user font-size-16 align-middle mr-1"></i>Profile</DropdownItem>
                        {props.profile.login_type === "email" && ( 
                         <DropdownItem tag="a" onClick={()=>{
                            props.history.push('/setting')
                        }} to="/setting"><i className="bx bx-cog font-size-17 align-middle mr-1"></i>Settings</DropdownItem>
                        )}
                        
                        
                        <div className="dropdown-divider"></div>
                        <Link to="#" className="dropdown-item" onClick={()=>{
                            localStorage.removeItem('token');
                            props.history.push('/login')
                        }}>
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}

const mapStateToProps = (state) => {
    return ({
        image: state.dashboard.userDetails.image_url,
        profile: state.dashboard.userDetails
    })
}

export default withRouter(connect(mapStateToProps,{})(ProfileMenu));

