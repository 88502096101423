export const EMP_DETAILS = 'EMP_DETAILS';
export const EMP_DETAILS_UPDATE = 'EMP_DETAILS_UPDATE';
export const EMP_DETAILS_LOADING = 'EMP_DETAILS_LOADING';
export const EMP_DETAILS_ERROR = 'EMP_DETAILS_ERROR';
export const EMP_DELETE = 'EMP_DELETE';


const initialState = {
    loading: true,
    error: null,
    education: {}
}

const emp = (state = initialState, action) => {
    switch (action.type) {
        
        case EMP_DETAILS:
            state = { loading: false, error: null, success: action.payload.success}
            break;
        case EMP_DETAILS_UPDATE:
            state = { loading: false, error: null, success: action.payload.success}
            break;
        case EMP_DELETE:
            state = { loading: false, error: null, success: action.payload.success}
            break;
        case EMP_DETAILS_LOADING:
            state = { ...state, loading: true };
            break;
        case EMP_DETAILS_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        default:
            break;
    }
    return state;
}
export default emp;