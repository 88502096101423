import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import TextArea from "../../../components/TextArea";
import ApiService from "../../../config/restapi";
import Common from "../../../helpers/common";

const JobRelated = (props) => {
  const [ljobcharcomplete, setLjobcharcomplete] = useState(0);
  const [stintcharcomplete, setStintcharcomplete] = useState(0);
  const [situationscharcomplete, setSituationscharcomplete] = useState(0);
  const [careercharcomplete, setCareercharcomplete] = useState(0);

  const [jobQuestions, setJobQuestions] = useState({
    last_like: "",
    best_stint: "",
    pro_personal_life: "",
    open_ended: "",
  });

  function getProfileData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.data && res.data.job_q) {
        setJobQuestions({
          ...jobQuestions,
          last_like: res.data.job_q.last_like ? res.data.job_q.last_like : "",
          best_stint: res.data.job_q.best_stint
            ? res.data.job_q.best_stint
            : "",
          pro_personal_life: res.data.job_q.pro_personal_life
            ? res.data.job_q.pro_personal_life
            : "",
          open_ended: res.data.job_q.open_ended
            ? res.data.job_q.open_ended
            : "",
        });
      }
    });
  }

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    props.jobQuestionsValCallBack(jobQuestions);
  }, [jobQuestions]);

  const onChangeTextareaHandler = (event, type) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(
      event.target.value.replace(/[^\w\s\.\,]/gi, "")
    );
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    switch (type) {
      case "ljob":
        if (WordCount <= 500){
          setLjobcharcomplete(WordCount);
          setJobQuestions({ ...jobQuestions, last_like: event.target.value });
        }
        break;
      case "stint":
        if (WordCount <= 500){
          setStintcharcomplete(WordCount);
          setJobQuestions({ ...jobQuestions, best_stint: event.target.value });
        } 
        break;
      case "situations":
        if (WordCount <= 500){
          setSituationscharcomplete(WordCount);
          setJobQuestions({
            ...jobQuestions,
            pro_personal_life: event.target.value,
          });
        } 
        break;
      case "career":
        if (WordCount <= 500){
          setCareercharcomplete(WordCount);
          setJobQuestions({ ...jobQuestions, open_ended: event.target.value });
        } 
        break;
      default:
        break;
    }
  };

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={3} className="job-related">
        <Form>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What did you like about your last job?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "ljob")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={ljobcharcomplete}
                value={jobQuestions.last_like}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="Which is the best stint in your career and why? "
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "stint")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={stintcharcomplete}
                value={jobQuestions.best_stint}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="Do professional situations influence your personal life and vice versa?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "situations")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={situationscharcomplete}
                value={jobQuestions.pro_personal_life}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="How would you tag your career - academics driven or passion driven? Please explain?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "career")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={careercharcomplete}
                value={jobQuestions.open_ended}
              />
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default JobRelated;
