import React from 'react';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from '../../assets/images/linkedin.png';


const LinkedInLoginButton = ({ onSuccess, onFailure }) => {
    return (
        <LinkedIn
            clientId="86i6ic4g6xr5n6"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            redirectUri="https://pro.zunavish.com/linkedin/callback/" 
            scope="r_emailaddress"
            renderElement={renderProps => (
                <img onClick={renderProps.onClick} disabled={renderProps.disabled} className="mr-2 cursor-pointer" src={linkedin} alt='linkedin' />
            )}
        />
    );
}
export { LinkedInLoginButton };
