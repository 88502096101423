import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import classnames from "classnames";
import Pdf from "react-to-pdf";
import bullet from "../../assets/images/bullet.svg";
import avatar from "../../assets/images/avatar.png";
//theme images
import Elegant_theme from "../../assets/images/Elegant_theme.png";
import Professional_theme from "../../assets/images/Professional_theme.png";
import Modern_theme from "../../assets/images/Modern_theme.png";
import Techy_theme from "../../assets/images/Techy_theme.png";
import Formal_theme from "../../assets/images/Formal_theme.png";

import ApiService from "../../config/restapi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
//themes import

import Elegant from "../../components/themes/Elegant";
import Theme2 from "../../components/themes/Theme2";
import Theme3 from "../../components/themes/Theme3";
import Theme4 from "../../components/themes/Theme4";
import Theme5 from "../../components/themes/Theme5";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import {
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Modal,
} from "reactstrap";
import AboutSection from "./AboutSection";
import Education from "./Education";
import Employment from "./Employment";
import Skills from "./Skills";
import Courses from "./Courses";
import api from "../../config/axios";

import s3api from "../../helpers/s3apis";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllUserData } from "../../redux/actions/user";
import {
  addEducationData,
  updateEducationData,
  deleteEducationData,
} from "../../redux/actions/profile/education";
import {
  addEmpData,
  updateEmpData,
  deleteEmpData,
} from "../../redux/actions/profile/emp";
import Certification from "./Certification";
import getAge from "../../helpers/getAge";
import ModalBody from "reactstrap/lib/ModalBody";
// import ViewBackgroundVModal from './ViewBackgroundVModal';
import { useHistory } from "react-router";

const ref = React.createRef();
const ResumeBuilder = (props) => {
  const history = useHistory();
  const [modal_center, setmodal_center] = useState(false);
  const [user, setUser] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});
  //View Document
  const [modal_bg, setmodal_bg] = useState(false);
  const [savedRowsEducation, setSavedRowsEducation] = useState([]);
  const [educationalEditedData, setEducationalEditedData] = useState();
  const [charComplete, setCharComplete] = useState(0);

  //On Save or Edit Education Modal Data
  const createEducationalRow = (data, type) => {
    if (type === "save") {
      let obj = {
        college: data.college,
        degree: data.degree,
        course: data.course,
        start_year: data.sDate,
        grad_year: data.eDate,
        still_studying: data.stillStudying,
      };
      ApiService.saveEducationDetailsOnBoarding([obj]).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEducationData();
        }
      });
    } else {
      let obj = {
        id: Number(data.id),
        college: data.college,
        degree: data.degree,
        course: data.course,
        start_year: data.sDate,
        grad_year: data.eDate,
        still_studying: data.stillStudying,
      };
      ApiService.editEducationDetailsOnBoarding(obj).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEducationData();
        }
      });
    }
  };

  //Set Edited Data on Education Modal
  const editEducationalRow = (row, type) => {
    const tempEditedData = savedRowsEducation.filter((v) => v.id === row.id);
    if (tempEditedData.length > 0) setEducationalEditedData(tempEditedData[0]);
  };

  //Delete Education Row
  const deleteEducationalRow = (row) => {
    ApiService.deleteEducationDetailsOnBoarding({ id: Number(row.id) }).then(
      (res) => {
        if (res && res.status === 200) {
          updateEducationData();
        }
      }
    );
  };

  //Reload Education Data
  function updateEducationData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.status === 200 && res.data) {
        setSavedRowsEducation([...res.data.educations]);
        setUser({ ...res.data });
        setUpdatedUser({
          ...updatedUser,
          educations: [...res.data.educations],
        });
      }
    });
  }
  console.log(user);

  const setEducationalDataNull = () => {
    if (educationalEditedData) setEducationalEditedData("");
  };
  //Certification
  const [savedRowsCertificates, setSavedRowsCertificate] = useState([]);
  const [certificateEditedData, setCertificateEditedData] = useState();

  const createCertificate = (data, type) => {
    // api.post('api/jobs/upload/resume', data.cert, {
    //   headers: {
    //     token: `${localStorage.getItem("token")}`,
    //     'Access-Control-Allow-Origin': '*'
    //   }
    // }).then((result) => {
    //   data.cert = undefined;
    //   data.link = result.data.data.Location
    api
      .post("/api/certificates/add", data, {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        var ext = data.cert[0].name.split(".").pop();
        var id = res.data.id;
        if (data.cert[0].size < 5242880) {
          s3api.upload("certificate", ext, data.cert[0], id);
        } else {
          toastr.error("Select a file of size less than 5 MB", "", {
            timeOut: 2000,
          });
        }
        toastr.success("Certificate Added Successfully!", "", {
          timeOut: 2000,
        });
        getAllCertificates();
      })
      .catch((e) => {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      });
  };
  const editCertificate = (data) => {
    if (data.cert) {
      // api.post('api/jobs/upload/resume', data.cert, {
      //   headers: {
      //     token: `${localStorage.getItem("token")}`,
      //     'Access-Control-Allow-Origin': '*'
      //   }
      // }).then((result) => {
      //   data.cert = undefined;
      //   data.link = result.data.data.Location
      api
        .put("/api/certificates/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log(data);
          console.log(data.cert[0]);
          var ext = data.cert[0].name.split(".").pop();
          var id = data.id;
          if (data.cert[0].size < 5242880) {
            s3api.upload("certificate", ext, data.cert[0], id);
          } else {
            toastr.error("Select a file of size less than 5 MB", "", {
              timeOut: 2000,
            });
          }
          getAllCertificates();
          toastr.success("Certificate Updated Successfully!", "", {
            timeOut: 2000,
          });
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    } else {
      api
        .put("/api/certificates/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          var ext = data.cert[0].name.split(".").pop();
          var id = data.id;
          if (data.cert[0].size < 5242880) {
            s3api.upload("certificate", ext, data.cert[0], id);
          } else {
            toastr.error("Select a file of size less than 5 MB", "", {
              timeOut: 2000,
            });
          }
          getAllCertificates();
          toastr.success("Certificate Updated Successfully!", "", {
            timeOut: 2000,
          });
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    }
  };

  //Courses
  const [savedRowsCourses, setSavedRowsCourses] = useState([]);
  const [courseEditedData, setCourseEditedData] = useState();

  const createCourse = (data, type) => {
    // api.post('api/jobs/upload/resume', data.cert, {
    //   headers: {
    //     token: `${localStorage.getItem("token")}`,
    //     'Access-Control-Allow-Origin': '*'
    //   }
    // }).then((result) => {
    //   data.cert = undefined;
    //   data.link = result.data.data.Location
    api
      .post("/api/courses/add", data, {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        var ext = data.cert[0].name.split(".").pop();
        var id = res.data.id;
        if (data.cert[0].size < 5242880) {
          s3api.upload("course", ext, data.cert[0], id);
        } else {
          toastr.error("Select a file of size less than 5 MB", "", {
            timeOut: 2000,
          });
        }
        toastr.success("Course Added Successfully!", "", { timeOut: 2000 });
        getAllCourses();
      })
      .catch((e) => {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      });
  };
  const editCourse = (data) => {
    if (data.cert) {
      // api.post('api/jobs/upload/resume', data.cert, {
      //   headers: {
      //     token: `${localStorage.getItem("token")}`,
      //     'Access-Control-Allow-Origin': '*'
      //   }
      // }).then((result) => {
      //   data.cert = undefined;
      //   data.link = result.data.data.Location
      api
        .put("/api/courses/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          var ext = data.cert[0].name.split(".").pop();
          var id = data.id;
          if (data.cert[0].size < 5242880) {
            s3api.upload("course", ext, data.cert[0], id);
          } else {
            toastr.error("Select a file of size less than 5 MB", "", {
              timeOut: 2000,
            });
          }
          toastr.success("Course Updated Successfully!", "", { timeOut: 2000 });
          getAllCourses();
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    } else {
      api
        .put("/api/courses/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          var ext = data.cert[0].name.split(".").pop();
          var id = data.id;
          if (data.cert[0].size < 5242880) {
            s3api.upload("course", ext, data.cert[0], id);
          } else {
            toastr.error("Select a file of size less than 5 MB", "", {
              timeOut: 2000,
            });
          }
          toastr.success("Course Updated Successfully!", "", { timeOut: 2000 });
          getAllCourses();
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    }
  };

  //Employment State
  const [savedRowsEmp, setSavedRowsEmp] = useState([]);
  const [empEditedData, setEmpEditedData] = useState();

  //On Save or Edit Employment Modal Data
  const createEmpRow = (data, type) => {
    if (type === "save") {
      let obj = {
        role: data.jobrole,
        company: data.company,
        emp_type: data.empType,
        city: data.location,
        start_year: data.sDate,
        grad_year: data.eDate,
        still_working: data.stillWorking,
        description: data.description,
      };
      ApiService.saveEmploymentDetailsOnBoarding([obj]).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEmpData();
        }
      });
    } else {
      let obj = {
        id: Number(data.id),
        role: data.jobrole,
        company: data.company,
        emp_type: data.empType,
        city: data.location,
        start_year: data.sDate,
        grad_year: data.eDate,
        still_working: data.stillWorking,
        description: data.description,
      };
      ApiService.editEmploymentDetailsOnBoarding(obj).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEmpData();
        }
      });
    }
  };

  //Set Edited Data on Employment Modal
  const editEmpRow = (row, type) => {
    const tempEditedData = savedRowsEmp.filter((v) => v.id === row.id);
    if (tempEditedData.length > 0) setEmpEditedData(tempEditedData[0]);
  };

  //Delete Employment Row
  const deleteEmpRow = (row) => {
    try {
      ApiService.deleteEmploymentDetailsOnBoarding({ id: Number(row.id) }).then(
        (res) => {
          if (res && res.status === 200) {
            updateEmpData();
          } else {
            toastr.error("Something Went Wrong", "", { timeOut: 2000 });
          }
        }
      );
    } catch (e) {
      toastr.error("Something Went Wrong", "", { timeOut: 2000 });
    }
  };

  //Reload Employment Data
  function updateEmpData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.status === 200 && res.data) {
        setSavedRowsEmp([...res.data.experiences]);
        setUser({ ...res.data });
        setUpdatedUser({
          ...updatedUser,
          experiences: [...res.data.experiences],
        });
      }
    });
  }

  const setEmpDataNull = () => {
    if (empEditedData) setEmpEditedData("");
  };

  //Edit Profile

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const openModal = () => {
    tog_center();
  };

  function tog_bg() {
    setmodal_bg(!modal_bg);
    removeBodyCss();
  }

  const updateProfileData = (data, type) => {
    if (type === "edit") {
      ApiService.editProfileData(data).then((res) => {
        if (res && res.status === 200 && res.data) {
          props.getAllUserData();
          if (!props.error) {
            tog_center(false);
            toastr.success("Profile Updated", "", { timeOut: 2000 });
          }
        }
      });
    }
  };

  const [themeBlocks, setThemeBlocks] = useState([
    { name: "Elegant", imgPath: Elegant_theme },
    { name: "Professional", imgPath: Professional_theme },
    { name: "Modern", imgPath: Modern_theme },
    { name: "Techy", imgPath: Techy_theme },
    { name: "Formal", imgPath: Formal_theme },
  ]);

  const changeTheme = (theme) => { };

  const [selectedTheme, setSelectedTheme] = useState("Elegant");
  const [skills, setSkills] = useState();

  console.log(selectedTheme);
  const getAllCourses = () => {
    api
      .get("/api/courses/all", {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("HERERER");
        setSavedRowsCourses(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log("HERERER");
        setSavedRowsCourses([]);
        console.log(e);
      });
  };
  const getAllCertificates = () => {
    api
      .get("/api/certificates/all", {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("HERERER");
        setSavedRowsCertificate(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log("HERERER");
        setSavedRowsCertificate([]);
        console.log(e);
      });
  };
  useEffect(() => {
    props.getAllUserData();
    ApiService.getProfileData().then((res) => {
      if (res && res.status === 200 && res.data) {
        setSavedRowsEmp([...res.data.experiences]);
        setSavedRowsEducation([...res.data.educations]);
        setUser({ ...res.data });
        let temp = { ...res.data };

        setUpdatedUser({ ...res.data });

        if(res.data && res.data.bio.length>0){
          var regex = /\s+/gi;
          var WordCount =
          res.data.bio === ""
            ? 0
            : res.data.bio.trim().replace(regex, " ").split(" ").length;
          setCharComplete(WordCount);
          console.log(WordCount);
        }
      }
    });
    getAllCertificates();
    getAllCourses();
    api
      .get(`api/resume/${props.match.params.id}`, {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("HERERER");
        setSelectedTheme(res.data.theme);
        console.log(res);
      });
  }, []);
  const [hasChanged, setHasChanged] = useState(false);

  const openResume = () => {
    window.scrollTo(0, 0);
    toastr.success("Resume Saved", "", { timeOut: 2000 });
    history.push("/resumeDashboard");


    //   let pdf = new Jspdf('p', 'mm', 'a4')
    //   var width = pdf.internal.pageSize.getWidth();
    //   var height = pdf.internal.pageSize.getHeight();
    //   console.log(canvas.width,canvas.height);
    //   pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10, 200, 150);
    //   pdf.save(filename)
    // })
  };

  const saveResume = () =>{
    console.log(document.querySelector(".resume-print-layout"));
    html2canvas(document.querySelector(".resume-print-layout")).then(canvas => {
      let pdf = new Jspdf('p', 'mm', 'a4')
      console.log(canvas);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      console.log(canvas.width,canvas.height);
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10, 200, 150);
      pdf.save("Resume.pdf")
  });
    
  }

  console.log(savedRowsCourses);
  function previeResume() { }

  const themeSelect = (index) => {
    var tempThemes = [...themeBlocks];
    tempThemes = tempThemes.map((val, idx) => {
      if (idx === index) {
        val["selected"] = true;
        api
          .patch(
            `api/resume/${props.match.params.id}`,
            { theme: val.name },
            {
              headers: {
                token: `${localStorage.getItem("token")}`,
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            console.log("HERERER");
            toastr.success("Theme Updated", "", { timeOut: 2000 });
            console.log(res);
            setSelectedTheme(val.name);
          });
      } else {
        val["selected"] = false;
      }
      return val;
    });
    setThemeBlocks(tempThemes);
  };
  console.log(props.user);
  const updateProfileDetails = () => {
    ApiService.editProfileData(updatedUser).then((res) => {
      if (res && res.status === 200 && res.data) {
        props.getAllUserData();
        if (!props.error) {
          toastr.success("Profile Updated", "", { timeOut: 2000 });
          setHasChanged(false);
        }
      }
    });
  };
  const OnchangeCallBack = (value, type) => {
    console.log(value, type);
    setHasChanged(true);
    switch (type) {
      case "isd_code":
      case "name":
      case "email":
      case "phone":
      case "city":
      case "age":
      case "bio":
      case "marital_status":
      case "country":
      case "children_count":
      case "has_children":
        var temp = { ...updatedUser };
        temp[type] = value;
        setUpdatedUser(temp);
        break;
      case "dob":
        var temp = { ...updatedUser };
        temp["date_of_birth"] = value;
        temp["age"] = getAge(value);
        console.log(temp);
        setUpdatedUser(temp);
        break;

        console.log("IMPLEMENT THISS");
        break;
      case "Children":
        console.log("IMPLEMENT THISS");
        break;

        console.log("IMPLEMENT THISS");
        break;

        console.log("IMPLEMENT THISS");
        break;
      default:
        break;
    }
  };

  const reset = () => {
    console.log(user);
    let temp = { ...user };
    setUpdatedUser(temp);
  };
  const options = {
    unit: "px",
    format: [350, 400]
  };
  console.log(updatedUser);
  return (
    <React.Fragment>
      <div className="page-content resume_builder_page">
        <Container fluid>
          <Row className="pt-3">
            <Col lg="9">
              <p className="resume-heading mb-0"> Build Your Resume </p>
            </Col>
          </Row>
          <div className="resume-container">
            <Row>
              <Col lg="8">
                <AboutSection
                  user={updatedUser}
                  resetUser={user}
                  getAllUserData={() => props.getAllUserData()}
                  allowReset={hasChanged}
                  OnchangeCallBack={OnchangeCallBack}
                  resetProfileDetails={reset}
                  updateProfileDetails={updateProfileDetails}
                  charComplete={charComplete}
                  setCharComplete={setCharComplete}
                />

                <Education
                  savedRowsEducation={savedRowsEducation}
                  createEducationalRow={createEducationalRow}
                  editEducationalRow={editEducationalRow}
                  educationEditedData={educationalEditedData}
                  setEducationalDataNull={setEducationalDataNull}
                  deleteEducationalRow={deleteEducationalRow}
                />
                <Employment
                  savedRowsEmp={savedRowsEmp}
                  createEmpRow={createEmpRow}
                  editEmpRow={editEmpRow}
                  empEditedData={empEditedData}
                  setEmpDataNull={setEmpDataNull}
                  deleteEmpRow={deleteEmpRow}
                />

                <Skills
                  getAllUserData={() => updateEducationData()}
                  user={user}
                />
                <Courses
                  userData={user}
                  savedRowsCourses={savedRowsCourses}
                  setSavedRowsCourses={setSavedRowsCourses}
                  createCourse={createCourse}
                  editCourse={editCourse}
                  courseEditedData={courseEditedData}
                  setEmpDataNull={setEmpDataNull}
                  deleteEmpRow={deleteEmpRow}
                />
                <Certification
                  userData={user}
                  savedRowsCertificates={savedRowsCertificates}
                  setSavedRowsCertificate={setSavedRowsCertificate}
                  createCertificate={createCertificate}
                  editCertificate={editCertificate}
                  certificateEditedData={certificateEditedData}
                  setEmpDataNull={setEmpDataNull}
                  deleteEmpRow={deleteEmpRow}
                />
                <Modal
                  
                  isOpen={modal_center}
                  toggle={openModal}
                  style={{
                    width: "21cm",
                    maxWidth: "21cm",
                    minHeight: "100%",
                    overflow: "auto",
                    backgroundColor: "#ffffff00",
                  }}
                >
                  {/* <Row>
                                        <Col lg="7"> */}
                  {/* </Col>
                                    </Row> */}
                  <br />
                  <br />
                  {selectedTheme === "Elegant" && Object.keys(user).length >0 && (
                    
                    <div className="resume-print-layout" >
      <div className="theme-1" >
        <div className="content" ref={ref}>
          {" "}
          {console.log(user)}
          <div className="left-section">
            <div
              className="font-weight-bold font-size-24"
              style={{ wordWrap: "break-word" }}
            >
              {user.name.split(" ")[0]}
            </div>
            <div
              className="font-weight-bold font-size-24"
              style={{ wordWrap: "break-word" }}
            >
              {user.name.split(" ")[2] ? user.name.split(" ")[2] : user.name.split(" ")[1] ? user.name.split(" ")[1] : ""}
            </div>
            <div className="font-weight-light font-weight-14">
              {" "}
              {user.designation}{" "}
            </div>
            <br />
            <br />

            <div className="personal-info">
              <div className="font-weight-medium mt-2 heading">
                {" "}
                Personal Info{" "}
              </div>
              <div>
                {user && user.phone ? (
                  <>
                    <div className="mt-2 font-weight-bold font-size-14">
                      Phone
                    </div>
                    <div className="font-weight-light font-size-12">
                      {user && user.phone ? user.phone : "--"}
                    </div>
                  </>
                ) : (
                  <div />
                )}
                {user && user.email ? (
                  <>
                    <div className="mt-2 font-weight-bold font-size-14">
                      Email
                    </div>
                    <div
                      className="font-weight-light font-size-12 "
                      style={{ wordWrap: "break-word" }}
                    >
                      {user && user.email ? user.email : "--"}
                    </div>
                  </>
                ) : (
                  <div />
                )}
                {/* <div className='mt-2 font-weight-bold font-size-14'>
                        WWW
                    </div>
                    <div className='font-weight-light font-size-12'>
                        www.janesantos.com
                    </div> */}
                {user && user.linked_in_url ? (
                  <>
                    <div className="mt-2 font-weight-bold font-size-14">
                      Linkedin
                    </div>
                    <div className="font-weight-light font-size-12">
                      {user && user.linked_in_url
                        ? user.linked_in_url
                        : "--"}
                    </div>
                  </>
                ) : (
                  <div />
                )}
                {/* <div className='mt-2 font-weight-bold font-size-14'>
                        Twitter
                    </div>
                    <div className='font-weight-light font-size-12'>
                        twitter.com/janesantos1
                    </div> */}
              </div>
            </div>
            <br />
            <br />
            {user &&
              user.skills &&
              user.skills.length !== 0 ? (
              <>
                <div className="skill-info">
                  <div className="font-weight-medium mt-2 heading">
                    {" "}
                    Skills{" "}
                  </div>
                  {user &&
                    user.skills &&
                    user.skills.map((item, index) => {
                      var i = JSON.parse(item);
                      return (
                        <div>
                          <div className="mt-2 font-weight-bold font-size-14">
                            {i.label}
                          </div>
                          {/* <div className='mt-2'>
                                        <Progress
                                            className="mb-2"
                                            value={80}
                                            color="primary"
                                        ></Progress>
                                    </div>
                                    <div className='text-right font-weight-light font-size-12'> Advanced </div> */}
                        </div>
                      );
                    })}
                </div>
              </>
            ) : (
              <div />
            )}
            <br />
            <br />
          </div>
          <div className="right-section">
            <div className="pb-2">
              {user && user.bio ? user.bio : "--"}
            </div>
            <br />
            <br />
            {user &&
              user.experiences &&
              user.experiences.length !== 0 ? (
              <>
                <div className="blue-heading font-weight-bold font-size-16">
                  Experience
            </div>
                {user &&
                  user.experiences &&
                  user.experiences.map((item, index) => {
                    return (
                      <Row className="mt-2" key={index}>
                        <Col lg={2} className="pr-0">
                          <div> {new Date(item.start_year).getFullYear()} - </div>
                          <div>
                            {item.grad_year
                              ? new Date(item.grad_year).getFullYear()
                              : "Present"}
                          </div>
                        </Col>
                        <Col lg={10}>
                          <div className="font-weight-bold font-size-16">
                            {item.company}
                          </div>
                          <div>
                            <span className="font-weight-bold font-size-14">
                              {item.role}, {item.emp_type}{" "}
                            </span>
                          </div>
                          <span className="font-italic">{item.city} </span>
                        </Col>
                      </Row>
                    );
                  })}
              </>) : <div />}
            <br />
            <br />
            {user &&
              user.educations &&
              user.educations.length !== 0 ? (
              <>
                <div className="blue-heading font-weight-bold font-size-16">
                  Education
            </div>
                {user &&
                  user.educations &&
                  user.educations.map((item, index) => {
                    return (
                      <Row className="mt-2" key={index}>
                        <Col lg={2} className="pr-0">
                          <div> {new Date(item.start_year).getFullYear()} - </div>
                          <div>
                            {item.grad_year
                              ? new Date(item.grad_year).getFullYear()
                              : "Present"}
                          </div>
                        </Col>

                        {item.course ? (
                          <Col lg={10}>
                            <div className="font-weight-bold font-size-16">
                              {item.college}
                            </div>
                            <div>
                              <span className="font-weight-bold font-size-14">
                                {item.course},{" "}
                              </span>{" "}
                              <span className="font-italic">{item.degree} </span>
                            </div>
                          </Col>
                        ) : (
                          <Col lg={10}>
                            <div className="font-weight-bold font-size-16">
                              {item.college}
                            </div>
                            <div>
                              <span className="font-weight-bold font-size-14"></span>{" "}
                              <span className="font-italic">{item.degree} </span>
                            </div>
                          </Col>
                        )}
                      </Row>
                    );
                  })}
              </>) : <div />}

            <br />
            <br />
            {savedRowsCourses &&
              savedRowsCourses &&
              savedRowsCourses.length !== 0 ? (
              <>
                <div className="blue-heading font-weight-bold font-size-16">
                  Courses
            </div>
                {savedRowsCourses &&
                  savedRowsCourses &&
                  savedRowsCourses.map((item, index) => {
                    return (
                      <Row className="mt-2" key={index}>
                        <Col lg={10}>
                          <div className="font-weight-bold font-size-16">
                            {item.title}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </>) : <div />}

            <br />
            <br />
            {savedRowsCertificates &&
              savedRowsCertificates &&
              savedRowsCertificates.length !== 0 ? (
              <>
                <div className="blue-heading font-weight-bold font-size-16">
                  Certifications
            </div>
                {savedRowsCertificates &&
                  savedRowsCertificates &&
                  savedRowsCertificates.map((item, index) => {
                    return (
                      <Row className="mt-2" key={index}>
                        <Col lg={10}>
                          <div className="font-weight-bold font-size-16">
                            {item.title}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </>) : <div />}

            <br />
          </div>
        </div>
      </div>
                    </div>
                  )}
                  {selectedTheme === "Professional" && Object.keys(user).length >0 && (
                    <div className="resume-print-layout">
                    <div className="theme-2" ref={ref}>
                      <Row>
                        <Col className="photo-background" lg="3">
                          <img
                            src={user.image_url || avatar}
                            className="user-profile"
                            alt="image-1"
                          ></img>
                        </Col>
                        <Col className="main-heading-name" lg="5">
                          <div className="first-name" style={{ wordWrap: "break-word" }}>
                            {user.name.split(" ")[0]}
                          </div>
                          <div className="last-name" style={{ wordWrap: "break-word" }}>
                          {user.name.split(" ")[2] ? user.name.split(" ")[2] : user.name.split(" ")[1] ? user.name.split(" ")[1] : ""}
                          </div>
                          <div className="designation"> {user.designation} </div>
                        </Col>
                        <Col className="contact-details" lg="4">
                          <div className="data-points">
                            <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                            <div style={{ paddingLeft: "2px" }}> </div>
                            {user.city}
                          </div>
                          <div className="data-points">
                            <i className="fa fa-phone-square" aria-hidden="true" />{" "}
                            <div style={{ paddingLeft: "2px" }}> </div>
                            {user.phone}
                          </div>
                          <div className="data-points" style={{ wordWrap: "break-word" }}>
                            <i className="fa fa-envelope" aria-hidden="true" />{" "}
                            <div style={{ paddingLeft: "2px" }}> </div>
                            <div style={{ wordWrap: "break-word", maxWidth: "100%" }}>
                              {user.email}
                            </div>
                          </div>
                          <div className="data-points">
                            <i className="fa fa-birthday-cake" aria-hidden="true" />{" "}
                            <div style={{ paddingLeft: "2px" }}> </div>
                            {user?.date_of_birth?.toString().split("T")[0]}
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="8">
                          <Row>
                            <Col className="side-bio" lg="1"></Col>
                            <Col lg="10">
                              <div className="heading">BIO</div>
                              <div className="bio">{user.bio}</div>
                            </Col>
                          </Row>
                          <br />
                          {user &&
                            user.experiences &&
                            user.experiences.length !== 0 ? (
                            <>
                              <Row>
                                <Col className="" lg="1"></Col>
                                <Col lg="10">
                                  <div className="heading">EMPLOYMENT</div>
                                </Col>
                              </Row>
                              {user &&
                                user.experiences &&
                                [...user.experiences].map((item, index) => {
                                  console.log(item);
                                  return (
                                    <Row key={index}>
                                      <Col lg={1} className="year">
                                        <div>
                                          {item.grad_year
                                            ? new Date(item.grad_year).getFullYear()
                                            : "Present"}
                                        </div>
                                        <div>{new Date(item.start_year).getFullYear()}</div>
                                      </Col>
                                      <Col lg={10}>
                                        <div className="title">
                                          {item.role}, {item.emp_type}
                                        </div>
                                        <div className="des">{item.company}</div>
                                      </Col>
                                      <br />
                                    </Row>
                                  );
                                })}
                            </>) : <div />}
              
                          <br />
                          {user &&
                            user.educations &&
                            user.educations.length !== 0 ? (
                            <>
                              <Row>
                                <Col className="" lg="1"></Col>
                                <Col lg="10">
                                  <div className="heading">EDUCATION</div>
                                </Col>
                              </Row>
                              {user &&
                                user.educations &&
                                user.educations.map((item, index) => {
                                  return (
                                    <Row key={index}>
                                      <Col lg={1} className="year">
                                        <div> {new Date(item.start_year).getFullYear()}</div>
                                        <div>
                                          {item.grad_year
                                            ? new Date(item.grad_year).getFullYear()
                                            : "Present"}
                                        </div>
                                      </Col>
                                      {item.course ? (
                                        <Col lg={10}>
                                          <div className="title">{item.college}</div>
                                          <div>
                                            <span className="des">{item.course}, </span>{" "}
                                            <span className="font-italic des">
                                              {item.degree}{" "}
                                            </span>
                                          </div>
                                        </Col>
                                      ) : (
                                        <Col lg={10}>
                                          <div className="title">{item.college}</div>
                                          <div>
                                            <span className="des"></span>
                                            <span className="font-italic des">
                                              {item.degree}{" "}
                                            </span>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                  );
                                })}
                            </>) : <div />}
              
                        </Col>
                        <Col className="right-section" lg="4">
                          <Row className="content">
                            <Col>
                              {user &&
                                user.skills &&
                                user.skills.length !== 0 ? (
                                <>
                                  <div className="heading">SKILLS</div>
                                  {user &&
                                    user.skills &&
                                    user.skills.map((item, index) => {
                                      const i = JSON.parse(item);
                                      return (
                                        <div className="data-points">
                                          {" "}
                                          <img
                                            className="pr-1 mr-1"
                                            src={bullet}
                                            alt="location"
                                            style={{ color: "black" }}
                                          />
                                          {i.label}
                                        </div>
                                      );
                                    })}
                                </>) : <div />}
              
                              <br />
                              {savedRowsCourses &&
                                savedRowsCourses &&
                                savedRowsCourses.length !== 0 ? (
                                <>
                                  <div className="heading">COURSES</div>
                                  {savedRowsCourses &&
                                    savedRowsCourses &&
                                    savedRowsCourses.map((item, index) => {
                                      return (
                                        <div className="data-points">
                                          {" "}
                                          <img
                                            className="pr-1 mr-1"
                                            src={bullet}
                                            alt="location"
                                            style={{ color: "black" }}
                                          />
                                          {item.title}
                                        </div>
                                      );
                                    })}
                                </>) : <div />}
              
                              <br />
                              {savedRowsCertificates &&
                                savedRowsCertificates &&
                                savedRowsCertificates.length !== 0 ? (
              
                                <div className="heading">CERTIFICATIONS</div>
              
                              ) : <div />}
                              {savedRowsCertificates &&
                                savedRowsCertificates &&
                                savedRowsCertificates.map((item, index) => {
                                  return (
                                    <div className="data-points">
                                      {" "}
                                      <img
                                        className="pr-1 mr-1"
                                        src={bullet}
                                        alt="location"
                                        style={{ color: "black" }}
                                      />
                                      {item.title}
                                    </div>
                                  );
                                })}
              
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  )}
                  {selectedTheme === "Modern" && Object.keys(user).length >0 && (
                    <div className="resume-print-layout">
                    <div className="theme-3" ref={ref}>
                      <div>
                        <meta charSet="utf-8" />
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1.0"
                        />
              
                        <div className="banner">
                          <div className="image">
                            <img
                              src={user.image_url || avatar}
                              className="user-profile-img"
                              alt="image-1"
                            ></img>
                          </div>
                          <div className="main-heading-name">
                            <div className="first-name" style={{ wordWrap: "break-word" }}>
                            {user.name.split(" ")[0]} <strong>{user.name.split(" ")[2] ? user.name.split(" ")[2] : user.name.split(" ")[1] ? user.name.split(" ")[1] : ""}</strong>
                              <br />
                              <span className="designation">{user.designation}</span>
                            </div>
                            {/* <div class="last-name">JHONSON</div> */}
                            {/* <div class="designation">Team Leader</div> */}
                          </div>
                        </div>
                        <div className="content">
                          <div className="left-section">
                            <div className="contact-details">
                              CONTACT
                              <p>
                                <i className="fa fa-map-marker" aria-hidden="true" />
                                {user.city}
                              </p>
                              <p>
                                <i className="fa fa-phone-square" aria-hidden="true" />
                                {user.phone}
                              </p>
                              <p style={{ wordWrap: "break-word" }}>
                                <i className="fa fa-envelope" aria-hidden="true" />
                                {user.email}
                              </p>
                              <p>
                                <i className="fa fa-birthday-cake" aria-hidden="true" />
                                {props?.data?.date_of_birth?.toString().split("T")[0]}
                              </p>
                            </div>
                            {user &&
                              user.educations &&
                              user.educations.length !== 0 ? (
                              <>
                                <div className="academic-section">
                                  ACADEMIC
                                  {user &&
                                    user.educations &&
                                    user.educations.map((item, index) => {
                                      return (
                                        <div className="academic-details">
                                          {item.course ? (
                                            <p>
                                              {item.course}
                                              <br /> {item.degree} <br />
                                              {item.college}
                                            </p>
                                          ) : (
                                            <p>
                                              {item.degree} <br />
                                              {item.college}
                                            </p>
                                          )}
              
                                          <span>
                                            {new Date(item.start_year).getFullYear()} -{" "}
                                            {item.grad_year
                                              ? new Date(item.grad_year).getFullYear()
                                              : "Present"}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>) : <div />}
              
                            {user &&
                              user.skills &&
                              user.skills.length !== 0 ? (
                              <>
                                <div className="skills-section">
                                  SKILLS
                                  <ul>
                                    {user &&
                                      user.skills &&
                                      user.skills.map((item, index) => {
                                        const i = JSON.parse(item);
                                        return (
                                          <li>
                                            <span>{i.label}</span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </>) : <div />}
              
                            <div className="references-section">
                              REFERENCES<span>None</span>
                            </div>
                          </div>
                          <div className="line" />
                          <div className="right-section">
                            <div className="bio-section">
                              BIO
                              <p>{user.bio}</p>
                            </div>
                            {user &&
                              user.experiences &&
                              user.experiences.length !== 0 ? (
                              <>
                                <div className="employment-section">
                                  EMPLOYMENT
                                  {user &&
                                    user.experiences &&
                                    [...user.experiences].map((item, index) => {
                                      console.log(item);
                                      return (
                                        <div
                                          className="employment-details"
                                          style={{ marginTop: "8px" }}
                                        >
                                          <p>
                                            <strong>{item.role} </strong>
                                            {/* <br /> {item.emp_type} */}
                                            <br />
                                            {item.company}
                                          </p>
                                          <span>
                                            {new Date(item.start_year).getFullYear()} -{" "}
                                            {item.grad_year
                                              ? new Date(item.grad_year).getFullYear()
                                              : "Present"}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>) : <div />}
              
                            {savedRowsCourses &&
                              savedRowsCourses &&
                              savedRowsCourses.length !== 0 ? (
                              <>
                                <div className="courses-section">
                                  COURSES
                                  <ul>
                                    {savedRowsCourses &&
                                      savedRowsCourses &&
                                      savedRowsCourses.map((item, index) => {
                                        return (
                                          <li>
                                            <span>{item.title}</span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </>) : <div />}
              
                            {savedRowsCertificates &&
                              savedRowsCertificates &&
                              savedRowsCertificates.length !== 0 ? (
                              <>
                                <div className="certifications-section">
                                  CERTIFICATIONS
                                  <ul>
                                    {savedRowsCertificates &&
                                      savedRowsCertificates &&
                                      savedRowsCertificates.map((item, index) => {
                                        return (
                                          <li>
                                            <span>{item.title}</span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </>) : <div />}
                          </div>
              
                        </div>
              
                      </div>
                    </div>
                  </div>
                  )}
                  {selectedTheme === "Techy" && Object.keys(user).length >0 && (
                    <div className="resume-print-layout">
                    <div className="theme-4" ref={ref}>
                      <div>
                        <link rel="stylesheet" href="style3.css" />
                        <div className="header">
                          <div className="main-heading-name">
                            <div className="first-name" style={{ wordWrap: "break-word" }}>
                              {user.name.split(" ")[0] + " " + (user.name.split(" ")[2] ? user.name.split(" ")[2] : user.name.split(" ")[1] ? user.name.split(" ")[1] : "")}
                              <br />
                            </div>
                          </div>
                          <div className="designation" style={{ color: "white" }}>
                            {user.designation}
                          </div>
                        </div>
                        <div className="content">
                          <div className="about-section">
                            <img src={user.image_url || avatar} />
                            <div className="contact-section">
                              <h1>CONTACT</h1>
                              <p style={{ wordWrap: "break-word" }}>
                                {user.phone}
                                <br />
                                {user.email}
                                <br />
                                {user.city}
                                <br />
                                {user.linked_in_url}
                              </p>
                            </div>
                            {user &&
                              user.educations &&
                              user.educations.length !== 0 ? (
                              <>
                                <div className="education-section">
                                  <h1>EDUCATION</h1>
                                  {user &&
                                    user.educations &&
                                    user.educations.map((item, index) => {
                                      return (
                                        <div key={index} style={{}}>
                                          {item.course ? (
                                            <div>
                                              <p>{item.course}, </p> <p>{item.degree} </p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p>{item.degree} </p>
                                            </div>
                                          )}
                                          <div>
                                            <p>{item.college}</p>
                                          </div>
              
                                          <p>
                                            {new Date(item.start_year).getFullYear()}
                                            {" - "}{" "}
                                            {item.grad_year
                                              ? new Date(item.grad_year).getFullYear()
                                              : "Present"}
                                            <br />
                                            <br />
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>) : <div />}
                            {user &&
                              user.skills &&
                              user.skills.length !== 0 ? (
                              <>
                                <div className="skills-section">
                                  <h1>SKILLS</h1>{" "}
                                  <ul>
                                    {user &&
                                      user.skills &&
                                      user.skills.map((item, index) => {
                                        const i = JSON.parse(item);
                                        return <li>{i.label}</li>;
                                      })}
                                  </ul>
                                </div>
                              </>) : <div />}
              
                          </div>
                          <div className="line" />
                          <div className="right-section">
                            <div className="bio-section">
                              <h1>BIO</h1>
                              <p style={{ marginTop: "0px" }}>{user.bio}</p>
                            </div>
                            {user &&
                              user.experiences &&
                              user.experiences.length !== 0 ? (
                              <>
                                <div className="employment-section">
                                  <h1>EMPLOYMENT</h1>
                                  {user &&
                                    user.experiences &&
                                    [...user.experiences].map((item, index) => {
                                      console.log(item);
                                      return (
                                        <Row key={index} style={{ marginTop: "10px" }}>
                                          <div>
                                            {item.role}
                                            <br />
                                            {item.emp_type} {" | "}
                                            {item.company}
                                            {" | "}
                                            {new Date(item.start_year).getFullYear()}
                                            {" - "}{" "}
                                            {item.grad_year
                                              ? new Date(item.grad_year).getFullYear()
                                              : "Present"}
                                          </div>
                                        </Row>
                                      );
                                    })}
                                </div>
                              </>) : <div />}
                            {savedRowsCourses &&
                              savedRowsCourses &&
                              savedRowsCourses.length !== 0 ? (
                              <>
                                <div className="courses-section">
                                  <h1>COURSES</h1>
                                  <ul style={{ marginTop: "10px" }}>
                                    {savedRowsCourses &&
                                      savedRowsCourses &&
                                      savedRowsCourses.map((item, index) => {
                                        return <li>{item.title}</li>;
                                      })}
                                  </ul>
                                </div>
                              </>) : <div />}
                            {savedRowsCertificates &&
                              savedRowsCertificates &&
                              savedRowsCertificates.length !== 0 ? (
                              <>
                                <div className="certifications-section">
                                  <h1>CERTIFICATIONS</h1>
                                  <ul style={{ marginTop: "10px" }}>
                                    {savedRowsCertificates &&
                                      savedRowsCertificates &&
                                      savedRowsCertificates.map((item, index) => {
                                        return <li>{item.title}</li>;
                                      })}
                                  </ul>
                                </div>
                              </>) : <div />}
              
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                  {selectedTheme === "Formal" && Object.keys(user).length >0 && (
                    <div className="resume-print-layout">
                    <div className="theme-5" ref={ref}>
                      <div className="content">
                        <div className="left-section">
                          <img src={user.image_url || avatar} />
                          <div className="bio-section">
                            <h1>BIO</h1>
                            <p>{user.bio}</p>
                          </div>
                          <div className="contact-section">
                            <h1>CONTACT</h1>
                            <p>
                              PHONE:
                              <br />
                              {user.phone}
                            </p>
                            <p>
                              LINKEDIN:
                              <br />
                              {user.linked_in_url}
                            </p>
                            <p>
                              EMAIL
                              <br />
                              <span style={{ wordWrap: "break-word" }}>
                                {user.email}
                              </span>
                            </p>
                            <p>
                              LOCATION
                              <br />
                              {user.city}
                            </p>
                          </div>
                        </div>
              
                        <div className="right-section">
                          <div className="title-section">
                            {user.name.split(" ")[0] + " " + (user.name.split(" ")[2] ? user.name.split(" ")[2] : user.name.split(" ")[1] ? user.name.split(" ")[1] : "")}
                            <br />
                            <p> {user.designation}</p>
                          </div>
                          {user &&
                            user.educations &&
                            user.educations.length !== 0 ? (
                            <>
                              <div className="education-section">
                                <h1>EDUCATION</h1>
                                <div className="line" />
                                {user &&
                                  user.educations &&
                                  user.educations.map((item, index) => {
                                    return (
                                      <Row key={index}>
                                        {item.course ? (
                                          <p>
                                            <strong>{item.college}</strong>
                                            <br />
                                            {item.course},{item.degree} <br />
                                            {new Date(item.start_year).getFullYear()}
                                            {" - "}{" "}
                                            {item.grad_year
                                              ? new Date(item.grad_year).getFullYear()
                                              : "Present"}
                                            <br />
                                          </p>
                                        ) : (
                                          <p>
                                            <strong>{item.college}</strong>
                                            <br />
                                            {item.degree} <br />
                                            {new Date(item.start_year).getFullYear()}
                                            {" - "}{" "}
                                            {item.grad_year
                                              ? new Date(item.grad_year).getFullYear()
                                              : "Present"}
                                            <br />
                                          </p>
                                        )}
                                      </Row>
                                    );
                                  })}
                              </div>
                            </>) : <div />}
                          {user &&
                            user.experiences &&
                            user.experiences.length !== 0 ? (
                            <>
                              <div className="work-section">
                                <h1>WORK EXPERIENCE</h1>
                                <div className="line" />
                                {user &&
                                  user.experiences &&
                                  [...user.experiences].map((item, index) => {
                                    console.log(item);
                                    return (
                                      <Row key={index}>
                                        <p>
                                          <strong>{item.company}</strong>
                                          <br />
                                          {item.role}
                                          <br />
                                          {new Date(item.start_year).getFullYear()}
                                          {" - "}{" "}
                                          {item.grad_year
                                            ? new Date(item.grad_year).getFullYear()
                                            : "Present"}
                                        </p>
                                      </Row>
                                    );
                                  })}
                              </div>
                            </>) : <div />}
                          {user &&
                            user.skills &&
                            user.skills.length !== 0 ? (
                            <>
                              <div className="skills-section">
                                <h1>SKILLS</h1>
                                <div className="line" />
                                <ul>
                                  {user &&
                                    user.skills &&
                                    user.skills.map((item, index) => {
                                      const i = JSON.parse(item);
                                      return <li>{i.label}</li>;
                                    })}
                                </ul>
                              </div>
                            </>) : <div />}
              
                          {savedRowsCourses &&
                            savedRowsCourses &&
                            savedRowsCourses.length !== 0 ? (
                            <>
                              <div className="courses-section">
                                <h1>COURSES</h1>
                                <div className="line" />
                                <ul>
                                  {user &&
                                    savedRowsCourses &&
                                    savedRowsCourses.map((item, index) => {
                                      return <li>{item.title}</li>;
                                    })}
                                </ul>
                              </div>
                            </>) : <div />}
                          {savedRowsCertificates &&
                            savedRowsCertificates &&
                            savedRowsCertificates.length !== 0 ? (
                            <>
                              <div
                                className="certifications-section"
                                style={{ marginBottom: "30px" }}
                              >
                                <h1>CERTIFICATIONS</h1>
                                <div className="line" />
                                <ul>
                                  {savedRowsCertificates &&
                                   savedRowsCertificates &&
                                   savedRowsCertificates.map((item, index) => {
                                      return <li>{item.title}</li>;
                                    })}
                                </ul>
                              </div>
                            </>) : <div />}
              
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                  <div
                    className="top-border-box-g"
                    style={{
                      backgroundColor: "#E2EAE7",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="font-size-14 font-weight-100"
                      style={{ color: "#111111", textAlign: "left", flex: "3" }}
                    >
                      The Preview may not match the downloaded Resume.
                    </div>
                    
                    <Pdf targetRef={ref} filename="resume.pdf">
                      {({ toPdf }) => <button
                      style={{ flex: "1" }}
                      className="btn btn-outline-green waves-effect waves-light w-lg mr-2"
                      type="button"
                      onClick={toPdf}
                    >
                      Download Resume
                    </button>}
                    </Pdf>
                    <button
                      style={{ flex: "1" }}
                      className="btn btn-outline-green waves-effect waves-light w-lg mr-2"
                      type="button"
                      onClick={() => openModal()}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              </Col>
              {/* <Col lg="7">
                        {selectedTheme === 'Elegant' && <Elegant data={props.user}/>} 
                        {selectedTheme === 'Professional' && <Elegant data={props.user}/>} 
                        {selectedTheme === 'Modern' && <Elegant data={props.user}/>} 
                        {selectedTheme === 'Techy' && <Elegant data={props.user}/>} 
                        {selectedTheme === 'Formal' && <Elegant data={props.user}/>} 
                        </Col> */}
              <Col lg="4">
                <div className="choose-theme">
                  <p>Choose Theme</p>
                  <ul className="pl-0 list-unstyled theme-block-container">
                    {themeBlocks.map((val, index) => {
                      return (
                        <li
                          key={index}
                          className="theme-block position-relative"
                          onClick={() => themeSelect(index)}
                        >
                          <div>
                            {console.log(
                              val.name === selectedTheme
                                ? "selected_theme"
                                : "",
                              val
                            )}
                            <img
                              src={val.imgPath}
                              alt={val.name}
                              className={
                                val.name === selectedTheme
                                  ? "selected_theme"
                                  : ""
                              }
                              style={
                                val.selected
                                  ? {
                                    border: "2px solid #213d30",
                                    borderRadius: "10px",
                                    width: "5rem",
                                  }
                                  : {
                                    borderRadius: "10px",
                                    width: "5rem",
                                  }
                              }
                            />
                          </div>
                          <div
                            className={classnames("pt-2", {
                              "font-weight-bold": val.name === selectedTheme,
                            })}
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            {val.name}{" "}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="mt-4 text-left">
                  <button
                    className="btn btn-outline-green waves-effect waves-light w-lg mr-2"
                    type="button"
                    onClick={() => openModal()}
                  >
                    Preview Resume
                  </button>
                  <button
                    className="btn bg-green waves-effect waves-light w-lg"
                    type="button"
                    onClick={openResume}
                  >
                    Save Resume
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.user,
    ...state.education,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllUserData: getAllUserData,

      addEducationData: addEducationData,
      updateEducationData: updateEducationData,
      deleteEducationData: deleteEducationData,

      addEmpData: addEmpData,
      updateEmpData: updateEmpData,
      deleteEmpData: deleteEmpData,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResumeBuilder)
);
