import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import TextArea from "../../../components/TextArea";
import ApiService from "../../../config/restapi";
import Common from "../../../helpers/common";

const WorkRelated = (props) => {
  const [motivatescharcomplete, setMotivatescharcomplete] = useState(0);
  const [mosticharcomplete, setMosticharcomplete] = useState(0);
  const [termcharcomplete, setTermcharcomplete] = useState(0);
  const [employeecharcomplete, setEmployeecharcomplete] = useState(0);

  const [workQuestions, setWorkQuestions] = useState({
    motivation: "",
    what_imp: "",
    term_length: "",
    open_ended: "",
  });

  function getProfileData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.data && res.data.workplace_q) {
        setWorkQuestions({
          ...workQuestions,
          motivation: res.data.workplace_q.motivation
            ? res.data.workplace_q.motivation
            : "",
          what_imp: res.data.workplace_q.what_imp
            ? res.data.workplace_q.what_imp
            : "",
          term_length: res.data.workplace_q.term_length
            ? res.data.workplace_q.term_length
            : "",
          open_ended: res.data.workplace_q.open_ended
            ? res.data.workplace_q.open_ended
            : "",
        });
      }
    });
  }

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    props.workQuestionsValCallBack(workQuestions);
  }, [workQuestions]);

  const onChangeTextareaHandler = (event, type) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(
      event.target.value.replace(/[^\w\s\.\,]/gi, "")
    );
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    switch (type) {
      case "motivates":
        if (WordCount <= 500){
          setMotivatescharcomplete(WordCount);
          setWorkQuestions({ ...workQuestions, motivation: event.target.value });
        }
        break;
      case "mosti":
        if (WordCount <= 500){
          setMosticharcomplete(WordCount);
          setWorkQuestions({ ...workQuestions, what_imp: event.target.value });
        } 
        break;
      case "term":
        if (WordCount <= 500){
          setTermcharcomplete(WordCount);
          setWorkQuestions({ ...workQuestions, term_length: event.target.value });
        }
        break;
      case "employee":
        if (WordCount <= 500){
          setEmployeecharcomplete(WordCount);
          setWorkQuestions({ ...workQuestions, open_ended: event.target.value });
        } 
        break;
      default:
        break;
    }
  };

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={2} className="work-related">
        <Form>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What motivates you to come to work everyday?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "motivates")
                }
                
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={motivatescharcomplete}
                value={workQuestions.motivation}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What are you most passionate about?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "mosti")
                }
                
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={mosticharcomplete}
                value={workQuestions.what_imp}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="Given an opportunity, would short term or long term onsite opportunity interest you?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "term")
                }
                
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={termcharcomplete}
                value={workQuestions.term_length}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What it means to be an efficient and reliable employee?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "employee")
                }
               
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={employeecharcomplete}
                value={workQuestions.open_ended}
              />
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default WorkRelated;
