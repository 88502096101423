import React, { useState, useEffect } from "react";
import classnames from "classnames";
import trusted_user_icon from "../assets/images/trusted_user_icon.png";
import verified_user_icon from "../assets/images/verified_user_icon.png";
import secure_user_icon from "../assets/images/secure_user_icon.png";
import cloudOutline from "../assets/images/cloud-outline.svg";
import { Form, FormGroup, Input, Modal } from "reactstrap";
import fileIcon from "../assets/images/file_icon.svg";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import s3api from "../helpers/s3apis";
import ApiService from "../config/restapi";
import Common from "../helpers/common";

const AddBackgroundVerificationModal = (props) => {
  const [bgDetails, setBgDetails] = useState({
    aadhaar: "",
    passport: "",
    PAN: "",
    ed_documents: "",
    pfnumber: "",
  });
  const [errors,setErrors] = useState(false);
  //   const [aadhar, setAadhar] = useState("");

  //   const [passport, setPassport] = useState("");

  function validateFileExtention(files, type) {
    var ext = files && files[0].name.split(".").pop();
    if (type === "ed_documents") {
      if (ext === "zip" || ext === "rar") return true;
    } else {
      if (ext === "pdf" || ext === "jpeg" || ext === "jpg" || ext === "png")
        return true;
    }
    return false;
  }

  useEffect(() => {
    ApiService.getBackgroundDocsDetails().then((res) => {
      if (res && res.data) {
        console.log(res.data)
        setBgDetails({
          ...res.data,
          pfnumber:res.data.pf_details,
        });
      }
    });
  }, []);

  function callapi() {
    ApiService.getBackgroundDocsDetails().then((res) => {
      if (res && res.data) {
        setBgDetails({
          ...res.data,
          pfnumber:res.data.pf_details,
        });
      }
    });
  }

  const handleAcceptedFiles = async (files, doctype) => {
    if (validateFileExtention(files, doctype)) {
      if (files) {
        var ext = files && files[0].name.split(".").pop();
        var fileid = doctype;
        if (files[0].size < 5242880) {
          await s3api.upload("backgroundVerification", ext, files[0], fileid);
          callapi();
        }
        else {
          toastr.error("Please Upload a file less than 5 MB", "", { timeOut: 2000 });
        }
      }
    } else {
      toastr.error("Please Upload Valid Format", "", { timeOut: 2000 });
    }
  };

  function handleDelete(id, type) {
    try {
      ApiService.deleteDocsBackgroundVerification({ id }).then((res) => {
        if (res && res.data) {
          let tempObj = bgDetails;
          if (type === "Aadhaar") delete tempObj.aadhaar;
          if (type === "Passport") delete tempObj.passport;
          if (type === "PAN") delete tempObj.PAN;
          if (type === "Education Doc") delete tempObj.ed_documents;
          setBgDetails({ ...tempObj });
          toastr.success(`${type} Deleted Successfully!`, "", {
            timeOut: 2000,
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmit() {
    if(!bgDetails.pfnumber.match(/^[A-Z]{5}[\d+]{10,17}$/ig)){
      setErrors(true);
      return;
    }
    ApiService.addPfDetails({pf_details:bgDetails.pfnumber}).then((res) => {
      if (res && res.data) {
        props.onModalSubmit()
      }
    });
    
  }

  return (
    <Modal
      size="lg"
      isOpen={props.modal_center}
      toggle={() => {
        props.tog_center();
      }}
      className="bg-verification-modal"
    >
      {console.log(bgDetails)}
      {/* {console.log(bgDetails.aadhaar.length)} */}
      {console.log(typeof bgDetails.passport)}

      {console.log(typeof bgDetails.aadhaar)}
      <Form>
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myLargeModalLabel"
            style={{ alignSelf: "center" }}
          >
            Background Verification
          </h5>
          <button
            onClick={() => {
              props.tog_center(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" style={{ fontSize: "2rem" }}>
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="container p-0">
            <div className="top-border-box-g">
              <div
                className="text-center font-size-18 font-weight-medium"
                style={{ color: "#111111" }}
              >
                We care about your privacy
              </div>
              <div
                className="text-center font-size-14 pt-1 font-weight-100"
                style={{ color: "#111111" }}
              >
                We only share this information with the clients so they can get
                a better understanding about your candidature. This helps you
                get ahead of others in the job hunt
              </div>
              <div className="d-flex justify-content-center mt-4">
                <div className="mx-3">
                  <div className="d-flex justify-content-center">
                    <img src={verified_user_icon} alt="verified_user_icon" />
                  </div>

                  <div
                    className="text-center mt-2 font-weight-100"
                    style={{
                      color: "#213D30",
                    }}
                  >
                    Become a verified user
                  </div>
                </div>
                <div className="mx-3">
                  <div className="d-flex justify-content-center">
                    <img src={secure_user_icon} alt="secure_user_icon" />
                  </div>
                  <div className="text-center mt-2 font-weight-100">
                    100% Secure
                  </div>
                </div>
                <div className="mx-2 my-auto">
                  {" "}
                  <div className="d-flex justify-content-center ">
                    <img src={trusted_user_icon} alt="trusted_user_icon" />
                  </div>
                  <div className="text-center mt-2 font-weight-100">
                    Trusted and Verified
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-3 mb-3 f-18">Documents Required</div>
            <div className="d-flex justify-content-center flex-sm-row flex-column">
              <div>
                <div className="box-personal mr-4 px-2 py-2">
                  <div className="pb-1 font-size-14 font-weight-normal">
                    Personal Verification
                  </div>
                  <div className="font-size-11 text-muted font-weight-100"></div>
                  <div className="mt-2 upload">
                    <div style={{ marginBottom: "8px" }}>
                      {bgDetails && !bgDetails.aadhaar && (
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            // console.log(acceptedFiles.id);
                            handleAcceptedFiles(acceptedFiles, "aadhaar");
                          }}
                          // onDrop={async ([selectedFile]) => {
                          //   // Step 1 - get pre-signed POST data.
                          //   const {
                          //     data: presignedPostData,
                          //   } = await getPresignedPostData(selectedFile);
                          //   // Step 2 - upload the file to S3.
                          //   try {
                          //     console.log(selectedFile);
                          //     console.log(presignedPostData);
                          //     const { name } = selectedFile;
                          //     await uploadFileToS3(presignedPostData, name);
                          //     console.log("File was successfully uploaded!");
                          //   } catch (e) {
                          //     console.log("An error occurred!", e.message);
                          //   }
                          // }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div>
                              <div className=" needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="needsclick d-flex align-items-center">
                                  <button
                                    type="button"
                                    className="d-flex align-items-center btn w-lg btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                                  >
                                    {" "}
                                    <img
                                      className="float-left"
                                      src={cloudOutline}
                                      alt="cloud"
                                    />
                                    <div className="font-size-11 ml-2">
                                      {" "}
                                      UPLOAD AADHAR
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}

                      {bgDetails && bgDetails.aadhaar && (
                        <div className="company-logo-img">
                          <img
                            src={bgDetails.aadhaar.link}
                            alt=""
                            className=""
                          />
                          <span
                            className="img-close"
                            onClick={() =>
                              handleDelete(bgDetails.aadhaar.id, "Aadhaar")
                            }
                          >
                            &times;
                          </span>
                        </div>
                        // <div>
                        //     <a className='cut-text-ellipsis mr-2'
                        //         title={bgDetails.aadhaar.key}
                        //         href={bgDetails.aadhaar.link} target='_blank'>
                        //         {bgDetails.aadhaar.key}
                        //     </a>
                        //     <button
                        //         type='button'
                        //         onClick={() => {
                        //             handleDelete(
                        //                 bgDetails.aadhaar.id,
                        //                 "Aadhaar"
                        //             );
                        //         }}
                        //     >
                        //         Delete
                        //     </button>
                        // </div>
                      )}
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                      {bgDetails && !bgDetails.passport && (
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles, "passport");
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div>
                              <div className=" needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="needsclick d-flex align-items-center">
                                  <button
                                    type="button"
                                    className="d-flex align-items-center btn w-lg btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                                  >
                                    {" "}
                                    <img
                                      className=""
                                      src={cloudOutline}
                                      alt="cloud"
                                    />
                                    <div className="font-size-11 ml-2">
                                      {" "}
                                      UPLOAD PASSPORT
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}
                      {bgDetails && bgDetails.passport && (
                        <div className="company-logo-img ml-2">
                          <img
                            src={bgDetails.passport.link}
                            alt=""
                            className=""
                          />
                          <span
                            className="img-close"
                            onClick={() =>
                              handleDelete(bgDetails.passport.id, "Passport")
                            }
                          >
                            &times;
                          </span>
                        </div>
                        // <div className='ml-1'>
                        //     <a  className='cut-text-ellipsis mr-2'
                        //         title={bgDetails.passport.key}
                        //         href={bgDetails.passport.link}
                        //         target='_blank'>
                        //         {bgDetails.passport.key}
                        //     </a>
                        //     <button
                        //         type='button'
                        //         onClick={() =>
                        //             handleDelete(
                        //                 bgDetails.passport.id,
                        //                 "Passport"
                        //             )
                        //         }
                        //     >
                        //         Delete
                        //     </button>
                        // </div>
                      )}
                    </div>{" "}
                  </div>
                </div>
                <div className="box-personal mt-3 mr-4 px-2 py-2">
                  <div className="pb-1 font-size-14 font-weight-normal">
                    PAN
                  </div>
                  <div className="font-size-11 text-muted font-weight-100"></div>
                  <div className="mt-2 upload">
                    <div style={{ marginBottom: "8px" }}>
                      {bgDetails && !bgDetails.PAN && (
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles, "PAN");
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div>
                              <div className=" needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="needsclick">
                                  <button
                                    type="button"
                                    className="d-flex align-items-center btn w-lg btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                                  >
                                    {" "}
                                    <img
                                      className=""
                                      src={cloudOutline}
                                      alt="cloud"
                                    />
                                    <div className="font-size-11 ml-2">
                                      {" "}
                                      UPLOAD PAN CARD
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}
                      {bgDetails && bgDetails.PAN && (
                        <div className="company-logo-img">
                          <img src={bgDetails.PAN.link} alt="" className="" />
                          <span
                            className="img-close"
                            onClick={() =>
                              handleDelete(bgDetails.PAN.id, "PAN")
                            }
                          >
                            &times;
                          </span>
                        </div>
                        // <div>
                        //     <a  className='cut-text-ellipsis mr-2'
                        //         title={bgDetails.PAN.key}
                        //         target="_blank"
                        //         href={bgDetails.PAN.link}>
                        //         {bgDetails.PAN.key}
                        //     </a>
                        //     <button
                        //         type='button'
                        //         onClick={() =>
                        //             handleDelete(
                        //                 bgDetails.PAN.id,
                        //                 "PAN"
                        //             )
                        //         }
                        //     >
                        //         Delete
                        //     </button>
                        // </div>
                      )}
                    </div>
                    <div style={{ marginBottom: "8px" }}></div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box-personal px-2 py-2 bg_verfication_top_mobile">
                  <div className="pb-1 font-size-14 font-weight-normal">
                    Educational Documents
                  </div>
                  <div className="font-size-11 text-muted font-weight-100">
                    Upload a zip file of your latest degree certificates
                  </div>
                  <div className="mt-2">
                    {bgDetails && !bgDetails.ed_documents && (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles, "ed_documents");
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div>
                            <div className=" needsclick" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="needsclick">
                                <button
                                  type="button"
                                  className="d-flex btn btn-outline-shadow waves-effect waves-light bg-uplaod-btn"
                                >
                                  {" "}
                                  <img
                                    className="float-left"
                                    src={cloudOutline}
                                    alt="cloud"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    )}
                    {bgDetails && bgDetails.ed_documents && (
                      <div className="company-logo-img">
                        <img src={fileIcon} alt="" className="" />
                        <span
                          className="img-close"
                          onClick={() =>
                            handleDelete(
                              bgDetails.ed_documents.id,
                              "Education Doc"
                            )
                          }
                        >
                          &times;
                        </span>
                      </div>
                      // <div>
                      //     <a  className='cut-text-ellipsis mr-2'
                      //         title={bgDetails.ed_documents.key}
                      //         target='_blank'
                      //         href={bgDetails.ed_documents.link}>
                      //         {bgDetails.ed_documents.key}
                      //     </a>
                      //     <button
                      //         type='button'
                      //         onClick={() =>
                      //             handleDelete(
                      //                 bgDetails.ed_documents.id,
                      //                 "Education Doc"
                      //             )
                      //         }
                      //     >
                      //         Delete
                      //     </button>
                      // </div>
                    )}
                  </div>
                </div>
                <div className="box-personal px-2 py-2 mt-3">
                  <div className="pb-1 font-size-14 font-weight-norma">PF</div>
                  <div className="font-size-11 text-muted font-weight-100"></div>
                  <FormGroup className="mb-0 mt-2">
                    <Input
                      type="tel"
                      maxLength={22}
                      placeholder="UAN-PF Account number"
                      value={bgDetails.pfnumber}
                      onChange={(e) => {
                        e.target.value = Common.trimStart(
                          e.target.value.replace(/[^\w\s\.\,]/gi, "")
                        );
                        setBgDetails({
                          ...bgDetails,
                          pfnumber: e.target.value,
                        });
                      }}
                    />
                    {
                                errors && (
                                  <label
                                  style={{ "margin-bottom": "0", color: "red" }}
                                >
                                  Please Enter the correct Format
                                </label>
                                )
                              }
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              props.tog_center();
            }}
            className={classnames(
              "btn font-light-green btn-outline-light-green waves-effect waves-light w-lg mr-2"
            )}
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn bg-light-green waves-effect waves-light w-lg"
            onClick={() => handleSubmit()}
            disabled={
              !bgDetails.pfnumber ||
              !bgDetails.aadhaar ||
              !bgDetails.passport ||
              !bgDetails.PAN ||
              !bgDetails.ed_documents
                ? true
                : false
            }
          >
            Submit
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddBackgroundVerificationModal;
