import axios from "axios";
//https://api.zunavish.com/api
const API_END_POINT = "https://api.zunavish.com/api";
const headers = {
  "Content-Type": "application/json",
  headers: { "Access-Control-Allow-Origin": "*" },
};

export default class RestApi {
  static getProfileData() {
    return axios.get(API_END_POINT + "/users/user", headers);
  }
  static getPublicProfileData(id) {
    return axios.get(API_END_POINT + "/users/user/public", { params: { id } });
  }

  static saveOnboardingPersonalDetails(data) {
    return axios.put(
      API_END_POINT + "/onboarding/personal_details",
      data,
      headers
    );
  }

  static saveEducationDetailsOnBoarding(data) {
    return axios.post(API_END_POINT + "/onboarding/educations", data, headers);
  }

  static editEducationDetailsOnBoarding(data) {
    return axios.put(API_END_POINT + "/profile/edit/educations", data, headers);
  }

  static deleteEducationDetailsOnBoarding(data, callback) {
    // return axios.delete(API_END_POINT+'/profile/delete/educations', {headers,data});
    let header = {
      token: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    return fetch(API_END_POINT + "/profile/delete/educations", {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static saveEmploymentDetailsOnBoarding(data) {
    return axios.post(API_END_POINT + "/onboarding/experiences", data, headers);
  }

  static editEmploymentDetailsOnBoarding(data) {
    return axios.put(
      API_END_POINT + "/profile/edit/experiences",
      data,
      headers
    );
  }

  static deleteEmploymentDetailsOnBoarding(data) {
    let header = {
      token: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };
    return fetch(API_END_POINT + "/profile/delete/experiences", {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  static savePersonalQuestionsOnBoarding(data) {
    return axios.post(
      API_END_POINT + "/onboarding/personal_questions",
      data,
      headers
    );
  }

  static saveJobQuestionsOnBoarding(data) {
    return axios.post(
      API_END_POINT + "/onboarding/job_questions",
      data,
      headers
    );
  }

  static saveWorkQuestionsOnBoarding(data) {
    return axios.post(
      API_END_POINT + "/onboarding/work_questions",
      data,
      headers
    );
  }

  static editProfileData(data) {
    return axios.put(
      API_END_POINT + "/profile/edit/personal_details",
      data,
      headers
    );
  }

  static updateProfilePhoto(data) {
    return axios.post(
      API_END_POINT + "/profile/add/profile_image",
      data,
      headers
    );
  }

  static getJobListByOffset(offset) {
    return axios.get(
      API_END_POINT + `/dashboard/jobs?offset=${offset}`,
      headers
    );
  }

  static uploadJobResume(data) {
    return axios.post(API_END_POINT + "/jobs/upload/resume", data, headers);
  }

  static applyJob(data) {
    return axios.post(API_END_POINT + "/jobs/apply", data, headers);
  }

  static addPfDetails(data) {
    return axios.post(API_END_POINT + "/dashboard/add/pf_details", data, headers);
  }

  static uploadDocsBackgroundVerification(data, doctype) {
    const headr = {
      "Content-Type": "application/json",
      headers: { "Access-Control-Allow-Origin": "*" },
      params: { doctype },
    };
    return axios.post(
      API_END_POINT + "/dashboard/upload/documents",
      data,
      headr
    );
  }

  static deleteDocsBackgroundVerification(data) {
    return axios.post(
      API_END_POINT + "/dashboard/delete/documents",
      data,
      headers
    );
  }

  static getBackgroundDocsDetails() {
    return axios.get(API_END_POINT + "/dashboard/documents", headers);
  }

  static getJobList(value) {
    return axios.get(API_END_POINT + `/users/search?query=${value}`, headers);
  }

  static getS3UploadURL(filetype, ext, id) {
    const headr = {
      headers: {
        token: `${localStorage.getItem("token")}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    if (id) {
      return axios.post(
        API_END_POINT + "/uploadURL",
        {
          fileType: filetype,
          fileId: id,
          fileExtension: ext,
        },
        headr
      );
    } else {
      return axios.post(
        API_END_POINT + "/uploadURL",
        {
          fileType: filetype,

          fileExtension: ext,
        },
        headr
      );
    }
  }
  static uploadToS3(url, file) {
    return axios.put(url, file, {
      headers: {
        "Content-Type": file.type
      }
    });

  }
}
