import React, { useState, useEffect  } from 'react';
import {  Modal, Row, Col } from "reactstrap";
import TextArea from '../../components/TextArea';


const ViewBackgroundVModal = (props) => {


    useEffect(() => {
    },[])
   
    return (
        <Modal
            isOpen={props.modal_center}
            centered={props.centered}
            className="edit-bio-modal"
        >
            <div className="modal-header">
                <div className="modal-title mt-0 font-weight-bold" id="myModalLabel">
                    Documents
                </div>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) } }
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="">
                    <Col lg="12" >
                        {props.editedData && (!props.editedData.aadhaar && !props.editedData.PAN &&
                            !props.editedData.passport && !props.editedData.ed_documents) &&
                            <div>
                                    No documents to view
                            </div>
                        }

                        {!props.editedData &&
                            <div>
                                No documents to view
                            </div>
                        }

                        {props.editedData && props.editedData.aadhaar && <div className=''>
                            <div>
                                <a href={props.editedData.aadhaar} target="_blank"> Aadhaar </a>
                            </div>
                        </div>}

                        {props.editedData && props.editedData.PAN && <div className=' mt-2'>
                            <div>
                                <a href={props.editedData.PAN} target="_blank"> PAN </a>
                            </div>
                        </div>}

                        {props.editedData && props.editedData.passport && <div className='mt-2'>
                            <div>
                                <a href={props.editedData.passport} target="_blank"> Passport </a>
                            </div>
                        </div>}

                        {props.editedData && props.editedData.ed_documents && <div className=' mt-2'>
                            <div>
                                <a href={props.editedData.ed_documents} target="_blank"> Education Documents </a>
                            </div>
                        </div>}
                        
                    </Col>
                </Row>                
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn bg-cancel btn-lg waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
            </div>
        </Modal>
      );
    }
    
export default ViewBackgroundVModal;