import api from '../../config/axios';
import { USER_DETAILS, USER_DETAILS_ERROR, USER_DETAILS_LOADING } from '../user';

export const getAllUserData = () => async dispatch => {
    dispatch({
        type: USER_DETAILS_LOADING,
    });
    try {
        const response = await api.get('/api/users/user',
            {
                headers: {
                    'token': `${localStorage.getItem('token')}`
                }
            });
        if (response.status === 200) {
            dispatch({
                type: USER_DETAILS,
                payload: response.data,
            });
        }
        else {
            dispatch({
                type: USER_DETAILS_ERROR,
            });
        }
    }
    catch (e) {
        dispatch({
            type: USER_DETAILS_ERROR,
        })
    }

}