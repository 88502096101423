export const JOB_DETAIL_LOADING = "JOB_DETAIL_LOADING";
export const JOB_DETAIL_SUCCESS = "JOB_DETAIL_SUCCESS";
export const JOB_DETAIL_ERROR = "JOB_DETAIL_ERROR";
export const JOB_APPLY_LOADING = "JOB_APPLY_LOADING";
export const JOB_APPLY_SUCCESS = "JOB_APPLY_SUCCESS";
export const JOB_APPLY_FAILURE = "JOB_APPLY_FAILURE";

const initialstate = {
  loading: false,
  error: null,
  name: "",
  id: "",
  title: "",
  company: "",
  location: "",
  description: "",
  posted_at: "",
  applicationStatus: "",
  company_log: ""
};

const job = (state = initialstate, action) => {
  switch (action.type) {
    case JOB_DETAIL_LOADING:
      state = { ...state, loading: true, error: null };
      break;
    case JOB_DETAIL_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;
    case JOB_DETAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        ...action.payload.foundJob,
        applicationStatus:action.payload.applicationStatus,
        company_log: action.payload.foundJob.organization.logo_url
      };
      break;
    case JOB_APPLY_LOADING:
      state = { ...state, apply_loading: true, apply_error: null };
      break;
    case JOB_APPLY_FAILURE:
      state = { ...state, apply_loading: false, apply_error: action.payload };
      break;
    case JOB_APPLY_SUCCESS:
      state = {
        ...state,
        apply_loading: false,
        apply_error: null
      };
      break;
  }
  return state;
};
export default job;
