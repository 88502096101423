import api from "../../config/axios";
import {
  JOB_APPLY_LOADING,
  JOB_APPLY_FAILURE,
  JOB_APPLY_SUCCESS,
  JOB_DETAIL_ERROR,
  JOB_DETAIL_LOADING,
  JOB_DETAIL_SUCCESS,
} from "../job";
export const getJobDetail = (id) => async (dispatch) => {
  dispatch({
    type: JOB_DETAIL_LOADING,
  });
  try {
    const response = await api.get(`/api/jobs/?id=${id}`, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: JOB_DETAIL_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: JOB_DETAIL_ERROR,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({
      type: JOB_DETAIL_ERROR,
      payload: e.data,
    });
  }
};
export const jobApply = (id, formData, email, contact) => async (dispatch) => {
  dispatch({
    type: JOB_APPLY_LOADING,
  });
  try {
    const res_upload = await api.post(`/api/jobs/upload/resume`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        token: `${localStorage.getItem("token")}`,
      },
    });

    const body = {
      jobId: id,
      email,
      link: res_upload.data.data.Location,
      contact,
    };
    if (res_upload.status === 200) {
      const response = await api.post(`/api/jobs/apply`, body, {
        headers: {
          token: `${localStorage.getItem("token")}`,
        },
      });
      if (response.status === 200) {
        dispatch({
          type: JOB_APPLY_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: JOB_APPLY_FAILURE,
          payload: response.data,
        });
      }
    } else {
      dispatch({
        type: JOB_APPLY_FAILURE,
        payload: res_upload.data,
      });
    }
  } catch (e) {
    dispatch({
      type: JOB_APPLY_FAILURE,
      payload: e.data,
    });
  }
};
