import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import default_company_logo from '../../assets/images/d-company-logo.png';
import timeSince from '../../helpers/timeSinceFormatter.js';
import time_icon from "../../assets/images/time.svg";
import location from "../../assets/images/location.svg";


export default class Card extends Component {
    render() {
        if (!this.props.job) {
            return (<Fragment></Fragment>)
        }
        return (
            <Link to={`/jobs/${this.props.job.id}`} className="rem-link-def">
                <div className="box-200 card-trans">
                    <div className="d-flex justify-content-center">
                        <img className="company-logos mt-3" src={this.props.isCompanyLogo ? this.props.isCompanyLogo : default_company_logo} alt="image-1"></img>
                    </div>
                    <br></br>
                    <div className="text-center mb-2 font-size-14 job-card-company cut-text-ellipsis" title={this.props.job.company}>{this.props.job.company}</div>
                    <div className="text-center mb-2 f-16 job-card-title cut-text-ellipsis" title={this.props.job.title}>{this.props.job.title}</div>
                    <div className="black-border"></div>
                    <div className="row w-90 mx-auto mt-2">
                        <div className="col-12 d-flex justify-content-between p-0">
                            <div className="f-12 text-md-center cut-text-ellipsis" title={this.props.job.location} style={{color:"#7E8384"}}>
                                {/* <i className="fas fa-map-marker-alt mb-2 mr-1"></i> */}
                                <img className='pr-1 mr-1' src={location} alt='location' style={{color:"black"}}/>
                                {this.props.job.is_remote_loc ? 'Work from home' : this.props.job.location }
                            </div>
                            <div className="f-12 text-md-center location-time" style={{color:"#7E8384"}}>
                                <img className='pr-1 mr-1' src={time_icon} alt='time_icon' />
                                {timeSince(new Date(this.props.job.createdAt))} ago
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}
