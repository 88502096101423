import api from '../../../config/axios';
import { EMP_DETAILS, EMP_DETAILS_ERROR, EMP_DETAILS_LOADING, EMP_DETAILS_UPDATE, EMP_DELETE } from '../../profile/emp';


export const addEmpData = (data) => async dispatch => {
    dispatch({
        type:EMP_DETAILS_LOADING,
    });
    try {
        
        const response = await api.post('/api/onboarding/experiences',data,
            {
                headers: {
                    'token': `${localStorage.getItem('token')}`
                }
            });
            
        if (response.status === 200) {
            dispatch({
                type: EMP_DETAILS,
                payload: response.data,
            });
        }
        else {
            dispatch({
                type: EMP_DETAILS_ERROR,
            });
        }
    }
    catch (e) {
        dispatch({
            type: EMP_DETAILS_ERROR,
        })
    }

}


export const updateEmpData = (data) => async dispatch => {
    dispatch({
        type:EMP_DETAILS_LOADING,
    });
    try {
        const response = await api.put('/api/profile/edit/experiences',data,
            {
                headers: {
                    'token': `${localStorage.getItem('token')}`
                }
            });
        if (response.status === 200) {
            dispatch({
                type: EMP_DETAILS_UPDATE,
                payload: data,
            });
        }
        else {
            dispatch({
                type: EMP_DETAILS_ERROR,
            });
        }
    }
    catch (e) {
        dispatch({
            type: EMP_DETAILS_ERROR,
        })
    }
}



export const deleteEmpData = (data) => async dispatch => {
    dispatch({
        type:EMP_DETAILS_LOADING,
    });
    try {
        const response = await api.delete('/api/profile/delete/experiences',
            {
                headers: {
                    'token': `${localStorage.getItem('token')}`
                },
                data
            });
        if (response.status === 200) {
            dispatch({
                type: EMP_DELETE,
                payload: data,
            });
        }
        else {
            dispatch({
                type: EMP_DETAILS_ERROR,
            });
        }
    }
    catch (e) {
        dispatch({
            type: EMP_DETAILS_ERROR,
        })
    }

}