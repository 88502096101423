import React, { useState, useEffect } from 'react';
import { Row, Col, TabContent, TabPane } from "reactstrap";
import SavedRows from '../../components/SavedRows';
import AddEducationalModal from './AddEducationalModal';

const Education = (props) =>{
    console.log(props.years);
    const [savedRows, setSavedRows] = useState([]);
    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const editEducationalRow = (row,type)=>{
        props.editEducationalRow(row,type);
        tog_center(true);
    }

    const deleteEducationalRow = (row,type) =>{
        props.deleteEducationalRow(row,type);
    }

    const openModal = () => {
       props.setEducationalDataNull();
        tog_center();
    }

    const createEducationalRow = (data,type) => {
        props.createEducationalRow(data,type);
        tog_center(false);
    }

     //.log('Education Data', props);

    return(
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="2" className="educational-tab">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                Educational details
                            </h6>
                            <span className="font-size-18 pr-3 cursor-pointer" onClick={()=> { openModal()}}>
                                +
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        {props.savedRowsEducation && props.savedRowsEducation.length > 0 && props.savedRowsEducation.map((data,index)=>{
                            return ( <SavedRows
                                data={data}
                                index={index}
                                editRow={editEducationalRow}
                                deleteRow={deleteEducationalRow}
                                type="educational"
                                key={index}
                            /> )
                        })  }
                    </Col>
                </Row>
            </TabPane>
            <AddEducationalModal  
                modal_center={modal_center}
                centered={true}
                tog_center={()=>tog_center()}
                onModalSubmit={createEducationalRow}
                educationEditedData={props.educationEditedData}
                years={props.years}
            />
        </TabContent>
    )
}
export default Education;