import React from 'react';
import { FormGroup, Label, Input } from "reactstrap";

const JobRelated = (props) =>{

    return(
        <FormGroup className="text-left">
            <Label for="basicpill-firstname-input1" className='font-size-16'> {props.labelName} <span className="label-required"> * </span> </Label>
            <Input required={true} type="textarea" value={props.value ? props.value : '' } placeholder={props.placeholder ? props.placeholder : ''} maxLength={props.maxLength} onChange={(event)=>props.onChangeTextareaHandler(event)} />
            {props.isCharLimitShow && 
                <div className="text-right">
                    <span className="font-size-12 font-weight-light count_color"> {props.charComplete?props.charComplete:0}/{props.charlimitLength} </span>
                </div>
            }
        </FormGroup>
    )
}

export default  JobRelated;