import React, { Component } from 'react'

export default class Skillscard extends Component {
    render() {
        return (
            <div className="skills-card py-2">
                <div className="f-14 f-gray ml-1">
                    Skills (6)
                </div>
                <div  className="f-14 mb-1 f-gray ml-1">Must Have</div>
                <div className="row"> 
                    <div className="ml-3 gray-round-box-s mt-1 mb-1 f-12">Design</div>
                    <div className="ml-3 gray-round-box-s mt-1 mb-1 f-12">Problem Solving</div>
                    <div className="ml-3 gray-round-box-s mt-1 mb-1 f-12">Workflows</div>
                </div>
                <div className="f-14 f-gray ml-1 mt-1 mb-1">Good to have</div>
                <div className="row">
                    <div className="ml-3 gray-round-box-s mt-1 mb-1 f-12">Design</div>
                    <div className="ml-3 gray-round-box-s mt-1 mb-1 f-12">Problem Solving</div>
                    <div className="ml-3 gray-round-box-s mt-1 mb-1 f-12">Workflows</div>
                </div>
            </div>
        )
    }
}
