import React, { useState } from "react";

import { connect } from "react-redux";

import { Link, NavLink, useHistory } from "react-router-dom";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ApiService from "../../config/restapi";
// Redux Store
import { toggleLeftmenu } from "../../redux/actions/layout";
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/Zunavish_Logo_SVG.svg";
import Common from "../../helpers/common";

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  console.log(window.location.pathname);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [joblist, setJobList] = useState("");
  function startSearch(value) {
    ApiService.getJobList(value).then((res) => {
      if (res && res.status === 200) {
        setJobList({ ...res.data });
      } else if (res.data.success === "false") {
        console.log(res);
        toastr.error(res.data.message, "", { timeOut: 2000 });
      } else {
        console.log(res);
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      }
    });
  }

  let history = useHistory();
  const toJobs = (id) => {
    history.push(`/jobs/${id}`);
    window.location.reload();
  };

  return (
    <React.Fragment>
      {/* {console.log(joblist)} */}
      <header id="page-topbar">
        <div className="navbar-header">
          <Link to="/dashboard" className="logo logo-dark">
            <img src={logo} alt="" />
          </Link>
          {/* <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => { props.toggleLeftmenu(!props.leftMenu); }}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button> */}
          <div className="d-flex justify-content-end flex-grow-1">
            <form
              className="app-search d-none d-lg-block"
              // onBlur={() => {
              //   setJobList("");
              // }}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control text-muted-1"
                  placeholder="Search Jobs, Assessments and Courses"
                  onChange={(e) => {
                    e.target.value = Common.trimStart(e.target.value);
                    setSearch(e.target.value);
                    console.log(e.target.value);
                    if (search) {
                      startSearch(e.target.value);
                    } else {
                      setJobList("");
                    }
                  }}
                />
                <span className="bx bx-search-alt"></span>
              </div>
              <div
                className={
                  search && joblist
                    ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                {joblist.jobs ? (
                  <div
                    style={{
                      margin: "8px",
                      listStyle: "none",
                      color: "#000",
                      opacity: "0.8",
                    }}
                  >
                    {joblist &&
                      joblist.jobs &&
                      joblist.jobs.length > 0 &&
                      joblist.jobs
                        .filter((item) => {
                          if (search == "") {
                            console.log("IF");
                          } else if (
                            item.company
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            item.title
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return item;
                          }
                        })
                        .map((item, index) => {
                          // console.log(item);
                          return (
                            <li
                              className="hoverChange"
                              key={index}
                              onClick={() => {
                                toJobs(item.id);
                              }}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <div>
                                <div
                                  style={{
                                    display: "inline",
                                    fontSize: "16px",
                                    marginRight: "2px",
                                  }}
                                >
                                  {item.title}
                                </div>
                              </div>
                              <div
                                style={{
                                  color:
                                    "rgba(156, 163, 175, var(--tw-text-opacity))",
                                }}
                              >
                                #{item.id}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#74b496",
                                }}
                              >
                                <i className="fas fa-map-marker-alt pr-2" />
                                {item.location || "Remote Job"}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "4px",
                                  borderTop: "1px solid #eeee",
                                }}
                              />
                            </li>
                          );
                        })}
                  </div>
                ) : (
                  <ul
                    style={{ listStyle: "none", color: "#000", opacity: "0.8" }}
                  >
                    <li
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {joblist.message}
                    </li>
                  </ul>
                )}
              </div>
            </form>
          </div>
          <div className="d-flex align-items-center justify-content-center font-size-16 header-font">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                onClick={() => setIsSearch(!isSearch)}
                // onBlur={() => setIsSearch(!isSearch)}
                // onChange={(e) => {
                //   setSearch(e.target.value);
                //   console.log(e.target.value);
                //   if (search) {
                //     startSearch();
                //   } else {
                //     setJobList("");
                //   }
                // }}
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div
                    className="form-group m-0"
                    // onBlur={() => {
                    //   setJobList("");
                    // }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        aria-label="Recipient's username"
                        onChange={(e) => {
                          e.target.value = Common.trimStart(e.target.value);

                          setSearch(e.target.value);
                          console.log(e.target.value);
                          if (search) {
                            startSearch(e.target.value);
                          } else {
                            setJobList("");
                          }
                        }}
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>{" "}
                  <div
                    className={
                      isSearch && search && joblist
                        ? "dropdown-menu dropdown-menu-lg dropdown-search p-0 show"
                        : "dropdown-menu dropdown-menu-lg dropdown-search p-0"
                    }
                    aria-labelledby="page-header-search-dropdown"
                  >
                    {joblist.jobs ? (
                      <div
                        style={{
                          margin: "8px",
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        {joblist &&
                          joblist.jobs &&
                          joblist.jobs.length > 0 &&
                          joblist.jobs
                            .filter((item) => {
                              if (search == "") {
                                console.log("IF");
                              } else if (
                                item.company
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase()) ||
                                item.title
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => {
                              // console.log(item);
                              return (
                                <li
                                  className="hoverChange"
                                  key={index}
                                  onClick={() => {
                                    toJobs(item.id);
                                  }}
                                  style={{
                                    padding: "8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{
                                        display: "inline",
                                        fontSize: "16px",
                                        marginRight: "2px",
                                      }}
                                    >
                                      {item.title}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        "rgba(156, 163, 175, var(--tw-text-opacity))",
                                    }}
                                  >
                                    #{item.id}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#74b496",
                                    }}
                                  >
                                    <i className="fas fa-map-marker-alt pr-2" />
                                    {item.location || "Remote Job"}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: "4px",
                                      borderTop: "1px solid #eeee",
                                    }}
                                  />
                                </li>
                              );
                            })}
                      </div>
                    ) : (
                      <ul
                        style={{
                          listStyle: "none",
                          color: "#000",
                          opacity: "0.8",
                        }}
                      >
                        <li
                          style={{
                            padding: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {joblist.message}
                        </li>
                      </ul>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="d-inline-block d-lg-none pr-2 font-size-18 green-text-color cursor-pointer">
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block header-dropdown-add"
              >
                <DropdownToggle
                  className="btn header-item waves-effect"
                  tag="button"
                >
                  <i className="fa fa-angle-down header_down_arrow"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a" onClick={()=>{
                            history.push('/dashboard')
                        }} to="/dashboard">
                    Jobs
                  </DropdownItem>
                  <DropdownItem tag="a" onClick={()=>{
                            history.push('/dashboar')
                        }} to="/dashboar">
                    Assessments
                    <span className="route_soon ml-1"> (Coming Soon)</span>
                  </DropdownItem>
                  <DropdownItem tag="a" onClick={()=>{
                            history.push('/resumeDashboard')
                        }} to="/resumeDashboard">
                    Resume Builder
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="d-none d-lg-block pl-4">
              <NavLink
                className="header-item"
                activeClassName="active"
                to={
                  window.location.pathname === "/dashboard"
                    ? "/dashboard"
                    : "/jobs"
                    ? "/jobs"
                    : "/abcd"
                }
                style={{ color: "#213D30" }}
              >
                Jobs
              </NavLink>
            </div>
            <div className="d-none d-lg-block pl-5">
              <NavLink
                className="header-item position-relative"
                activeClassName="active"
                to="/dashboar"
                style={{ color: "#213D30" }}
              >
                Assessments
                <span className="route_soon">(Coming Soon)</span>
              </NavLink>
            </div>
            {/* <div className="d-inline-block pl-5">
                  <NavLink className="header-item" activeClassName="active" to="/courses" >COURSES</NavLink>                
              </div> */}
            <div className="d-none d-lg-block pl-5 pr-2">
              <NavLink
                className="header-item"
                activeClassName="active"
                to="/resumeDashboard"
                style={{ color: "#213D30" }}
              >
                Resume Builder
              </NavLink>
            </div>
            <div
              className="mx-2"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/notifications")}
            >
              <NotificationDropdown />
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, { toggleLeftmenu })(Header);
