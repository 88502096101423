import api from "../../../config/axios";
import {
  EDUCATION_DETAILS,
  EDUCATION_DETAILS_ERROR,
  EDUCATION_DETAILS_LOADING,
  EDUCATION_UPDATE,
  EDUCATION_DELETE,
} from "../../profile/education";

export const addEducationData = (data) => async (dispatch) => {
  dispatch({
    type: EDUCATION_DETAILS_LOADING,
  });
  try {
    const response = await api.post("/api/onboarding/educations", data, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });

    if (response.status === 200) {
      dispatch({
        type: EDUCATION_DETAILS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: EDUCATION_DETAILS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: EDUCATION_DETAILS_ERROR,
    });
  }
};

export const updateEducationData = (data) => async (dispatch) => {
  dispatch({
    type: EDUCATION_DETAILS_LOADING,
  });
  try {
    const response = await api.put("/api/profile/edit/educations", {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
      data,
    });
    if (response.status === 200) {
      dispatch({
        type: EDUCATION_UPDATE,
        payload: data,
      });
    } else {
      dispatch({
        type: EDUCATION_DETAILS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: EDUCATION_DETAILS_ERROR,
    });
  }
};

export const deleteEducationData = (data) => async (dispatch) => {
  dispatch({
    type: EDUCATION_DETAILS_LOADING,
  });
  try {
    const response = await api.delete("/api/profile/delete/educations", data, {
      headers: {
        token: `${localStorage.getItem("token")}`,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: EDUCATION_DELETE,
        payload: data,
      });
    } else {
      dispatch({
        type: EDUCATION_DETAILS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: EDUCATION_DETAILS_ERROR,
    });
  }
};
