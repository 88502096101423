import React from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";


const ResumeRow = (props) => {

    return (<Row className="m-0 mt-2 mb-4">
        <Col lg="12" className="added-row">
            <Row>
                <Col lg="8" className="">
                    <h5 className="font-size-18">{props.data.resume_name}   </h5>

                    <div className="text-muted font-weight-light font-size-14 mt-3">
                        Last Edited: {`${new Date(props.data.updatedAt).toDateString()}`} | {`${(new Date(props.data.updatedAt))
                            .toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`}
                    </div>
                </Col>
                <Col lg="4" className="text-right">

                    <span className="pl-2">
                        <i className="bx bx-pencil font-size-18 cursor-pointer" onClick={() => props.editResume(props.data.id)}></i>
                    </span>
                    <span className="pl-2">
                        <i className="mdi mdi-delete-outline font-size-18 cursor-pointer" onClick={() => props.deleteResume(props.data.id)}></i>
                    </span>
                </Col>
            </Row>



        </Col>
    </Row>
    );
}

export default ResumeRow;
