import React, { useState, useEffect } from "react";

import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import blankImg from "../../assets/images/users/blank_profile.svg";
import "react-phone-number-input/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber
} from "react-phone-number-input/input";
import cloudOutline from "../../assets/images/cloud-outline.svg";
import getAge from "../../helpers/getAge";
import DateRangePicker from "../../components/DateRangePicker";
import ApiService from "../../config/restapi";
import s3api from "../../helpers/s3apis";
import Common from "../../helpers/common";

const EditProfileModal = (props) => {
  const [showError, setShowError] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [country, setCountry] = useState("IN");

  const [fname, setFName] = useState();
  const [designation, setDesignation] = useState();
  const [lname, setLName] = useState();
  const [email, setEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [localcountry, setLocalCountry] = useState();
  const [city, setCity] = useState();
  const [zipcode, setZipCode] = useState();
  const [lnkurl, setLnkUrl] = useState();
  const [dob, setDob] = useState();
  const [age, setAge] = useState();
  const [profilepic, setProfilepic] = useState(null);
  const [isdCode, setIsdCode] = useState();

  const [selectedCountry, setSelectedCountry] = useState("Select");
  const [selectedDistrict, setSelectedDistrict] = useState("Select");
  const countries = [
    { name: "Select", districts: ["Select"] },
    {
      name: "India",
      districts: [
        "Select",
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Hyderabad",
        "Ahmedabad",
        "Chennai",
        "Kolkata",
        "Surat",
        "Pune",
        "Jaipur",
        "Lucknow",
        "Kanpur",
        "Nagpur",
        "Indore",
        "Thane",
        "Bhopal",
        "Visakhapatnam",
        "Pimpri-Chinchwad",
        "Patna",
        "Vadodara",
        "Ghaziabad",
        "Ludhiana",
        "Agra",
        "Nashik",
        "Faridabad",
        "Meerut",
        "Rajkot",
        "Kalyan-Dombivali",
        "Vasai-Vira",
        "Varanasi",
        "Srinagar",
        "Aurangabad",
        "Dhanbad",
        "Amritsar",
        "NaviMumbai",
        "Allahabad",
        "Ranchi",
        "Howrah",
        "Coimbatore",
        "Jabalpur",
        "Gwalior",
        "Vijayawada",
        "Jodhpur",
        "Madurai",
        "Raipur",
        "Kota",
      ],
    },
  ];
  const changeCountry = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedDistrict("Select");
    if (event.target.value === "Select") {
      OnchangeHandler("", "localcountry");
      OnchangeHandler("", "city");
    } else {
      OnchangeHandler(event.target.value, "localcountry");
      OnchangeHandler("", "city");
    }
  };

  const changeDistrict = (event) => {
    if (event.target.value === "Select") {
      OnchangeHandler("", "city");
    } else {
      OnchangeHandler(event.target.value, "city");
    }
    setSelectedDistrict(event.target.value);
  };

  useEffect(() => {
    if (props.editedData) {
      setProfilepic(props.editedData.image_url);
      setFName(props.editedData.name);
      setDesignation(props.editedData.designation);
      setEmail(props.editedData.email);
      setMobileNumber(props.editedData.phone);
      setCity(props.editedData.city);
      setLnkUrl(props.editedData.linked_in_url);
      setLocalCountry(props.editedData.country);
      setDob(
        props.editedData.date_of_birth
          ? new Date(props.editedData.date_of_birth)
          : ""
      );
      setAge(props.editedData.age);
      setIsdCode(props.editedData.isd_code || "IN");
      setSelectedCountry(props.editedData.country);
      setSelectedDistrict(props.editedData.city);
      setZipCode(props.editedData.zipcode);
    }
  }, [props.editedData, props.modal_center]);

  // function handleUpload(file) {
  //   try {
  //     if (!file) {
  //       throw new Error("Select a file first!");
  //     }
  //     const formData = new FormData();
  //     formData.append("file",file[0]);
  //     setProfilepic(formData);
  //     handleAcceptedFiles(file);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  function checkname(name, s) {
    if (name === "" || !name) {
      return { message: `${s} should not be blank`, valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: `Please enter a valid ${s}`, valid: false };
    }
  }
  function checkEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return { message: "", valid: true };
    }
    return { message: "Please enter a valid email address", valid: false };
  }
  function checkmobileNumber(number) {
    console.log(isPossiblePhoneNumber("+"+getCountryCallingCode(isdCode)+number));
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (isPossiblePhoneNumber("+"+getCountryCallingCode(isdCode)+number)) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkCountry(value) {
    if (value === "select" || !value) {
      return { message: "Select a country", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkCity(value) {
    if (value === "Select" || !value) {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkzipcode(value) {
    if (/^[0-9]+$/.test(value)) {
      return { message: "", valid: true };
    } else {
      if (value === "" || value === undefined) {
        return { message: "Zip code cannot be blank.", valid: false };
      } else {
        return { message: "Please enter a valid zip code", valid: false };
      }
    }
  }

  function checkDob(age) {
    if (age) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your date of birth", valid: false };
    }
  }

  function checkMarital(marital) {
    if (marital) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your marital status", valid: false };
    }
  }

  function checkHasChildren(children) {
    if (children === true || children === false) {
      return { message: "", valid: true };
    } else {
      return { message: "Choose if you have/dont have children", valid: false };
    }
  }
  function validateFileExtention(file) {
    var ext = file.name.split(".").pop();
    if (
      ext &&
      (ext.toLowerCase() === "jpeg" ||
        ext.toLowerCase() === "png" ||
        ext.toLowerCase() === "gif" ||
        ext.toLowerCase() === "jpg")
    ) {
      return true;
    }
    return false;
  }

  // onChange={(e) =>
  //   handleUpload(
  //     e.target.files)
  // }

  const handleAcceptedFiles = async (file) => {
    if (validateFileExtention(file)) {
      console.log(file);
      var ext = file.name.split(".").pop();
      if (file.size < 5242880) {
        const url = await s3api.upload("profilePic", ext, file);
        OnchangeHandler(url, "image_url");
      } else {
        toastr.error("Select a file of size less than 5 MB", "", {
          timeOut: 2000,
        });
      }

      // // setselectedFiles(files);
      // const formData = new FormData();
      // formData.append("file", files[0]);

      // //setselectedFiles(formData);
      // // const reader = new FileReader();
      // // reader.addEventListener("load", () => {
      // //   setProfilepic(reader.result);
      // // });
      // // reader.readAsDataURL(files[0]);
      // ApiService.updateProfilePhoto(formData).then((res) => {
      //   if (res && res.status === 200 && res.data) {
      //     setProfilepic(res.data.link);
      //   }
      // });
    } else {
      toastr.error("Please Upload Valid Photo", "", { timeOut: 2000 });
    }
  };

  function clearData() {}

  const removeProfilePic = () => {
    setselectedFiles("");
    setProfilepic(null);
  };

  const OnchangeHandler = (value, type) => {
    switch (type) {
      case "fname":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setFName(value);
        break;
      case "designation":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setDesignation(value);
        break;
      case "lname":
        value = Common.trimStart(value.replace(/[^a-zA-Z\s]/gi, ""));
        setLName(value);
        break;
      case "email":
        value = value.replace(/[^\w\s\.\,@]/gi, "").trimStart();
        setEmail(value);
        break;
      case "mobileNumber":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setMobileNumber(value);
        break;
      case "localcountry":
        setLocalCountry(value);
        break;
      case "city":
        setCity(value);
        break;
      case "zipcode":
        value = Common.trimStart(value.replace(/[^0-9]/gi, ""));
        setZipCode(value);
        break;
      case "lnkurl":
        setLnkUrl(value);
        break;
      case "dob":
        setDob(value);
        setAge(getAge(value));
        break;
      case "age":
        setAge(value);
        break;
      case "isd_code":
        setIsdCode(value);
        break;
      case "image_url":
        setProfilepic(value);
        break;
      default:
        break;
    }
  };
  function handleSavedChanges() {
    if (
      !checkname(fname, "name").valid ||
      !checkEmail(email).valid ||
      !checkname(designation, "designation").valid ||
      !checkmobileNumber(mobileNumber).valid ||
      !city
    ) {
      console.log(checkname(fname, "name").valid);
      console.log(checkEmail(email).valid);
      console.log(checkmobileNumber(mobileNumber).valid);
      console.log(checkname(designation, "designation").valid);
      console.log(city);
      console.log("here");
      setShowError(true);
      return;
    }
    props.onModalSubmit(
      {
        name: fname,
        designation,
        email,
        age,
        phone: mobileNumber,
        country: localcountry,
        city,
        zipcode,
        linked_in_url: lnkurl,
        id: props.editedData.id,
        date_of_birth: dob,
        isd_code: isdCode,
        image_url: profilepic,
      },
      "edit"
    );
    clearData();
  }

  return (
    <Modal
      isOpen={props.modal_center}
      centered={props.centered}
      size="lg"
      className="edit-profile-modal"
    >
      <div className="modal-header">
        <h6 className="modal-title mt-0" id="myModalLabel">
          Edit Profile
        </h6>
        <button
          type="button"
          onClick={() => {
            props.tog_center(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg="12">
            <FormGroup>
              {/* <Label>Profile Photo</Label> */}
              <div className="d-flex">
                <div className="profile-logo-container">
                  <div className="profile-logo-content">
                    <img
                      src={profilepic ? profilepic : blankImg}
                      alt=""
                      className="avatar-sm"
                    />
                  </div>
                </div>
                <div className="upload-section">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles[0]);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span className="font-size-12">
                            <button
                              className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                              type="button"
                            >
                              <img src={cloudOutline} alt="cloud" />{" "}
                              <span className="pl-1">UPLOAD PHOTO</span>
                            </button>
                          </span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {profilepic && (
                    <div className="pt-1 pl-1">
                      <button
                        className="waves-effect waves-light btn btn-outline-shadow w-md font-size-12 ml-2"
                        type="button"
                        onClick={() => removeProfilePic()}
                      >
                        <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required" />
                        <span className="pl-1 label-required">
                          REMOVE PHOTO{" "}
                        </span>
                      </button>
                    </div>
                  )}
                  File size limit: 5mb
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Full Name <span className="label-required"> * </span>
              </Label>
              <Input
                type="text"
                value={fname || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "fname")}
                className="form-control"
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkname(fname, "Name").message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-designation-input1">
                Designation <span className="label-required"> * </span>
              </Label>
              <Input
                maxLength="30"
                type="text"
                value={designation || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "designation")}
                className="form-control"
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkname(designation, "Designation").message}
                </label>
              )}
            </FormGroup>
          </Col>
          {/* <Col lg="6">
                        <FormGroup className="text-left">
                            <Label for="basicpill-firstname-input1">Last Name </Label>
                            <Input type="text"value={lname || ''} onChange={(e)=>OnchangeHandler(e.target.value,'lname')} className="form-control"  />
                        </FormGroup>
                    </Col> */}
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="mb-4 text-left">
              <Label>
                D.O.B <span className="label-required"> * </span>
              </Label>
              <InputGroup>
                <DateRangePicker
                  propsref="birthcalendar"
                  proptype="birth"
                  propsid="dobpicker"
                  className="form-control"
                  selected={dob || ""}
                  onChange={(val) => OnchangeHandler(val, "dob")}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showMonthDropdown={true}
                  showYearDropdown={true}
                />
                <label htmlFor="dobpicker">
                  <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                </label>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="cust-age-width text-left">
              <Label for="basicpill-lastname-input2">Age</Label>
              <Input
                type="text"
                value={age || ""}
                readOnly
                onChange={(event) => {}}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Email Address <span className="label-required"> * </span>
              </Label>
              <Input
                disabled
                type="email"
                value={email || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "email")}
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkEmail(email).message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Phone Number <span className="label-required"> * </span>
              </Label>
              <Row>
                <Col lg="3" className="pr-0">
                  <select
                    className="form-control phone-number-select"
                    value={isdCode || ""}
                    onChange={(event) => {
                      OnchangeHandler(event.target.value, "isd_code");
                    }}
                  >
                    {getCountries().map((country) => (
                      <option key={country} value={country}>
                        {country} +{getCountryCallingCode(country)}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col lg="9">
                  {/* <PhoneInput
                    country={country ? country : 'IN'}
                    international
                    value={mobileNumber}
                    onChange={(v) => OnchangeHandler(v, 'mobileNumber')}
                    className="form-control phone-number-input"
                  /> */}
                  <Input
                    type="tel"
                    maxLength={13}
                    value={mobileNumber || ""}
                    onChange={(e) =>
                      OnchangeHandler(e.target.value, "mobileNumber")
                    }
                  />
                </Col>
                {showError && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {checkmobileNumber(mobileNumber).message}
                  </label>
                )}
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label>
                Country<span className="label-required"> * </span>
              </Label>
              <Input
                type="select"
                name="select"
                onChange={(event) => changeCountry(event)}
              >
                {countries.map((e, key) => {
                  return (
                    <option key={key} selected={selectedCountry === e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </Input>
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkCountry(selectedCountry).message}
                </label>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-lastname-input2">
                City/District<span className="label-required"> * </span>
              </Label>
              <Input
                type="select"
                onChange={(event) => changeDistrict(event)}
                name="select"
                id="exampleCity"
              >
                {countries.find((i) => i.name === selectedCountry) &&
                  countries
                    .find((i) => i.name === selectedCountry)
                    .districts.map((e, key) => {
                      return (
                        <option key={key} selected={selectedDistrict === e}>
                          {e}
                        </option>
                      );
                    })}
              </Input>
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkCity(selectedDistrict).message}
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">ZIP Code </Label>
              <Input
                type="text"
                maxLength="6"
                value={zipcode || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "zipcode")}
              />
              {showError && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {checkzipcode(zipcode).message}
                </label>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup className="text-left">
              <Label for="basicpill-firstname-input1">
                Linked In Profile URL
              </Label>
              <Input
                type="text"
                placeholder="https://www,linkedin.com/in/yourURL"
                value={lnkurl || ""}
                onChange={(e) => OnchangeHandler(e.target.value, "lnkurl")}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            props.tog_center();
          }}
          className="btn bg-cancel btn-lg waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-light-green btn-lg waves-effect waves-light"
          onClick={() => {
            handleSavedChanges();
          }}
        >
          Save Changes{" "}
        </button>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
