import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import moment from "moment";
import "./customcss.css";
import DateRangePicker from "../../../components/DateRangePicker";

const PersonalDetails = (props) => {
  const [charComplete, setCharComplete] = useState(0);
  const [country, setCountry] = useState(props.country || "");
  const [selectedCountry, setSelectedCountry] = useState(
    props.country || "Select"
  );
  const [selectedDistrict, setSelectedDistrict] = useState(
    props.city || "Select"
  );
  const countries = [
    { name: "Select", districts: ["Select"] },
    {
      name: "India",
      districts: [
        "Select",
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Hyderabad",
        "Ahmedabad",
        "Chennai",
        "Kolkata",
        "Surat",
        "Pune",
        "Jaipur",
        "Lucknow",
        "Kanpur",
        "Nagpur",
        "Indore",
        "Thane",
        "Bhopal",
        "Visakhapatnam",
        "Pimpri-Chinchwad",
        "Patna",
        "Vadodara",
        "Ghaziabad",
        "Ludhiana",
        "Agra",
        "Nashik",
        "Faridabad",
        "Meerut",
        "Rajkot",
        "Kalyan-Dombivali",
        "Vasai-Vira",
        "Varanasi",
        "Srinagar",
        "Aurangabad",
        "Dhanbad",
        "Amritsar",
        "NaviMumbai",
        "Allahabad",
        "Ranchi",
        "Howrah",
        "Coimbatore",
        "Jabalpur",
        "Gwalior",
        "Vijayawada",
        "Jodhpur",
        "Madurai",
        "Raipur",
        "Kota",
      ],
    },
    {
      name: "US",
      districts: [
        "Select",
        "Aberdeen",
        "Abilene",
        "Akron",
        "Albany",
        "Albuquerque",
        "Alexandria",
        "Allentown",
        "Amarillo",
        "Anaheim",
        "Anchorage",
        "Ann Arbor",
        "Antioch",
        "Apple Valley",
        "Appleton",
        "Arlington",
        "Arvada",
        "Asheville",
        "Athens",
        "Atlanta",
        "Atlantic City",
        "Augusta",
        "Aurora",
        "Austin",
        "Bakersfield",
        "Baltimore",
        "Barnstable",
        "Baton Rouge",
        "Beaumont",
        "Bel Air",
        "Bellevue",
        "Berkeley",
        "Bethlehem",
        "Billings",
        "Birmingham",
        "Bloomington",
        "Boise",
        "Boise City",
        "Bonita Springs",
        "Boston",
        "Boulder",
        "Bradenton",
        "Bremerton",
        "Bridgeport",
        "Brighton",
        "Brownsville",
        "Bryan",
        "Buffalo",
        "Burbank",
        "Burlington",
        "Cambridge",
        "Canton",
        "Cape Coral",
        "Carrollton",
        "Cary",
        "Cathedral City",
        "Cedar Rapids",
        "Champaign",
        "Chandler",
        "Charleston",
        "Charlotte",
        "Chattanooga",
        "Chesapeake",
        "Chicago",
        "Chula Vista",
        "Cincinnati",
        "Clarke County",
        "Clarksville",
        "Clearwater",
        "Cleveland",
        "College Station",
        "Colorado Springs",
        "Columbia",
        "Columbus",
        "Concord",
        "Coral Springs",
        "Corona",
        "Corpus Christi",
        "Costa Mesa",
        "Dallas",
        "Daly City",
        "Danbury",
        "Davenport",
        "Davidson County",
        "Dayton",
        "Daytona Beach",
        "Deltona",
        "Denton",
        "Denver",
        "Des Moines",
        "Detroit",
        "Downey",
        "Duluth",
        "Durham",
        "El Monte",
        "El Paso",
        "Elizabeth",
        "Elk Grove",
        "Elkhart",
        "Erie",
        "Escondido",
        "Eugene",
        "Evansville",
        "Fairfield",
        "Fargo",
        "Fayetteville",
        "Fitchburg",
        "Flint",
        "Fontana",
        "Fort Collins",
        "Fort Lauderdale",
        "Fort Smith",
        "Fort Walton Beach",
        "Fort Wayne",
        "Fort Worth",
        "Frederick",
        "Fremont",
        "Fresno",
        "Fullerton",
        "Gainesville",
        "Garden Grove",
        "Garland",
        "Gastonia",
        "Gilbert",
        "Glendale",
        "Grand Prairie",
        "Grand Rapids",
        "Grayslake",
        "Green Bay",
        "GreenBay",
        "Greensboro",
        "Greenville",
        "Gulfport-Biloxi",
        "Hagerstown",
        "Hampton",
        "Harlingen",
        "Harrisburg",
        "Hartford",
        "Havre de Grace",
        "Hayward",
        "Hemet",
        "Henderson",
        "Hesperia",
        "Hialeah",
        "Hickory",
        "High Point",
        "Hollywood",
        "Honolulu",
        "Houma",
        "Houston",
        "Howell",
        "Huntington",
        "Huntington Beach",
        "Huntsville",
        "Independence",
        "Indianapolis",
        "Inglewood",
        "Irvine",
        "Irving",
        "Jackson",
        "Jacksonville",
        "Jefferson",
        "Jersey City",
        "Johnson City",
        "Joliet",
        "Kailua",
        "Kalamazoo",
        "Kaneohe",
        "Kansas City",
        "Kennewick",
        "Kenosha",
        "Killeen",
        "Kissimmee",
        "Knoxville",
        "Lacey",
        "Lafayette",
        "Lake Charles",
        "Lakeland",
        "Lakewood",
        "Lancaster",
        "Lansing",
        "Laredo",
        "Las Cruces",
        "Las Vegas",
        "Layton",
        "Leominster",
        "Lewisville",
        "Lexington",
        "Lincoln",
        "Little Rock",
        "Long Beach",
        "Lorain",
        "Los Angeles",
        "Louisville",
        "Lowell",
        "Lubbock",
        "Macon",
        "Madison",
        "Manchester",
        "Marina",
        "Marysville",
        "McAllen",
        "McHenry",
        "Medford",
        "Melbourne",
        "Memphis",
        "Merced",
        "Mesa",
        "Mesquite",
        "Miami",
        "Milwaukee",
        "Minneapolis",
        "Miramar",
        "Mission Viejo",
        "Mobile",
        "Modesto",
        "Monroe",
        "Monterey",
        "Montgomery",
        "Moreno Valley",
        "Murfreesboro",
        "Murrieta",
        "Muskegon",
        "Myrtle Beach",
        "Naperville",
        "Naples",
        "Nashua",
        "Nashville",
        "New Bedford",
        "New Haven",
        "New London",
        "New Orleans",
        "New York",
        "New York City",
        "Newark",
        "Newburgh",
        "Newport News",
        "Norfolk",
        "Normal",
        "Norman",
        "North Charleston",
        "North Las Vegas",
        "North Port",
        "Norwalk",
        "Norwich",
        "Oakland",
        "Ocala",
        "Oceanside",
        "Odessa",
        "Ogden",
        "Oklahoma City",
        "Olathe",
        "Olympia",
        "Omaha",
        "Ontario",
        "Orange",
        "Orem",
        "Orlando",
        "Overland Park",
        "Oxnard",
        "Palm Bay",
        "Palm Springs",
        "Palmdale",
        "Panama City",
        "Pasadena",
        "Paterson",
        "Pembroke Pines",
        "Pensacola",
        "Peoria",
        "Philadelphia",
        "Phoenix",
        "Pittsburgh",
        "Plano",
        "Pomona",
        "Pompano Beach",
        "Port Arthur",
        "Port Orange",
        "Port Saint Lucie",
        "Port St. Lucie",
        "Portland",
        "Portsmouth",
        "Poughkeepsie",
        "Providence",
        "Provo",
        "Pueblo",
        "Punta Gorda",
        "Racine",
        "Raleigh",
        "Rancho Cucamonga",
        "Reading",
        "Redding",
        "Reno",
        "Richland",
        "Richmond",
        "Richmond County",
        "Riverside",
        "Roanoke",
        "Rochester",
        "Rockford",
        "Roseville",
        "Round Lake Beach",
        "Sacramento",
        "Saginaw",
        "Saint Louis",
        "Saint Paul",
        "Saint Petersburg",
        "Salem",
        "Salinas",
        "Salt Lake City",
        "San Antonio",
        "San Bernardino",
        "San Buenaventura",
        "San Diego",
        "San Francisco",
        "San Jose",
        "Santa Ana",
        "Santa Barbara",
        "Santa Clara",
        "Santa Clarita",
        "Santa Cruz",
        "Santa Maria",
        "Santa Rosa",
        "Sarasota",
        "Savannah",
        "Scottsdale",
        "Scranton",
        "Seaside",
        "Seattle",
        "Sebastian",
        "Shreveport",
        "Simi Valley",
        "Sioux City",
        "Sioux Falls",
        "South Bend",
        "South Lyon",
        "Spartanburg",
        "Spokane",
        "Springdale",
        "Springfield",
        "St. Louis",
        "St. Paul",
        "St. Petersburg",
        "Stamford",
        "Sterling Heights",
        "Stockton",
        "Sunnyvale",
        "Syracuse",
        "Tacoma",
        "Tallahassee",
        "Tampa",
        "Temecula",
        "Tempe",
        "Thornton",
        "Thousand Oaks",
        "Toledo",
        "Topeka",
        "Torrance",
        "Trenton",
        "Tucson",
        "Tulsa",
        "Tuscaloosa",
        "Tyler",
        "Utica",
        "Vallejo",
        "Vancouver",
        "Vero Beach",
        "Victorville",
        "Virginia Beach",
        "Visalia",
        "Waco",
        "Warren",
        "Washington",
        "Waterbury",
        "Waterloo",
        "West Covina",
        "West Valley City",
        "Westminster",
        "Wichita",
        "Wilmington",
        "Winston",
        "Winter Haven",
        "Worcester",
        "Yakima",
        "Yonkers",
        "York",
        "Youngstown",
      ],
    },
  ];

  const [errors, setErrors] = useState({
    name: { valid: false, message: "" },
    mobile: { valid: false, message: "" },
    city: { valid: false, message: "" },
    country: { valid: false, message: "" },
    nchild: { valid: false, message: "" },
    dob: { valid: false, message: "" },
    marital: { valid: false, message: "" },
    children: { valid: false, message: "" },
    about: { valid: false, message: "" },
  });
  function checkBio(name) {
    console.log(name);
    if (name === "" || !name) {
      return { message: "Bio should not be blank", valid: false };
    } else if (isNaN(name?.trim())) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid bio", valid: false };
    }
  }

  const handleTextarea = (event) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) {
      setCharComplete(WordCount);
      return true;
    }
    return false;
  };

  const changeCountry = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedDistrict("Select");
    if (event.target.value === "Select") {
      props.OnchangeCallBack("", "country");
      props.OnchangeCallBack("", "city");
    } else {
      props.OnchangeCallBack(event.target.value, "country");
      props.OnchangeCallBack("", "city");
    }
  };

  const changeDistrict = (event) => {
    if (event.target.value === "Select") {
      props.OnchangeCallBack("", "city");
    } else {
      props.OnchangeCallBack(event.target.value, "city");
    }
    setSelectedDistrict(event.target.value);
  };

  function checkname(name) {
    console.log(name?.length);
    if (name === "" || !name) {
      return { message: "Name should not be blank", valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {

      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid name", valid: false };
    }
  }

  function checkmobileNumber(number) {
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkCountry(value) {
    if (value === "" || !value) {
      return { message: "Select a country", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkCity(value) {
    if (value === "" || !value) {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkChildren(children, nchild) {
    if (children) {
      if (!nchild || nchild === 0) {
        return { message: "Choose the no of children", valid: false };
      } else {
        return { message: "", valid: true };
      }
    } else {
      return { message: "", valid: true };
    }
  }

  function checkDob(age) {
    console.log(age);
    if (age) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your date of birth", valid: false };
    }
  }

  function checkMarital(marital) {
    if (marital) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your marital status", valid: false };
    }
  }

  function checkHasChildren(children) {
    if (children === true || children === false) {
      return { message: "", valid: true };
    } else {
      return { message: "Choose if you have/dont have children", valid: false };
    }
  }

  function checkValidity() {
    for (let i in errors) {
      if (errors[i]["valid"]) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  }

  function handleChildren(value) {
    if (value !== "Select") {
      props.OnchangeCallBack(value, "noofChildren");
    } else {
      props.OnchangeCallBack(0, "noofChildren");
    }
  }

  useEffect(() => {
    console.log(props);
      setErrors({
        name: checkname(props.name),
        mobile: checkmobileNumber(props.mobileNumber),
        country: checkCountry(props.country),
        city: checkCity(props.city),
        nchild: checkChildren(props.children, props.nchild),
        dob: checkDob(props.age),
        marital: checkMarital(props.marital),
        children: checkHasChildren(props.children),
        about: checkBio(props.about),
      });
    console.log(errors);
  }, [
    props.name,
    props.mobileNumber,
    props.country,
    props.city,
    props.children,
    props.nchild,
    props.age,
    props.marital,
    props.about,
  ]);

  useEffect(() => {
      props.OnchangeCallBack(checkValidity(), "validity");
  }, [errors]);
  const change = (value, type) => {
    props.OnchangeCallBack(value, type);
  };

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="personal-details">
        <Form>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">
                  Name <span className="label-required"> * </span>{" "}
                </Label>
                <Input
                  type="text"
                  name="name"
                  maxLength="40"
                  value={props.name || ""}
                  className="form-control"
                  onChange={(event) => {
                    change(event.target.value, "name");
                  }}
                />
                {props.name !== undefined && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    {errors.name.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="mb-4 text-left">
                <Label>Date of birth</Label>
                <InputGroup>
                  <DateRangePicker
                    propsref="birthcalendar"
                    proptype="birth"
                    propsid="dobpicker"
                    selected={props.dobDate || ""}
                    onChange={(val) => props.OnchangeCallBack(val, "dob")}
                    // minDate={moment().subtract(500, "years")}
                    maxDate={moment()}
                    // showDisabledMonthNavigation
                  />
                  <label htmlFor="dobpicker">
                    <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                  </label>
                </InputGroup>
                {props.dobDate !== undefined && props.age !== null && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {errors.dob.message}
                  </label>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="cust-age-width text-left">
                <Label for="basicpill-lastname-input2">Age</Label>
                <Input type="text" value={(props.age)?props.age + " Years " || "":""} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Email Address<span className="label-required"> * </span>
                </Label>
                <Input type="email" disabled value={props.email || ""} />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Phone Number <span className="label-required"> * </span>{" "}
                </Label>

                <Row>
                  <Col lg="6" xs="6" className="pr-0">
                    <select
                      className="form-control phone-number-select"
                      value={props.countrycode || ""}
                      onChange={(event) => {
                        props.OnchangeCallBack(event.target.value, "isd_code");
                      }}
                    >
                      {getCountries().map((country) => (
                        <option key={country} value={country}>
                          {country} +{getCountryCallingCode(country)}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col lg="6" xs="6">
                    {/* <PhoneInput
                                            country={country ? country : 'IN'}
                                            international
                                            value={props.mobileNumber || ''}
                                            onChange={(value)=> props.OnchangeCallBack(value, 'mobile')} 
                                            className="form-control phone-number-input"
                                        /> */}
                    <Input
                      type="tel"
                      maxLength={10}
                      value={props.mobileNumber || ""}
                      onChange={(e) => change(e.target.value, "mobile")}
                    />
                  </Col>
                  {props.mobileNumber !== undefined && (
                    <label
                      style={{
                        "margin-bottom": "0",
                        color: "red",
                        padding: "0px 12px",
                      }}
                    >
                      {errors.mobile.message}
                    </label>
                  )}
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">Marital Status</Label>
                <FormGroup check>
                  <Label className="custom_radio" check>
                    <Input
                      className="custom_radio__input"
                      type="radio"
                      checked={props.marital === "Single" ? true : false}
                      name="radio1"
                      value="Single"
                      onChange={(value) =>
                        props.OnchangeCallBack(value.target.value, "Marital")
                      }
                    />{" "}
                    <div className="custom_radio__radio"></div>Single
                  </Label>
                  <Label className="custom_radio" check>
                    <Input
                      className="custom_radio__input"
                      type="radio"
                      checked={props.marital === "Married" ? true : false}
                      name="radio1"
                      value="Married"
                      onChange={(value) =>
                        props.OnchangeCallBack(value.target.value, "Marital")
                      }
                    />{" "}
                    <div className="custom_radio__radio"></div>Married
                  </Label>
                  <Label className="custom_radio" check>
                    <Input
                      className="custom_radio__input"
                      type="radio"
                      checked={
                        props.marital === "prefer not to share" ? true : false
                      }
                      name="radio1"
                      value="prefer not to share"
                      onChange={(value) =>
                        props.OnchangeCallBack(value.target.value, "Marital")
                      }
                    />{" "}
                    <div className="custom_radio__radio"></div>I prefer not to
                    share
                  </Label>
                </FormGroup>
                {
                  props.marital!==null && (
                    <label style={{ "margin-bottom": "0", color: "red" }}>
                      {errors.marital.message}
                    </label>
                  )
                }
                
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="6">
                  <FormGroup className="text-left">
                    <Label for="basicpill-lastname-input2">Children</Label>
                    <FormGroup check>
                      <Label className="custom_radio" check>
                        <Input
                          className="custom_radio__input"
                          type="radio"
                          checked={props.children === true ? true : false}
                          name="radio2"
                          value="Yes"
                          onChange={(value) =>
                            props.OnchangeCallBack(
                              value.target.value,
                              "Children"
                            )
                          }
                        />{" "}
                        <div className="custom_radio__radio"></div>
                        Yes
                      </Label>
                      <Label className="custom_radio" check>
                        <Input
                          className="custom_radio__input"
                          type="radio"
                          checked={props.children === false ? true : false}
                          name="radio2"
                          value="No"
                          onChange={(value) =>
                            props.OnchangeCallBack(
                              value.target.value,
                              "Children"
                            )
                          }
                        />{" "}
                        <div className="custom_radio__radio"></div>
                        No
                      </Label>
                    </FormGroup>
                    {
                      props.children!==null &&(
                        <label style={{ "margin-bottom": "0", color: "red" }}>
                          {errors.children.message}
                        </label>
                      )
                    }
                    
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="text-left">
                    <Label for="exampleSelect">No. of children</Label>
                    <Input
                      disabled={!props.children ? true : false}
                      value={props.nchild || ""}
                      onChange={(event) => handleChildren(event.target.value)}
                      type="select"
                      name="select"
                    >
                      <option>Select</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                    </Input>
                    <label
                      style={{
                        "margin-bottom": "0",
                        color: "red",
                        padding: "0px 12px",
                      }}
                    >
                      {errors.nchild.message}
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label>Country</Label>
                <Input
                  type="select"
                  name="select"
                  onChange={(event) => changeCountry(event)}
                >
                  {countries.map((e, key) => {
                    return (
                      <option key={key} selected={props.country === e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                </Input>
                {props.country != undefined && (
                  <label
                    style={{
                      "margin-bottom": "0",
                      color: "red",
                      padding: "0px 12px",
                    }}
                  >
                    {errors.country.message}
                  </label>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">City/District</Label>
                <Input
                  type="select"
                  onChange={(event) => changeDistrict(event)}
                  name="select"
                  id="exampleCity"
                >
                  {countries.find((i) => i.name === props.country) &&
                    countries
                      .find((i) => i.name === props.country)
                      .districts.map((e, key) => {
                        return (
                          <option key={key} selected={props.city === e}>
                            {e}
                          </option>
                        );
                      })}
                </Input>
                {props.city != undefined && (
                  <label
                    style={{
                      "margin-bottom": "0",
                      color: "red",
                      padding: "0px 12px",
                    }}
                  >
                    {errors.city.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0 text-left">
                <Label for="basicpill-lastname-input2">
                  Tell us about yourself
                </Label>
                <Input
                  placeholder="Let others know what type of person you are"
                  type="textarea"
                  charlimitLength="500"
                  value={props.about || ""}
                  onChange={(event) => {
                    if (handleTextarea(event)) {
                      props.OnchangeCallBack(event.target.value, "about");
                    }
                  }}
                  name="text"
                />
                <div className="text-right">
                  <span className="font-size-12 font-weight-light">
                    {" "}
                    {charComplete}/500{" "}
                  </span>
                </div>
              </FormGroup>
              {((props.about !== undefined && props.about !== null) || errors?.about.valid) && (
                <label
                  className="float-left"
                  style={{ "margin-bottom": "0", color: "red" }}
                >
                  {errors.about.message}
                </label>
              )}
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default PersonalDetails;
