import React, { useState, useEffect } from 'react';
import { Row, Col, TabContent, TabPane } from "reactstrap";
import EditBioModal from './EditBioModal';
import moment from 'moment';
import ApiService from '../../config/restapi';


const About = (props) =>{

    const [edit_bio_modal, setedit_bio_modal] = useState(false);


    function openEditBioData(){
        setedit_bio_modal(!edit_bio_modal);
        removeBodyCss();
    }

    
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const updateBioData = (data)=> {
        ApiService.saveOnboardingPersonalDetails({bio : data}).then((res) => {
            if(res && res.status === 200 && res.data){
               props.getAllUserData();
               openEditBioData(false);
            }
        });
    }

     function getFormattedDate(date){
        return  date ? moment(new Date(date)).format('DD/MMM/YYYY') : ''
     }

    return(
        <TabContent activeTab={props.activeTab}>
            <TabPane tabId="1" className="about-page">
                <Row>
                    <Col sm="12">
                        <div className="d-flex  justify-content-between">
                            <h6>
                                About
                            </h6>
                            {/* <span>
                                <i className="bx bx-pencil font-size-18"></i>
                            </span> */}
                        </div>
                        <Row>
                            <Col sm="6">
                                <Row>
                                    <Col sm="6"> 
                                        <p className="font-weight-medium font-size-12 mb-0 pt-1 about_heading_text">Date of Birth</p>
                                        <p className="font-weight-light font-size-16 mt-2"> {props.userData.date_of_birth ? getFormattedDate(props.userData.date_of_birth):'--' }</p>
                                    </Col>
                                    <Col sm="6"> 
                                        <p className="font-weight-medium font-size-12 mb-0 pt-1 about_heading_text">Age</p>
                                        <p className="font-weight-light font-size-16 mt-2">{props.userData.age ? props.userData.age:'--' }</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Row>
                                    <Col sm="6"> 
                                        <p className="font-weight-medium font-size-12 mb-0 pt-1 about_heading_text">Marital Status</p>
                                        <p className="font-weight-light font-size-16 mt-2"> {props.userData.marital_status ? props.userData.marital_status:'--' } </p>
                                    </Col>
                                    <Col sm="6"> 
                                        <p className="font-weight-medium font-size-12 mb-0 pt-1 about_heading_text">Children</p>
                                        <p className="font-weight-light font-size-16 mt-2"> {props.userData.children_count ? props.userData.children_count:'--' } </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Row>
                                    <Col sm="6"> 
                                        <p className="font-weight-medium font-size-12 mb-0 pt-1 about_heading_text">City/District</p>
                                        <p className="font-weight-light font-size-16 mt-2">{props.userData.city ? props.userData.city:'--' }</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Row>
                                    <Col sm="12"> 
                                        <p className="font-weight-medium font-size-12 mb-0 pt-1 about_heading_text"> <span> Bio </span> 
                                            <i className="pl-1 bx bx-pencil font-size-12 cursor-pointer" onClick={()=>openEditBioData()}> </i>
                                        </p> 
                                        <p className="font-weight-light font-size-16 mt-2"> 
                                            {props.userData.bio}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TabPane>
            <EditBioModal
                    modal_center={edit_bio_modal}
                    centered={true}
                    tog_center={()=>openEditBioData()}
                    onModalSubmit={(value)=>updateBioData(value)}
                    editedData={{val:props.userData.bio}}
                />
        </TabContent>
    )
}

export default About;