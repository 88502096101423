import React, { Component } from "react";
import { Container, Alert, Modal, Row, Col, FormGroup, Label, Input, InputGroup, Card, CardBody } from "reactstrap";
import Jobscard from "./Jobscard";
import "react-phone-number-input/style.css";
import apply from "../../assets/images/apply.png";
import Skillscard from "./Skillscard";
import { connect } from "react-redux";
import { jobApply, getJobDetail } from "../../redux/actions/job";
import { fetchJobs } from "../../redux/actions/dashboard";
import timeSince from "../../helpers/timeSinceFormatter";
import blankImg from "../../assets/images/user.png";
import default_company_logo from "../../assets/images/d-company-logo.png";
import classnames from "classnames";
import PhoneInput, { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import company_img from "../../assets/images/company.svg";
import location from "../../assets/images/location.svg";
import time_icon from "../../assets/images/time.svg";
import { bindActionCreators } from "redux";
import ApplyJobModal from "./ApplyJobModal";
import { Link } from "react-router-dom";

class Jobs extends Component {
    state = {
        modal_apply: false,
        country: "",
        email: "",
        emailError: "Enter Email",
        phoneError: null,
        isValidated: false,
        phone: "",
        selectedId: this.props.match.params.id,
        formData: null,
    };

    componentDidMount() {
        this.props.getJobDetail(this.props.match.params.id);
        if (this.props.jobs === null || this.props.jobs.length === 0) {
            this.props.fetchJobs(0);
        }
    }
    getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.match.params.id !== prevState.selectedId) {
            this.props.getJobDetail(this.props.match.params.id);
        }
        return null;
    };

    tog_apply() {
        this.setState({ modal_apply: !this.state.modal_apply });
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    updateData(data) {
        this.tog_apply();
        this.props.getJobDetail(this.state.selectedId);
    }

    jobClickCallback(id) {
        this.setState(
            {
                selectedId: id,
            },
            () => {
                this.props.getJobDetail(id);
            }
        );
    }

    render() {
        return (
            <div className="pt-4 jobs-cont">
                <Row className="green-text-color">
                    <Col sm={3} lg={3} md={3} className="pr-0 mobile_right_padding">
                        <Jobscard
                            selectedId={this.state.selectedId}
                            jobClickCallback={(id) => this.jobClickCallback(id)}
                            jobs={this.props.jobs}
                            fetchJobs={this.props.fetchJobs}
                        />
                    </Col>
                    <Col sm={9} lg={9} md={9}>
                        <div className="ml-3 mr-4 pr-0">
                            <div className="top-container">
                                <div className="ux-designer"> {this.props.title}</div>
                                <div>
                                    <img
                                        className="ml-4 shift-image rounded-circle company-logos"
                                        src={this.props.company_log || default_company_logo}
                                        alt=""
                                    />
                                </div>
                                <div className="web_display justify-content-between company-info">
                                    <div className="d-flex ml-5 pl-4 green-text-color mobile_job_detail_header" style={{width:"60%"}}>
                                        <div className="mr-3" style={{width:"70%"}}>
                                            <div className="mob_display d-flex align-items-start">
                                                <img className="f-14 mb-2 pl-3 pr-2" src={company_img} alt="company" />{" "}
                                                <span style={{wordBreak:"break-all"}}>{this.props.company}</span>
                                            </div>
                                            <div className="">
                                                <img src={time_icon} className="pl-3 pr-2" alt="time_icon" />
                                                {/* <i className="far fa-clock mb-2 px-3"></i>{" "} */}
                                                {timeSince(new Date(this.props.createdAt))}
                                            </div>
                                        </div>
                                        <div className="loc">
                                            <img src={location} className="mr-1 pl-3 pr-2" alt="loc" />
                                            {this.props.is_remote_loc ? "Work from home" : this.props.location}
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-start mobile_jobdetail_status_text">
                                        <div className="mobile_apply_btn">
                                            {/* {this.props.applicationStatus && this.props.applicationStatus !== 'Not Applied' && <span className={`green-status-text ${ this.props.applicationStatus === 'reject' ? 'reject' :''}`}> {this.props.applicationStatus} </span>} */}
                                            <img src={apply}></img>
                                            <button
                                                className="btn bg-green px-5 f-16 mx-2"
                                                disabled={
                                                    this.props.applicationStatus === "applied" ||
                                                        this.props.applicationStatus === "hold" ||
                                                        this.props.applicationStatus === "select" ||
                                                        this.props.applicationStatus === "reject" ||
                                                        this.props.status === "close"
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => this.tog_apply()}
                                            >
                                                {" "}
                                                {this.props.applicationStatus &&
                                                    this.props.applicationStatus !== "Not Applied"
                                                    ? this.props.applicationStatus === "applied"
                                                        ? "Applied"
                                                        : this.props.applicationStatus === "hold"
                                                            ? "Hold"
                                                            : this.props.applicationStatus === "select"
                                                                ? "Selected"
                                                                : this.props.applicationStatus === "reject"
                                                                    ? "Rejected"
                                                                    : "Closed"
                                                    : "Apply"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row className="mt-4">
                                <Col lg="9">
                                    <div className="font-size-18">Roles & Responsibilites</div>
                                    <div className="mt-3 font-size-14 word-wrap">
                                        {this.props.roles ? this.props.roles : "No description provided."}
                                    </div>

                                    <div className="border-bottom-dark mt-2 mb-3"> </div>

                                    <div className="mt-3 font-size-18">Additional details related to the job</div>
                                    <div className="mt-3 font-size-14 word-wrap">
                                        {this.props.job_details ? this.props.job_details : "No description provided."}
                                    </div>

                                    <div className="border-bottom-dark mt-2 mb-3"> </div>

                                    <div className="mt-3 font-size-18">Other details</div>
                                    <div className="mt-3 font-size-14 word-wrap">
                                        {this.props.perks ? this.props.perks : "No description provided."}
                                    </div>
                                    {this.props.doc_url && (
                                        <div className="mt-3">
                                            <a
                                                className="btn bg-green button-wid-150 f-16"
                                                href={this.props.doc_url}
                                                target="_blank"
                                                download
                                            >
                                                Download document
                                            </a>
                                        </div>
                                    )}
                                </Col>
                                <Col lg="3" className="mobile_margin_5">
                                    <Card className="job-detail-card">
                                        <CardBody>
                                            <p className="green-text-color pb-2 font-size-16 font-weight-medium">
                                                Job Details
                                            </p>
                                            <Row className="green-text-color">
                                                <Col sm={12}>
                                                    <div className="mb-1">
                                                        <i className="fas fa-graduation-cap fa-lg" />
                                                        <span className="font-size-12 pl-2 font-weight-light">
                                                            {this.props.education_level
                                                                ? this.props.education_level
                                                                : "--"}
                                                        </span>
                                                    </div>
                                                    {/* <div className='mb-1'>
                                        <i className='mdi mdi-briefcase-outline font-size-20' />
                                        <span className="font-size-12 font-weight-light pl-2">
                                            4-8 years
                                        </span>
                                    </div> */}
                                                    {/* <div className='mb-1'>
                                        <i className='mdi mdi-gender-male-female font-size-20' />
                                        <span className="font-size-12 font-weight-light pl-2">
                                            {this.props.diversity}
                                        </span>
                                    </div> */}
                                                    {(this.props.min_exp || this.props.max_exp) && (
                                                        <div className="mb-1">
                                                            <i className="mdi mdi-briefcase-outline font-size-20" />
                                                            <span className="font-size-12 font-weight-light pl-2">
                                                                {this.props.min_exp}-{this.props.max_exp} years
                                                            </span>
                                                        </div>
                                                    )}
                                                </Col>
                                            </Row>
                                            {this.props && (this.props.skills_must || this.props.skills_good) && (
                                                <p className="mt-2 mb-2 green-text-color border-bottom pb-2 font-size-16 font-weight-medium">
                                                    Skills{" "}
                                                    {this.props.skills_good && this.props.skills_must && (
                                                        <span>
                                                            (
                                                            {this.props.skills_good.length +
                                                                this.props.skills_must.length}
                                                            )
                                                        </span>
                                                    )}
                                                </p>
                                            )}
                                            <Row className="green-text-color">
                                                {this.props.skills_must && this.props.skills_must.length > 0 && (
                                                    <Col sm={12} className="mt-2">
                                                        <p className="mb-2 font-weight-medium font-size-14">
                                                            Must have
                                                        </p>
                                                        <ul className="skills-learns pl-0 mb-0">
                                                            {this.props.skills_must.map((item, index) => {
                                                                return (
                                                                    <li
                                                                        className={classnames(
                                                                            "d-inline-block mr-2 mt-1 ml-0 skills-items skill-container"
                                                                        )}
                                                                        key={index}
                                                                    >
                                                                        <span className="font-size-12"> {item} </span>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Col>
                                                )}
                                                {this.props.skills_good && this.props.skills_good.length > 0 && (
                                                    <Col sm={12} className="mt-4">
                                                        <p className="mb-2 font-weight-medium font-size-14">
                                                            Good to have
                                                        </p>
                                                        <ul className="skill-tags-container pl-0">
                                                            {this.props.skills_good.map((item, index) => {
                                                                return (
                                                                    <li
                                                                        className={classnames(
                                                                            "d-inline-block mr-2 mt-1 skills-items skill-container"
                                                                        )}
                                                                        key={index}
                                                                    >
                                                                        <span className="font-size-12"> {item} </span>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Col>
                                                )}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <ApplyJobModal
                    modal_center={this.state.modal_apply}
                    centered={true}
                    tog_center={() => this.tog_apply()}
                    onModalSubmit={(value) => this.updateData(value)}
                    {...this.props}
                    selectedId={this.state.selectedId}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { ...state.job, jobs: state.dashboard.jobs, user: state.user.user };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getJobDetail: getJobDetail,
            fetchJobs: fetchJobs,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
