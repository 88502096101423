import React, { useState } from "react";
import { TabContent, TabPane, Row, Col } from "reactstrap";
import ResumeModal from "./ResumeModal";
import CandidatureModal from "./CandidatureModal";
import cloudOutline from "../../assets/images/cloud-outline.svg";
import ApiService from "../../config/restapi";
import api from "../../config/axios";
import pdf_icon from "../../assets/images/pdf_icon.png";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Dropzone from "react-dropzone";
import s3api from "../../helpers/s3apis";

const Cadidature = (props) => {
  const [resume_modal, setresume_modal] = useState(false);
  const [candidature_modal, setcandidature_modal] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const openResumeModal = () => {
    setresume_modal(!resume_modal);
    removeBodyCss();
  };

  const openCandidatureModal = () => {
    setcandidature_modal(!candidature_modal);
    removeBodyCss();
  };
  const handleAcceptedFiles = (file) => {
    try {
      if (!file) {
        throw new Error("Select a file first!");
      }
      console.log(file);
      // file.forEach((item) => {
      //   var ext = item.name.split(".").pop();
      //   console.log(item);
      //   if (item.size < 5242880) {
      //     s3api.upload("document", ext, item);
      //   } else {
      //     toastr.error("Select a file of size less than 5 MB", "", {
      //       timeOut: 2000,
      //     });
      //   }
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const updateResume = (file) => {
    // api
    //   .post("api/jobs/upload/resume", file, {
    //     headers: {
    //       token: `${localStorage.getItem("token")}`,
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   })
    //   .then((result) => {
    //     ApiService.saveOnboardingPersonalDetails({
    //       resume_url: result.data.data.Location,
    //     }).then((res) => {
    //       if (res && res.status === 200 && res.data) {
    //         props.getAllUserData();
    //         setresume_modal(false);
    //       }
    //     });
    //   });

    props.getAllUserData();
    setresume_modal(false);
    console.log("HI");
  };
  const deleteResume = () => {
    ApiService.saveOnboardingPersonalDetails({ resume_url: null }).then(
      (res) => {
        if (res && res.status === 200 && res.data) {
          props.getAllUserData();
          setresume_modal(false);
        }
      }
    );
  };
  const updateCandidature = (data) => {
    ApiService.saveOnboardingPersonalDetails({ video_url: data }).then(
      (res) => {
        if (res && res.status === 200 && res.data) {
          props.getAllUserData();
          setcandidature_modal(false);
        }
      }
    );
  };
  const deleteDoc = (data) => {
    const new_doc = props.userData.other_documents.filter((e) => e !== data);
    ApiService.editProfileData({ other_documents: new_doc }).then((res) => {
      if (res && res.status === 200 && res.data) {
        props.getAllUserData();
        setcandidature_modal(false);
      }
    });
  };

  return (
    <TabContent activeTab={props.activeTab}>
      <TabPane tabId="4" className="candidature-tab">
        <Row>
          <Col sm="12">
            <div>
              <h6 className="mb-1">Candidature</h6>
            </div>
            <div className="font-weight-light text-muted">
              Showcase your resume and other documents that talk about your
              candidature and why you are a best fit
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="font-weight-normal pt-3 mb-1">Resume</p>
            {!props.userData.resume_url && (
              <div className="">
                <button
                  type="button"
                  className="btn btn-outline-shadow waves-effect waves-light font-size-16"
                  onClick={() => openResumeModal()}
                >
                  {" "}
                  <img src={cloudOutline} alt="cloud" />
                </button>
              </div>
            )}

            {props.userData.resume_url && (
              <div className="apply_resume_wrapper">
                <div className="company-logo-img">
                  <a href={props.userData.resume_url} target="_blank">
                    <img
                      src={pdf_icon}
                      alt=""
                      className="courses-cert-img"
                      style={{ width: "auto" }}
                    />
                  </a>
                  <span className="img-close" onClick={deleteResume}>
                    &times;
                  </span>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="12" sm="12">
            <p className="font-weight-normal pt-3 mb-1">Candidature Video</p>
            <p className="font-weight-light text-muted mb-1">
              Upload a video talking about your how you can be a best fit for a
              company
            </p>
            <p className="font-weight-light text-muted mb-1">
              {props.userData.video_url}
            </p>
            <div className="">
              <button
                type="button"
                className="btn btn-outline-shadow waves-effect waves-light font-size-16"
                onClick={() => openCandidatureModal()}
              >
                {" "}
                <p style={{ fontSize: "12px", margin: "0px" }}>
                  Add Link <img src={cloudOutline} alt="cloud" />
                </p>
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" sm="12">
            <p className="font-weight-normal pt-3 mb-1">Other Documents</p>
            <Row>
              {props.userData.other_documents &&
                props.userData.other_documents.map((e) => {
                  return (
                    <Col lg="2" sm="2">
                      <div className="apply_resume_wrapper">
                        <div className="company-logo-img">
                          <a href={e} target="_blank">
                            <img
                              src={pdf_icon}
                              alt=""
                              className="courses-cert-img"
                              style={{ width: "auto" }}
                            />
                          </a>

                          <span
                            className="img-close"
                            onClick={() => deleteDoc(e)}
                          >
                            &times;
                          </span>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              {props.userData.other_documents &&
                props.userData.other_documents.length < 5 && (
                  <Col lg="2" sm="2">
                    <div className="candidature">
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles[0].size < 5242880) {
                          } else {
                            return toastr.error("Select a file of size less than 5 MB", "", {
                              timeOut: 2000,
                            });
                          }
                          var ext = acceptedFiles && acceptedFiles[0].name.split(".").pop();

                          if (
                            ext === "pdf" ||
                            ext === "docx" ||
                            ext === "doc") {

                          }
                          else {
                            return toastr.error("File must be pdf, docx or doc.", "", {
                              timeOut: 2000,
                            });
                          }
                          const formData = new FormData();
                          formData.append("file", acceptedFiles[0]);
                          api
                            .post("api/jobs/upload/resume", formData, {
                              headers: {
                                token: `${localStorage.getItem("token")}`,
                                "Access-Control-Allow-Origin": "*",
                              },
                            })
                            .then((result) => {
                              ApiService.editProfileData({
                                other_documents: [
                                  ...props.userData.other_documents,
                                  result.data.data.Location,
                                ],
                              }).then((res) => {
                                if (res && res.status === 200 && res.data) {
                                  props.getAllUserData();
                                  setcandidature_modal(false);
                                }
                              }).catch((e) => {
                                toastr.error("File upload failed. Internal Server Error", "", {
                                  timeOut: 2000,
                                });
                              });
                            });
                          // handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <button
                                  type="button"
                                  className="btn btn-outline-shadow waves-effect waves-light font-size-16"
                                >
                                  {" "}
                                  <img src={cloudOutline} alt="cloud" />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </Col>
                )}
            </Row>
          </Col>
        </Row>
      </TabPane>
      <ResumeModal
        modal_center={resume_modal}
        centered={true}
        tog_center={() => openResumeModal()}
        onModalSubmit={(file) => updateResume(file)}
      />
      <CandidatureModal
        modal_center={candidature_modal}
        centered={true}
        link={props.userData.video_url}
        tog_center={() => openCandidatureModal()}
        onModalSubmit={updateCandidature}
      />
    </TabContent>
  );
};

export default Cadidature;
