import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import html2canvas from "html2canvas";
import Jspdf from "jspdf";
import classnames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//theme images
import Elegant_theme from "../../assets/images/Elegant_theme.png";
import Professional_theme from "../../assets/images/Professional_theme.png";
import Modern_theme from "../../assets/images/Modern_theme.png";
import Techy_theme from "../../assets/images/Techy_theme.png";
import Formal_theme from "../../assets/images/Formal_theme.png";

//themes import

import Elegant from "../../components/themes/Elegant";
import Professional from "../../components/themes/Theme2";

import { getAllUserData } from "../../redux/actions/user";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import {
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import DateRangePicker from "../../components/DateRangePicker";
import Common from "../../helpers/common";

const AboutSection = (props) => {

  const [country, setCountry] = useState();

  const handleTextarea = (event) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(event.target.value);
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;
    if (WordCount <= 500) props.setCharComplete(WordCount);
  };


  console.log(props.user.date_of_birth);
  console.log(new Date(props.user.date_of_birth || new Date()));
  const [errors, setErrors] = useState({
    name: { valid: false, message: "" },
    mobile: { valid: false, message: "" },
    city: { valid: false, message: "" },
    country: { valid: false, message: "" },
    nchild: { valid: false, message: "" },
    dob: { valid: false, message: "" },
    marital: { valid: false, message: "" },
    children: { valid: false, message: "" },
    bio: { valid: false, message: "" },
  });

  function checkname(name) {
    if (name === "" || !name) {
      return { message: "Name should not be blank", valid: false };
    } else if (/^[a-zA-Z. ]+$/.test(name)) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid name", valid: false };
    }
  }
  function checkBio(name) {
    console.log("HEREEE");
    if (name === "" || !name) {
      return { message: "Bio should not be blank", valid: false };
    } else if (isNaN(name?.trim())) {
      return { message: "", valid: true };
    } else {
      return { message: "Please enter a valid bio", valid: false };
    }
  }

  function checkmobileNumber(number) {
    console.log(number);
    if (number === "") {
      return {
        message: "Phone number should not be blank",
        valid: false,
      };
    } else if (number >= 1000000000 && number <= 10000000000) {
      return {
        message: "",
        valid: true,
      };
    } else {
      return {
        message: "Please enter a valid phone number",
        valid: false,
      };
    }
  }

  function checkCountry(value) {
    if (value === "" || !value) {
      return { message: "Select a country", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkCity(value) {
    if (value === "" || !value) {
      return { message: "Select a city", valid: false };
    } else {
      return { message: "", valid: true };
    }
  }

  function checkChildren(children, nchild) {
    if (children) {
      if (!nchild || nchild === 0) {
        return { message: "Choose the no of children", valid: false };
      } else {
        return { message: "", valid: true };
      }
    } else {
      return { message: "", valid: true };
    }
  }

  function checkDob(age) {
    if (age) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your date of birth", valid: false };
    }
  }

  function checkMarital(marital) {
    if (marital) {
      return { message: "", valid: true };
    } else {
      return { message: "Enter your marital status", valid: false };
    }
  }

  function checkHasChildren(children) {
    if (children === true || children === false) {
      return { message: "", valid: true };
    } else {
      return { message: "Choose if you have/dont have children", valid: false };
    }
  }

  function checkValidity() {
    for (let i in errors) {
      if (errors[i]["valid"]) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  }

  function handleChildren(value) {
    if (value !== "Select") {
      props.OnchangeCallBack(value, "noofChildren");
    } else {
      props.OnchangeCallBack(0, "noofChildren");
    }
  }

  useEffect(() => {
    setErrors({
      name: checkname(props?.user?.name),
      mobile: checkmobileNumber(props?.user?.phone),
      country: checkCountry(props.user.country),
      city: checkCity(props.user.city),
      nchild: checkChildren(props.user.has_children, props.user.children_count),
      dob: checkDob(props.user.age),
      marital: checkMarital(props.marital_status),
      children: checkHasChildren(props.user.has_children),
      bio: checkBio(props.user.bio),
    });
  }, [props.user]);

  return (
    <TabContent>
      <TabPane className="about-page" style={{ padding: "25px" }}>
        <h6>About</h6>
        {/* <span>
                                <i className="bx bx-pencil font-size-18"></i>
                            </span> */}
        <Form>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-firstname-input1">
                  Name<span className="label-required"> * </span>{" "}
                </Label>
                <Input
                  type="text"
                  value={props.user.name || ""}
                  className="form-control"
                  onChange={(event) => {
                    event.target.value = Common.trimStart(
                      event.target.value.replace(/[^a-zA-Z\s]/gi, "")
                    );
                    props.OnchangeCallBack(event.target.value, "name");
                  }}
                />
                {props.user.name !== undefined && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {" "}
                    {errors.name.message}
                  </label>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="mb-4 text-left">
                <Label>Date of birth</Label>
                <InputGroup>
                  <DateRangePicker
                    className="form-control"
                    // selected = {user.p}
                    selected={new Date(props.user.date_of_birth || new Date())}
                    onChange={(val) => props.OnchangeCallBack(val, "dob")}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                  />
                  <label htmlFor="dobpicker">
                    <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                  </label>
                </InputGroup>
                {props.user.date_of_birth !== undefined && (
                  <label style={{ "margin-bottom": "0", color: "red" }}>
                    {errors.dob.message}
                  </label>
                )}
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="cust-age-width text-left">
                <Label for="basicpill-lastname-input2">Age</Label>
                <Input type="text" value={props.user.age + " Years " || ""} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Email Address<span className="label-required"> * </span>
                </Label>
                <Input type="email" disabled value={props.user.email || ""} />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">
                  Phone Number <span className="label-required"> * </span>{" "}
                </Label>
                <Row>
                  <Col lg="3" xs="3" className="pr-0">
                    <select
                      className="form-control phone-number-select"
                      value={props.user.isd_code}
                      onChange={(event) => {
                        props.OnchangeCallBack(event.target.value, "isd_code");
                      }}
                    >
                      {getCountries().map((country) => (
                        <option key={country} value={country}>
                          {country} {getCountryCallingCode(country)}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col lg="9" xs="9">
                    {/* <PhoneInput
                                            country={country ? country : 'IN'}
                                            international
                                            value={props.mobileNumber || ''}
                                            onChange={(value)=> props.OnchangeCallBack(value, 'mobile')} 
                                            className="form-control phone-number-input"
                                        /> */}
                    <Input
                      type="tel"
                      maxLength={10}
                      value={props.user.phone || ""}
                      onChange={(e) =>
                        props.OnchangeCallBack(e.target.value, "phone")
                      }
                    />
                  </Col>
                  {props.user.phone !== undefined && (
                    <label
                      style={{
                        "margin-bottom": "0",
                        color: "red",
                        padding: "0px 12px",
                      }}
                    >
                      {errors.mobile.message}
                    </label>
                  )}
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">Marital Status</Label>
                <FormGroup check style={{ display: "flex" }}>
                  <Label className="cust-radio-label" check>
                    <Input
                      type="radio"
                      checked={
                        props.user.marital_status === "Single" ? true : false
                      }
                      name="radio1"
                      value="Single"
                      onChange={(value) =>
                        props.OnchangeCallBack("Single", "marital_status")
                      }
                    />{" "}
                    Single
                  </Label>
                  <Label className="cust-radio-label" check>
                    <Input
                      type="radio"
                      checked={
                        props.user.marital_status === "Married" ? true : false
                      }
                      name="radio1"
                      value="Married"
                      onChange={(value) =>
                        props.OnchangeCallBack("Married", "marital_status")
                      }
                    />{" "}
                    Married
                  </Label>
                  <Label className="cust-radio-label" check>
                    <Input
                      type="radio"
                      checked={
                        props.user.marital_status === "prefer not to share" ||
                        props.user.marital_status === null
                          ? true
                          : false
                      }
                      name="radio1"
                      value="prefer not to share"
                      onChange={(value) =>
                        props.OnchangeCallBack(
                          "prefer not to share",
                          "marital_status"
                        )
                      }
                    />{" "}
                    I prefer not to share
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="6">
                  <FormGroup className="text-left">
                    <Label for="basicpill-lastname-input2">Children</Label>
                    <FormGroup check>
                      <Label className="cust-radio-label" check>
                        <Input
                          type="radio"
                          checked={props.user.has_children ? true : false}
                          name="radio2"
                          value={true}
                          onChange={(value) =>
                            props.OnchangeCallBack(
                              value.target.value,
                              "has_children"
                            )
                          }
                        />{" "}
                        Yes
                      </Label>
                      <Label check>
                        <Input
                          type="radio"
                          checked={!props.user.has_children ? true : false}
                          name="radio2"
                          value={false}
                          onChange={(value) =>
                            props.OnchangeCallBack(
                              value.target.value,
                              "has_children"
                            )
                          }
                        />{" "}
                        No
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="text-left">
                    <Label for="exampleSelect">No. of children</Label>
                    <Input
                      type="select"
                      disabled={!props.user.has_children ? true : false}
                      value={props.user.children_count || ""}
                      onChange={(event) =>
                        props.OnchangeCallBack(
                          event.target.value,
                          "children_count"
                        )
                      }
                      type="select"
                      name="select"
                    >
                      <option>Select</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label>Country</Label>
                <Input
                  type="select"
                  name="select"
                  value={props.user.country || ""}
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "country")
                  }
                >
                  <option>Select</option>
                  <option>India</option>
                  <option>United Sates</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="text-left">
                <Label for="basicpill-lastname-input2">City/District</Label>
                <Input
                  type="select"
                  value={props.user.city || ""}
                  onChange={(event) =>
                    props.OnchangeCallBack(event.target.value, "city")
                  }
                  name="select"
                  id="exampleCity"
                >
                  <option>Select</option>
                  <option>Mumbai</option>
                  <option>Delhi</option>
                  <option>Bangalore</option>
                  <option>Hyderabad</option>
                  <option>Ahmedabad</option>
                  <option>Chennai</option>
                  <option>Kolkata</option>
                  <option>Surat</option>
                  <option>Pune</option>
                  <option>Jaipur</option>
                  <option>Lucknow</option>
                  <option>Kanpur</option>
                  <option>Nagpur</option>
                  <option>Indore</option>
                  <option>Thane</option>
                  <option>Bhopal</option>
                  <option>Visakhapatnam</option>
                  <option>Pimpri-Chinchwad</option>
                  <option>Patna</option>
                  <option>Vadodara</option>
                  <option>Ghaziabad</option>
                  <option>Ludhiana</option>
                  <option>Agra</option>
                  <option>Nashik</option>
                  <option>Faridabad</option>
                  <option>Meerut</option>
                  <option>Rajkot</option>
                  <option>Kalyan-Dombivali</option>
                  <option>Vasai-Vira</option>
                  <option>Varanasi</option>
                  <option>Srinagar</option>
                  <option>Aurangabad</option>
                  <option>Dhanbad</option>
                  <option>Amritsar</option>
                  <option>Navi Mumbai</option>
                  <option>Allahabad</option>
                  <option>Ranchi</option>
                  <option>Howrah</option>
                  <option>Coimbatore</option>
                  <option>Jabalpur</option>
                  <option>Gwalior</option>
                  <option>Vijayawada</option>
                  <option>Jodhpur</option>
                  <option>Madurai</option>
                  <option>Raipur</option>
                  <option>Kota</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup className="mb-0 text-left">
                <Label for="basicpill-lastname-input2">
                  Tell us about yourself
                </Label>
                <Input
                  placeholder="Let others know what type of person you are"
                  type="textarea"
                  value={props.user.bio || ""}
                  charlimitLength="500"
                  onChange={(event) => {
                    handleTextarea(event);
                    props.OnchangeCallBack(event.target.value, "bio");
                  }}
                  name="text"
                />
                <div className="text-right">
                  <span className="font-size-12 font-weight-light">
                    {" "}
                    {props.charComplete}/500{" "}
                  </span>
                </div>
              </FormGroup>
              {(props.user.bio !== undefined || errors?.bio.valid) && (
                <label style={{ "margin-bottom": "0", color: "red" }}>
                  {errors.bio.message}
                </label>
              )}
            </Col>
          </Row>
        </Form>
        <div className="text-right">
          <button
            className={classnames(
              "btn font-light-green btn-outline-light-green waves-effect waves-light w-lg mr-2"
            )}
            onClick={props.resetProfileDetails}
            disabled={!props.allowReset}
          >
            Reset
          </button>
          <button
            className="btn bg-light-green waves-effect waves-light w-lg"
            onClick={props.updateProfileDetails}
            disabled={!props.allowReset}
          >
            Save
          </button>
        </div>
      </TabPane>
    </TabContent>
  );
};
export default AboutSection;
