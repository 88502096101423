import React, { Component } from "react";
import AssessmentCard from "./AssessmentCard";

export default class Assessment extends Component {
  render() {
    return (
      <div>
        <div className="assessment-container mt-5 pt-4">
          <div className="mb-2 mt-3 f-18 b-g">ASSESSMENT TEST</div>
          <div className="f-16 b-g">General Aptitude</div>
          <div className="row">
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
          </div>
          <div className="f-16 b-g">Technical Test</div>
          <div className="row">
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
          </div>
          <div className="f-16 b-g">Communication Skill Test</div>
          <div className="row">
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
          </div>
          <div className="f-16 b-g">Psychometric Test</div>
          <div className="row">
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
            <div className="col-xl-3 col-md-4 col-6 mb-2">
              <AssessmentCard />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
