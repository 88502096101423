import React, { useState, useEffect } from 'react';
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from 'classnames';
import MoreAboutYourself from "./MoreAboutYourself";
import WorkPlaceRelated from './WorkPlaceRelated';
import JobRelated from "./JobRelated";
import { withRouter } from 'react-router-dom';
import { Row, Col } from "reactstrap";
import ApiService from '../../../config/restapi';


const MoreQuestionsWizard = (props) => {

    const [personalQuestions, setPersonalQuestions] = useState();
    const [jobQuestions, setJobQuestions] = useState();
    const [workQuestions, setWorkQuestions] = useState();


    const wizardHeadersNames = [
        { name: "More about yourself", key: "pd-width" },
        { name: "Workplace Questions", key: "ed-width" },
        { name: "Job Related Questions", key: "empd-width" }
    ];

    const [wizardHeaders, setwizardHeaders] = useState([]);
    const [activeTab, setactiveTab] = useState(1);
    



    const onSkipClick = (tab) => {
        if (tab === 1) {
            setactiveTab(2);
        } else if (tab === 2) {
            setactiveTab(3);
        } else if (tab === 3) {
            props.history.push("/dashboard");
        }
    }

    const onNextClick = (tab) => {
        //console.log("skiNextp");
        if (tab === 1) {
            ApiService.savePersonalQuestionsOnBoarding(personalQuestions).then((res) => {
                if (res && res.status === 200 && res.data) {
                    setactiveTab(2);
                }
            });
            //setactiveTab(2);
        } else if (tab === 2) {
            ApiService.saveWorkQuestionsOnBoarding(workQuestions).then((res) => {
                if (res && res.status === 200 && res.data) {
                    setactiveTab(3);
                }
            });

            //setactiveTab(3);
        } else if (tab === 3) {
            ApiService.saveJobQuestionsOnBoarding(jobQuestions).then((res) => {
                if (res && res.status === 200 && res.data) {
                    props.history.push("/dashboard");
                }
            });
            // props.history.push("/dashboard");
        }
    }

 

    useEffect(() => {
        wizardHeadersNames.map((obj, index) => {
            if (activeTab === (index + 1)) {
                obj["active"] = "active";
                obj["key"] = wizardHeadersNames[index].key;
            } else {
                delete obj.active;
                delete obj.key;
            }
            if (activeTab === 2 || activeTab === 3) {
                if (activeTab === 3) wizardHeadersNames[activeTab - 3]["makeactive"] = (activeTab - 3);
                wizardHeadersNames[activeTab - 2]["makeactive"] = (activeTab - 2);
                wizardHeadersNames[activeTab - 2]["lessWidthD"] = "less-width-d";
            }
        })
        setwizardHeaders([...wizardHeadersNames]);
    }, [activeTab]);


    const ToggleHeaderTab = (tab,item)=>{
        if("makeactive" in item){
            setactiveTab(item.makeactive + 1);
        }
    }

    const WizardHeaders = (item1, idx) => {
        return (
            <NavItem key={idx}>
                <NavLink className={`text-center ${item1.active ? item1.active : ''}`}>
                    <span  onClick={()=>{ToggleHeaderTab(activeTab,item1)}}  className={classnames('step-number mr-1', { [`${item1.key}`]: item1.key },
                        { [`${item1.lessWidthD}`]: item1.lessWidthD },
                        { [`${item1.solidImage}`]: item1.solidImage })}>{idx + 1}</span>
                    {item1.name ? <div className={classnames('font-size-14 mt-2')} > {item1.name} </div> : ''}
                </NavLink>
            </NavItem>
        )
    }

    const personalQuestionsValCallBack = (pdata) => {
        setPersonalQuestions(pdata);
    }
    const jobQuestionsValCallBack = (jdata) => {
        setJobQuestions(jdata);
    }
    const workQuestionsValCallBack = (wdata) => {
        setWorkQuestions(wdata);
    }

 
    const checkForDisabled = ()=>{
        if(activeTab === 1 && personalQuestions){
            if(!personalQuestions.hobbies || !personalQuestions.passion ||
                 !personalQuestions.is_enterpreneur || !personalQuestions.open_ended){
                return true;
            }else{ return false}
        }
        if(activeTab === 3 && jobQuestions){
            if(!jobQuestions.last_like || !jobQuestions.best_stint ||
                !jobQuestions.pro_personal_life || !jobQuestions.open_ended){
               return true;
           }else{ return false}
        }
        if(activeTab === 2 && workQuestions){
            if(!workQuestions.motivation || !workQuestions.what_imp ||
                !workQuestions.term_length || !workQuestions.open_ended){
               return true;
           }else{ return false}
        }
        return false;
    }

    return (
        <div id="basic-pills-wizard" className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
                {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
            </ul>
            <Card>
                <CardBody>
                    {activeTab === 1 ? <MoreAboutYourself
                        activeTab={activeTab}
                        personalQuestions={personalQuestions}
                        personalQuestionsValCallBack={personalQuestionsValCallBack}
                    /> :
                        (activeTab === 2 ? <WorkPlaceRelated
                            activeTab={activeTab}
                            workQuestionsValCallBack={workQuestionsValCallBack}
                        /> :
                            <JobRelated
                                activeTab={activeTab}
                                jobQuestionsValCallBack={jobQuestionsValCallBack}
                            />)
                    }
                </CardBody>
            </Card>
            <div className='text-right'>
                {/* <button
                    className={classnames('btn btn-outline-green waves-effect waves-light w-lg mr-2')}
                    type="submit"
                    disabled={checkForDisabled()}
                    onClick={() => onSkipClick(activeTab)}
                >Skip
                </button> */}
                <button className="btn bg-green waves-effect waves-light w-lg"
                    disabled={checkForDisabled()}
                    type="submit"
                    onClick={() => onNextClick(activeTab)}
                >Next</button>
            </div>
        </div>
    )
}

export default withRouter(MoreQuestionsWizard);