import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import image from "../assets/images/user.png";
import { connect } from "react-redux";
import phone_icon from "../assets/images/phone_icon.png";
import email_icon from "../assets/images/email_icon.png";
import PhoneInput, { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import trusted_user_icon from "../assets/images/trusted_user_icon.png";
import verified_user_icon from "../assets/images/verified_user_icon.png";
import secure_user_icon from "../assets/images/secure_user_icon.png";
import cloudOutline from "../assets/images/cloud-outline.svg";

import ApiService from "../config/restapi";
import CandidatureModal from "../pages/Profile/CandidatureModal";
import company_img from "../assets/images/company.svg";
import location from "../assets/images/location.svg";
import { Form, FormGroup, Input, Modal } from "reactstrap";
import Dropzone from "react-dropzone";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AddBackgroundVerificationModal from "./AddBackgroundVerificationModal";
import s3api from "../helpers/s3apis";

//Redux Actions
import { updatephoto, fetchDashboardDetails } from "../redux/actions/dashboard";

import timeSince from "../helpers/timeSinceFormatter";
const SideNav = (props) => {

    const [closedJobs, setClosedJobs] = useState([]);
    const [openJobs, setOpenJobs] = useState([]);

    useEffect(()=>{
        let newArr = props.user.jobs.filter((job)=>{
            return job.status==="close"
        })
        setClosedJobs(newArr);

        let new2Arr = props.user.jobs.filter((job)=>{
            return job.status!=="close"
        })
        setOpenJobs(new2Arr);
    },[])

    const handlephoto = async (file, type) => {
        try {
            if (!file) {
                throw new Error("Select a file first!");
            }
            console.log(file.name.slice(-3));
            console.log(type);
            var ext = file.name.split(".").pop();
            if (file.size < 5242880) {
                await s3api.upload("profilePic", ext, file);
                props.fetchDashboardDetails();
            } else {
                toastr.error("Select a file of size less than 5 MB", "", {
                    timeOut: 2000,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleVideo = async (file, type) => {
        try {
            if (!file) {
                throw new Error("Select a file first!");
            } else {
                var ext = file && file.name.split(".").pop();
                if (
                    ext === "webm" ||
                    ext === "mp4" ||
                    ext === "ogv" ||
                    ext === "mpeg" ||
                    ext === "mpg" ||
                    ext === "mov" ||
                    ext === "m4v" ||
                    ext === "wmv" ||
                    ext === "ogm" ||
                    ext === "flc"
                ) {
                    const formData = new FormData();
                    formData.append("file", file);
                } else {
                    toastr.error("Please Upload Valid Format", "", { timeOut: 2000 });
                }
            }

            // ApiService.updateVideo(formData).then((res) => {
            //     if (res && res.status === 200 && res.data) {
            //         getDashBoadData();
            //     }
            // });
            // handle success
        } catch (error) {
            console.log(error);
        }
    };
    const [modal_large, setmodal_large] = useState(false);
    const [candidature_modal, setCandidature_modal] = useState(false);

    function tog_large() {
        setmodal_large(!modal_large);
        removeBodyCss();
    }

    function openCandidatureModal() {
        setCandidature_modal(!candidature_modal);
        removeBodyCss();
    }
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function updateBgModalData() {
        tog_large(false);
    }

    function updateCandidature(data) {
        ApiService.saveOnboardingPersonalDetails({ video_url: data }).then((res) => {
            if (res && res.status === 200 && res.data) {
                props.fetchDashboardDetails();
                openCandidatureModal(false);
            }
        });
    }

    return (
        <div className="sidenav-box mt-2">
            <div className="sidebar-menu">
                <div className="profile-section">
                    <div className="basic-details">
                        <div className="image-profile pt-4">
                            <img src={props.user.image_url || image} className="user-profile" alt="image-1"></img>
                        </div>
                        <br />
                        <div className="f-20 f-w-100 text-center pt-1">Welcome, {props.user.name}!</div>
                        <div className="f-13 f-w-100  mt-3 mx-auto" style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",width:"fit-content"}}>
                            <div>
                            <img className="mr-2" src={email_icon} alt="email" />
                            <span>{props.user.email}</span>
                            </div>
                            <div className="mb-3 pt-3">
                                <img className="mr-2" src={phone_icon} alt="phone" />
                                <span className="mr-1">
                                    {props.user.isd_code ? "+" + getCountryCallingCode(props.user.isd_code) : ""}{" "}
                                    {props.user.phone}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 mb-2">
                        <span className="f-18">Profile</span>

                        <span className="float-right" style={{ display: "flex" }}>
                            {/* <div className="round-circle" /> */}
                            <span style={{ width: "16px", height: "16px" }}>
                                <CircularProgressbar
                                    value={Math.min(100, Math.max(props.user.percentComplete, 0))}
                                    background={true}
                                    styles={{
                                        path: {
                                            stroke: "#213d30",
                                        },
                                        // Customize the circle behind the path, i.e. the "total progress"
                                        trail: {
                                            // Trail color
                                            stroke: "transparent",
                                        },
                                        // Customize the text

                                        // Customize background - only used when the `background` prop is true
                                        background: {
                                            fill: "#74b496",
                                        },
                                    }}
                                />
                            </span>
                            <span className="f-12 ml-2">
                                {+Math.min(100, Math.max(props.user.percentComplete, 0)).toFixed(2)}% complete
                            </span>
                        </span>
                    </div>
                    <div className=" d-flex justify-content-between">
                        <span className="small-blurred-text font-size-12">Help recruiters recognise you</span>
                        <span className=" f-g f-12 f-w-300" style={{ float: "right" }}>
                            {props.user.image_url ? (
                                <div>
                                    <input
                                        type="file"
                                        accept="image/gif, image/jpeg, image/png"
                                        onChange={(e) => handlephoto(e.target.files[0])}
                                        name="imageUpdate"
                                        id="imageUpdate"
                                        style={{ display: "none" }}
                                    />
                                    <label className="cursor-pointer f-w-300" htmlFor="imageUpdate">
                                        Change Photo
                                    </label>
                                </div>
                            ) : (
                                <div>
                                    <input
                                        type="file"
                                        accept="image/gif, image/jpeg, image/png"
                                        onChange={(e) => handlephoto(e.target.files[0])}
                                        name="imageUpload"
                                        id="imageUpload"
                                        style={{ display: "none" }}
                                    />
                                    <label className="cursor-pointer f-w-300" htmlFor="imageUpload">
                                        Add Photo
                                    </label>
                                </div>
                            )}
                        </span>
                    </div>
                    <div className="mb-2 d-flex justify-content-between">
                        <span className="small-blurred-text font-size-12">Upload a video resume</span>

                        <span className="pe-auto f-g f-12">
                            <div
                                type="button"
                                onClick={() => {
                                    openCandidatureModal();
                                }}
                                className="pe-auto f-g f-12 f-w-200 btn-sp f-w-300"
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg"
                            >
                                {props?.user?.video_url ? "Update" : "Add"} Video
                            </div>
                            <CandidatureModal
                                link={props?.user?.video_url}
                                modal_center={candidature_modal}
                                centered={true}
                                tog_center={() => openCandidatureModal()}
                                onModalSubmit={updateCandidature}
                            />
                        </span>
                    </div>
                    <div className="mb-2 d-flex justify-content-between">
                        <span className="small-blurred-text font-size-12 f-w-300">Become a verified user</span>
                        <span className="pe-auto f-g f-12">
                            <div
                                type="button"
                                onClick={() => {
                                    tog_large();
                                }}
                                className="f-g f-12 f-w-200 btn-sp f-w-300"
                                data-toggle="modal"
                                data-target=".bs-example-modal-lg"
                            >
                                Begin
                            </div>
                            <AddBackgroundVerificationModal
                                modal_center={modal_large}
                                centered={true}
                                tog_center={() => tog_large()}
                                onModalSubmit={updateBgModalData}
                            />
                        </span>
                    </div>
                </div>

                <br />
                {openJobs.length > 0 && (
                    <div className="last-section mb-2">
                        <div className="f-16 mb-3 pl-3 pr-3">ACTIVE APPLICATIONS ({openJobs.length})</div>
                        {openJobs.map((e, index) => (
                            <ActiveApplication key={index} job={e} />
                        ))}
                    </div>
                )}

<br />
                {closedJobs.length > 0 && (
                    <div className="last-section mb-2">
                        <div className="f-16 mb-3 pl-3 pr-3">CLOSED APPLICATIONS ({closedJobs.length})</div>
                        {closedJobs.map((e, index) => (
                            <ActiveApplication key={index} job={e} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
const ActiveApplication = (props) => {
    return (
        <Fragment>
            <Link to={`/jobs/${props.job.id}`} className="rem-link-def pt-2 active_app_link">
                <div className="d-flex justify-content-between pl-3 pr-3">
                    <div className="f-16 cut-text-ellipsis">{props.job.title}</div>
                </div>
                <div className="pl-3 pr-3">
                    <div className="f-14 mb-2 mt-3">
                        {/* <i className="far fa-building pr-1 f-g"></i> */}
                        <img className="mr-1" src={company_img} alt="company" /> {props.job.company}
                    </div>
                    <div className="cut-text-ellipsis">
                        {/* <i className="fas fa-map-marker-alt mb-2"></i> */}
                        <img src={location} className="pr-1 mr-1 " alt="loc" />
                        {props.job.is_remote_loc ? "Work from home" : props.job.location}
                    </div>
                    <div className="font-size-11 text-muted font-weight-light text-right pl-3 pr-3">
                        {timeSince(new Date(props.job.createdAt))} ago
                    </div>
                    <hr />
                </div>
            </Link>
        </Fragment>
    );
};


const mapstateToprops = (state) => {
    return state.dashboard;
};

const mapDispatchToProps = {
    updatephoto,
    fetchDashboardDetails,
};

export default connect(mapstateToprops, mapDispatchToProps)(SideNav);
