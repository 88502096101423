import api from '../../config/axios';
import toastr from "toastr";
import { CHANGE_PASSWORD , CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_LOADING } from '../accountsetting';

export const changePassword = (password, confirmPassword, currentPassword) => async dispatch => {
    dispatch({
        type: CHANGE_PASSWORD_LOADING,
    });
    try {
        const response = await api.put('/api/account_settings/change_password', { password, confirmPassword, currentPassword}, 
            {
                headers: {
                    'token': `${localStorage.getItem('token')}`
                }
            });

        if (response.status === 200) {
            console.log(response);
            if(response.data.success){
                toastr.success("Password updated successfully!", "", { timeOut: 2000 });

                dispatch({
                    type: CHANGE_PASSWORD,
                    payload: response.data,
                });
            }
            else{
                toastr.error(response.data.reason, "", { timeOut: 2000 });
                dispatch({
                    type: CHANGE_PASSWORD_ERROR,
                    payload: response.data
                }); 
            }
            
        }
        else {
            toastr.error(response.data, "", { timeOut: 2000 });

            dispatch({
                type: CHANGE_PASSWORD_ERROR,
                payload: response.data
            });        
        }
    }
    catch (err) {
        toastr.error(err.response.data.message, "", { timeOut: 2000 });

        dispatch({
            type: CHANGE_PASSWORD_ERROR,
            payload: err.response.data
        });
    }

}