import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Card, CardBody } from "reactstrap";
import classnames from "classnames";
import PersonalDetails from "./PersonalDetails";
import EducationalDetails from "./EducationalDetails";
import EmployementDetails from "./EmployementDetails";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ApiService from "../../../config/restapi";
import getAge from "../../../helpers/getAge";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import common from "../../../helpers/common";

const AboutYourselfWizard = (props) => {
  // Personal Details States

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [dobDate, setDobDate] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [Marital, setMarital] = useState();
  const [children, setChildren] = useState();
  const [nchild, setnchild] = useState(0);
  const [city, setcity] = useState();
  const [age, setAge] = useState();
  const [about, setabout] = useState();
  const [country, setCountry] = useState();
  const [profileData, setProfileData] = useState();
  const [countrycode, setCountryCode] = useState();
  const [educationSaveData, setEducationSaveData] = useState([]);
  const [validity, setValidity] = useState(false);
  const [employmentSaveData, setEmploymentSaveData] = useState([]);

  const wizardHeadersNames = [
    { name: "Personal Details", key: "pd-width" },
    { name: "Educational Details", key: "ed-width" },
    { name: "Employment Details", key: "empd-width" },
  ];

  const [wizardHeaders, setwizardHeaders] = useState([]);
  const [activeTab, setactiveTab] = useState(1);

  const OnchangeCallBack = (value, type) => {
    switch (type) {
      case "name":
        let newValueName = value.replace(/[^a-zA-Z\s]/gi, "");
        setName(common.trimStart(newValueName));
        break;
      case "email":
        value = value.replace(/[^\w\s\.\,@]/gi, "").trimStart();
        setEmail(value);
        break;
      case "mobile":
        let newValuePhone = value.replace(/[^\w]/gi, "");
        setMobileNumber(newValuePhone);
        break;
      case "Marital":
        setMarital(value);
        break;
      case "Children":
        setChildren(value === "Yes" ? true : false);
        if (value === "No") {
          setnchild(0);
        }
        break;
      case "noofChildren":
        setnchild(value);
        break;
      case "city":
        let newValueCity = value.replace(/[^a-zA-Z\s]/gi, "");
        setcity(common.trimStart(newValueCity));
        break;
      case "age":
        setAge(value);
        break;
      case "about":
        let newValue = value.replace(/[^\w\s\.\,]/gi, "");
        setabout(common.trimStart(newValue));
        break;
      case "dob":
        setDobDate(value);
        setAge(getAge(value));
        break;
      case "country":
        setCountry(value);
        break;
      case "dropDownCountry":
        setCountryCode(value);
        break;
      case "isd_code":
        setCountryCode(value);
        break;
      case "validity":
        setValidity(value);
        break;
      default:
        break;
    }
  };

  const onSkipClick = (tab) => {
    if (tab === 1) {
      setactiveTab(2);
    } else if (tab === 2) {
      setactiveTab(3);
    } else if (tab === 3) {
      props.onCompleteAboutYourselfCallBack();
    }
  };

  const onNextClick = (tab) => {
    if (tab === 1) {
      let Obj = {
        name,
        email,
        age,
        city,
        country,
        isd_code: countrycode,
        has_children: children,
        children_count: nchild ? Number(nchild) : 0,
        phone: mobileNumber,
        marital_status: Marital,
        bio: about,
        date_of_birth: dobDate,
      };

      if (!validity) {
        if (name === undefined) setName("");
        if (city === undefined) setcity("");
        if (country === undefined) setCountry("");
        if (mobileNumber === undefined) setMobileNumber("");
        toastr.error("Please enter all the required information.");
        return;
      }
      if (mobileNumber && mobileNumber.length === 10 && mobileNumber[0] >= 6) {
        if (age < 30) {
          toastr.error("Age must be greater than 30");
          return;
        }
        ApiService.saveOnboardingPersonalDetails(Obj).then((res) => {
          if (res && res.status === 200 && res.data) {
            setactiveTab(2);
          }
        });
      } else {
        toastr.error("Enter a valid 10 digit mobile number", "", {
          timeOut: 2000,
        });
      }
    } else if (tab === 2) {
      setactiveTab(3);
      // if(educationSaveData.length > 0){
      //     ApiService.saveEducationDetailsOnBoarding(educationSaveData).then((res) => {
      //         if(res && res.status === 200 && res.data){
      //             setactiveTab(3);
      //         }
      //     });
      // }else{
      //     setactiveTab(3);
      // }
    } else if (tab === 3) {
      if (employmentSaveData.length > 0) {
        ApiService.saveEmploymentDetailsOnBoarding(employmentSaveData).then(
          (res) => {
            if (res && res.status === 200 && res.data) {
              props.onCompleteAboutYourselfCallBack();
            }
          }
        );
      } else {
        props.onCompleteAboutYourselfCallBack();
      }
      //props.onCompleteAboutYourselfCallBack();
    }
  };

  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "" && obj[key] != false)
        return false;
    }
    return true;
  }

  const deleteFromBackendData = (updatedData) => {
    // if(index > -1){
    //     console.log('educationSaveData',index,educationSaveData);
    //     const tempERow =  [...educationSaveData];
    //     tempERow.splice(index, 1);
    setEducationSaveData([...updatedData]);
    //}
  };

  const onNextClickEduDataCallBack = (eduData, editIndex) => {
    if (!checkProperties(eduData[0])) {
      if (editIndex > -1) {
      } else {
        // const latestData =  [...eduData, ...rowData];
        setEducationSaveData([...eduData]);
      }
    }
  };

  const deleteEmpFromBackendData = (updatedData) => {
    setEmploymentSaveData([...updatedData]);
  };

  const onNextClickEmploymentCallBack = (empData, editIndex) => {
    if (!checkProperties(empData[0])) {
      if (editIndex > -1) {
      } else {
        // const latestData =  [...empData, ...rowData];
        setEmploymentSaveData([...empData]);
      }
    }
  };

  function getProfileData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.data) {
        if (res.data.name) setName(res.data.name);
        setEmail(res.data.email);
        if (res.data.phone) setMobileNumber(res.data.phone);
        setMarital(res.data.marital_status);
        setChildren(res.data.has_children);
        setnchild(res.data.children_count);
        if (res.data.city != "") setcity(res.data.city);
        if (res.data.country) setCountry(res.data.country);
        if (res.data.phone) setMobileNumber(res.data.phone);
        setAge(res.data.age);
        setabout(res.data.bio);
        setDobDate(new Date(res.data.date_of_birth));
        setCountryCode(res.data.isd_code);
        setProfileData({ ...res.data });
      }
    });
  }

  useEffect(() => {
    getProfileData();
    let temData = [...wizardHeadersNames];
    temData.map((obj, index) => {
      if (activeTab === index + 1) {
        obj["active"] = "active";
        obj["key"] = temData[index].key;
      } else {
        delete obj.active;
        delete obj.key;
      }
      if (activeTab === 2 || activeTab === 3) {
        if (activeTab === 3)
          temData[activeTab - 3]["makeactive"] = activeTab - 3;
        temData[activeTab - 2]["makeactive"] = activeTab - 2;
        temData[activeTab - 2]["lessWidthD"] = "less-width-d";
      }
    });
    setwizardHeaders([...temData]);
  }, [activeTab]);

  const ToggleHeaderTab = (tab, item) => {
    if ("makeactive" in item) {
      setactiveTab(item.makeactive + 1);
    }
  };

  const WizardHeaders = (item1, idx) => {
    return (
      <NavItem key={idx}>
        <NavLink className={`text-center ${item1.active ? item1.active : ""}`}>
          <span
            onClick={() => {
              ToggleHeaderTab(activeTab, item1);
            }}
            className={classnames(
              "step-number mr-1",
              { [`${item1.key}`]: item1.key },
              { [`${item1.lessWidthD}`]: item1.lessWidthD },
              { [`${item1.solidImage}`]: item1.solidImage }
            )}
          >
            {idx + 1}
          </span>
          {item1.name ? (
            <div className={classnames("font-size-14 mt-2")}>
              {" "}
              {item1.name}{" "}
            </div>
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div id="basic-pills-wizard" className="twitter-bs-wizard">
      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified mb-3">
        {wizardHeaders.map((item, idx) => WizardHeaders(item, idx))}
      </ul>
      <Card>
        <CardBody>
          {activeTab === 1 ? (
            <PersonalDetails
              activeTab={activeTab}
              OnchangeCallBack={(value, type) => OnchangeCallBack(value, type)}
              mobileNumber={mobileNumber}
              name={name}
              email={email}
              marital={Marital}
              age={age}
              about={about}
              city={city}
              children={children}
              nchild={nchild}
              dobDate={dobDate}
              country={country}
              countrycode={countrycode}
            />
          ) : activeTab === 2 ? (
            <EducationalDetails
              activeTab={activeTab}
              onNextClickEduDataCallBack={onNextClickEduDataCallBack}
              deleteFromBackendData={deleteFromBackendData}
              getSavedRows={[
                ...common.getSortedArray(profileData.educations, "id"),
              ]}
              updateEducationData={getProfileData}
            />
          ) : (
            <EmployementDetails
              activeTab={activeTab}
              onNextClickEmploymentCallBack={onNextClickEmploymentCallBack}
              deleteEmpFromBackendData={deleteEmpFromBackendData}
              getSavedRows={profileData.experiences}
              updateEmpData={getProfileData}
            />
          )}
        </CardBody>
      </Card>
      {!name || !email || !mobileNumber ? (
        <div className="text-right">
          <button
            className={classnames(
              "btn font-light-green btn-outline-light-green waves-effect waves-light w-lg mr-2"
            )}
          >
            Skip
          </button>
          <button className="btn bg-light-green waves-effect waves-light w-lg">
            {activeTab === 3 ? "Complete" : "Next"}
          </button>
        </div>
      ) : (
        <div className="text-right">
          <button
            className={classnames(
              "btn font-dark-green btn-outline-green waves-effect waves-light w-lg mr-2"
            )}
            type="submit"
            disabled={!name || !email || !mobileNumber ? true : false}
            onClick={() => onSkipClick(activeTab)}
          >
            Skip
          </button>
          <button
            className="btn bg-green waves-effect waves-light w-lg"
            type="submit"
            onClick={() => onNextClick(activeTab)}
          >
            {activeTab === 3 ? "Complete" : "Next"}
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(AboutYourselfWizard);
