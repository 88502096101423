import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import TextArea from "../../../components/TextArea";
import ApiService from "../../../config/restapi";
import Common from "../../../helpers/common";

const MoreAboutYourself = (props) => {
  const [hobbiescharcomplete, setHobbiescharcomplete] = useState(0);
  const [passionatecharcomplete, setPassionatecharcomplete] = useState(0);
  const [entrepreneurshipcharcomplete, setEntrepreneurshipcharcomplete] =
    useState(0);
  const [viewcharcomplete, setViewcharcomplete] = useState(0);

  const [personalQuestions, setPersonalQuestions] = useState({
    hobbies: "",
    passion: "",
    is_enterpreneur: "",
    open_ended: "",
  });

  function getProfileData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.data && res.data.personal_q) {
        setPersonalQuestions({
          ...personalQuestions,
          hobbies: res.data.personal_q.hobbies
            ? res.data.personal_q.hobbies
            : "",
          passion: res.data.personal_q.passion
            ? res.data.personal_q.passion
            : "",
          is_enterpreneur: res.data.personal_q.is_enterpreneur
            ? res.data.personal_q.is_enterpreneur
            : "",
          open_ended: res.data.personal_q.open_ended
            ? res.data.personal_q.open_ended
            : "",
        });
      }
    });
  }

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    props.personalQuestionsValCallBack(personalQuestions);
  }, [personalQuestions]);

  const onChangeTextareaHandler = (event, type) => {
    // const charCount = event.target.value.length;
    var regex = /\s+/gi;
    event.target.value = Common.trimStart(
      event.target.value.replace(/[^\w\s\.\,]/gi, "")
    );
    var WordCount =
      event.target.value === ""
        ? 0
        : event.target.value.trim().replace(regex, " ").split(" ").length;

    switch (type) {
      case "hobbies":
        if (WordCount <= 500) {
          setHobbiescharcomplete(WordCount);
          setPersonalQuestions({
            ...personalQuestions,
            hobbies: event.target.value,
          });
        }
        break;

      case "passionate":
        if (WordCount <= 500) {
          setPassionatecharcomplete(WordCount);
          setPersonalQuestions({
            ...personalQuestions,
            passion: event.target.value,
          });
        }
        break;

      case "entrepreneurship":
        if (WordCount <= 500) {
          setEntrepreneurshipcharcomplete(WordCount);
          setPersonalQuestions({
            ...personalQuestions,
            is_enterpreneur: event.target.value,
          });
        }
        break;

      case "view":
        if (WordCount <= 500) {
          setViewcharcomplete(WordCount);
          setPersonalQuestions({
            ...personalQuestions,
            open_ended: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content pt-0"
    >
      <TabPane tabId={1} className="more-about-yourself">
        <Form>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What are your hobbies?/How do you like to spend your leisure time?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "hobbies")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={hobbiescharcomplete}
                value={personalQuestions.hobbies}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What are you most passionate about?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "passionate")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={passionatecharcomplete}
                value={personalQuestions.passion}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="Does Entrepreneurship interest you?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "entrepreneurship")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={entrepreneurshipcharcomplete}
                value={personalQuestions.is_enterpreneur}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <TextArea
                labelName="What is your view on fairness and unfairness in life?"
                onChangeTextareaHandler={(event) =>
                  onChangeTextareaHandler(event, "view")
                }
                isCharLimitShow={true}
                charlimitLength="500"
                charComplete={viewcharcomplete}
                value={personalQuestions.open_ended}
              />
            </Col>
          </Row>
        </Form>
      </TabPane>
    </TabContent>
  );
};

export default MoreAboutYourself;
