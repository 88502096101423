import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from 'classnames';
import professional_icon from '../../assets/images/professional_icon.png';
import beginner_icon from '../../assets/images/begineer_icon.png';
import intermidiate_icon from '../../assets/images/intermidiate_icon.png';



// {   skillName : "Information Architechture",
// proficiency:"beginner"
// },
// {   skillName : "Design",
// proficiency:"professional"
// },{
// skillName : "Java",
// proficiency:"intermidiate"
// }

const ManageSkillsModal = (props) => {
    const [proficiencyLevelArray, setProficiencyLevelArray] = useState([{ proficiency: 'beginner' }, {
        proficiency: 'intermidiate'
    }, { proficiency: 'professional' }]);

    const [manageSkilData, setManageSkilData] = useState([]);

    const dotHandler = (index, proficiency) => {
        var tempData = [...manageSkilData];
        var tempData1 = manageSkilData.map((val, idx) => {
            if (idx === index) {
                var val1 = JSON.parse(val);
                val1["proficiency"] = proficiency;
                console.log(JSON.stringify(val1));
                return JSON.stringify(val1);

            } else {
                return JSON.stringify(JSON.parse(val));
            }

        });
        console.log(tempData1);
        setManageSkilData(tempData1);
    }

    const deleteRow = (pindex) => {
        const tempData = [...manageSkilData];
        if (pindex > -1) {
            tempData.splice(pindex, 1);
        }
        setManageSkilData(tempData);
    }

    useEffect(() => {
        if (props.editedData) {
            setManageSkilData([...props.editedData.skills]);
        }
    }, [props.editedData]);

    return (
        <Modal
            size="lg"
            isOpen={props.modal_center}
            centered={props.centered}
            className="add-skills-modal"
        >
            <div className="modal-header">
                <div className="modal-title mt-0 font-weight-bold" id="myModalLabel">
                    Manage Skills ({manageSkilData.length})
                    {/* Edit profeciencies or  */}
                    <div className='font-weight-light font-size-11 mt-2'>Delete skills to manage them</div>
                </div>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="add-skill-container pl-3 pr-3 pb-3 text-left">
                    <Col lg="5" className='p-0'>
                        Skill
                    </Col>
                    {/* <Col lg="4">
                        <Label className="mb-1"> Edit Profeciency Level </Label>
                    </Col> */}
                </Row>
                {manageSkilData && manageSkilData.length > 0 && manageSkilData.map((v, pindex) => {
                    let v1 = JSON.parse(v);
                    return (
                        <Row key={pindex} className="add-skill-container mt-2 pl-3 pr-3 border-bottom pb-3">
                            <Col lg="4" className='d-flex align-items-center text-left p-0'>
                                <FormGroup className="text-left mb-1">
                                    <div> {v1.label}</div>
                                </FormGroup>
                            </Col>
                            <Col lg="4" className='text-left'>
                                <div>
                                    {proficiencyLevelArray.map((data, index) => {
                                        return (
                                            <div className='proficiency-container float-left mr-2' key={index} onClick={() => dotHandler(pindex, data.proficiency)}>
                                                {data.proficiency === 'beginner' && <img src={beginner_icon} alt='skills' />}
                                                {data.proficiency === 'intermidiate' && <img src={intermidiate_icon} alt='skills' />}
                                                {data.proficiency === 'professional' && <img src={professional_icon} alt='skills' />}
                                                <div
                                                    className={classnames('font-size-11 font-weight-light text-transform', { 'font-weight-bold': data.proficiency === v1.proficiency })}>
                                                    {data.proficiency}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                            <Col lg="4" className='text-right'>
                                <button className="waves-effect waves-light btn btn-outline-shadow w-xs font-size-11 p-1" type="button" onClick={() => deleteRow(pindex)} >
                                    <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required"></i> <span className='label-required'> REMOVE SKILL</span>
                                </button>
                            </Col>
                        </Row>
                    )
                })}
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn bg-green waves-effect"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn bg-green waves-effect waves-light"
                    onClick={() => props.onModalSubmit({ skills: [...manageSkilData] })}
                >Save </button>

            </div>
        </Modal>
    );
}

export default ManageSkillsModal;