export const DASHBOARD_JOBS_LOADING = "DASHBOARD_JOBS_LOADING";
export const DASHBOARD_JOBS_SUCCESS = "DASHBOARD_JOBS_SUCCESS";
export const DASHBOARD_JOBS_ERROR = "DASHBOARD_JOBS_ERROR";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";
export const JOBS_ERROR = "JOBS_ERROR";
export const DASHBOARD_PROFILE_LOADING = "LOADING_PROFILE_DASHBOARD";
export const DASHBOARD_PROFILE_SUCCESS = "DASHBOARD_PROFILE_SUCCESS";
export const DASHBOARD_PROFILE_ERROR = "DASHBOARD_PROFILE_ERROR";
export const DASHBOARD_UPLOADING = "DASHBOARD_UPLOADING";
export const DASHBOARD_UPLOAD_SUCCESS = "DASHBOARD_UPLOAD_SUCCESS";
export const DASHBOARD_UPLOAD_ERROR = "DASHBOARD_UPLOAD_ERROR";
export const DASHBOARD_BACKGROUND_SUCCESS = "DASHBOARD_BACKGROUND_SUCCESS";
export const DASHBOARD_BACKGROUND_LOADING = "DASHBOARD_BACKGROUND_LOADING";
export const DASHBOARD_BACKGROUND_ERROR = "DASHBOARD_BACKGROUND_ERROR";
export const DASHBOARD_DELETE_SUCCESS = "DASHBOARD_DELETE_SUCCESS";
export const DASHBOARD_DELETE_LOADING = "DASHBOARD_DELETE_LOADING";
export const DASHBOARD_DELETE_ERROR = "DASHBOARD_DELETE_ERROR";
export const UPDATEPHOTO="UPDATEPHOTO";
export const UPDATEPHOTO_SUCCESS="UPDATEPHOTO_SUCCESS";
export const UPDATEPHOTO_ERROR="UPDATEPHOTO_ERROR";
const initialstate = {
  loading: false,
  error: null,
  jobs: [],
  recommendedJobs: [],
  userDetails: { jobs: [] },
  loading_jobs: false,
  all_jobs_fetched: false,
  uploading: false,
  uploaded: false,
  bg_details: {},
};

const dashboard = (state = initialstate, action) => {
  switch (action.type) {
    case DASHBOARD_PROFILE_LOADING:
      state = { ...state, loading: true, error: null };
      break;
    case DASHBOARD_PROFILE_ERROR:
      state = { ...state, loading: false, error: action.payload };
      break;
    case DASHBOARD_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        recommendedJobs: action.payload.recommendedJobs,
        userDetails: action.payload.user,
      };
      break;
    case DASHBOARD_JOBS_LOADING:
      state = { ...state, loading_jobs: true };
      break;
    case DASHBOARD_JOBS_ERROR:
      state = {
        ...state,
        loading_jobs: false,
        jobs_error: action.payload.error,
      };
      break;
    case DASHBOARD_JOBS_SUCCESS:
      state = {
        ...state,
        loading_jobs: false,
        jobs: [...state.jobs, ...action.payload.jobs],
        all_jobs_fetched: action.payload.jobs == [] ? true : false,
      };
      break;
    case DASHBOARD_UPLOAD_SUCCESS:
      const { id, key, link, type } = action.payload.newDoc;
      const bg_details = state.bg_details;
      const data = { id, key, link };
      switch (type) {
        case "PAN":
          state = {
            ...state,
            bg_details: { ...bg_details, PAN: data },
            uploading: false,
            error: null,
          };
          break;
        case "aadhaar":
          state = {
            ...state,
            bg_details: { ...bg_details, aadhaar: data },
            uploading: false,
            error: null,
          };
          break;
        case "passport":
          state = {
            ...state,
            bg_details: { ...bg_details, passport: data },
            uploading: false,
            error: null,
          };
          break;
        case "ed_documents":
          state = {
            ...state,
            bg_details: { ...bg_details, ed_documents: data },
            uploading: false,
            error: null,
          };
          break;

        default:
          state = { ...state, uploading: false, error: null };
          break;
      }

      break;

    case DASHBOARD_UPLOAD_ERROR:
      state = {
        ...state,
        uploading: false,
        error: action.payload,
      };
      break;

    case DASHBOARD_UPLOADING:
      state = { ...state, uploading: true, error: null };
      break;

    case DASHBOARD_BACKGROUND_SUCCESS:
      state = {
        ...state,
        bg_details: action.payload,
        loading: false,
        error: null,
      };
      break;

    case DASHBOARD_BACKGROUND_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case DASHBOARD_BACKGROUND_LOADING:
      state = { ...state, loading: true, error: null };
      break;
      case UPDATEPHOTO:
        state={
          ...state,
          loading:true,
          error:null
        }
        break;
    case UPDATEPHOTO_SUCCESS:
      const userDetails = { ...state.userDetails, image_url: action.payload };
      state={...state,userDetails,loading:false,error:null}
      break;
    case UPDATEPHOTO_ERROR:
      state={...state,loading:false,error:action.payload}
    case DASHBOARD_DELETE_SUCCESS:
      const doctype = action.payload.type;
      switch (type) {
        case "PAN":
          state = {
            ...state,
            bg_details: { ...bg_details, PAN: null },
            uploading: false,
            error: null,
          };
          break;
        case "aadhaar":
          state = {
            ...state,
            bg_details: { ...bg_details, aadhaar: null },
            uploading: false,
            error: null,
          };
          break;
        case "passport":
          state = {
            ...state,
            bg_details: { ...bg_details, passport: null },
            uploading: false,
            error: null,
          };
          break;
        case "ed_documents":
          state = {
            ...state,
            bg_details: { ...bg_details, ed_documents: null },
            uploading: false,
            error: null,
          };
          break;

        default:
          state = { ...state, uploading: false, error: null };
          break;
      }

      break;

    case DASHBOARD_DELETE_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case DASHBOARD_DELETE_LOADING:
      state = { ...state, loading: true, error: null };
      break;
    // case USER_DETAILS_LOADING:
    //     state={...state,loading:true}
    //     break;
    //     case JOB_DETAIL_LOADING:
    //         state={...state,loading:true}
    //         break;
    //     case USER_DETAILS_ERROR:
    //         state={...state,error:action.payload}
    //         break;

    //     case JOB_APPLY:
    //         state={...state,userdetails:{...action.payload},loading:false}
    //         break;
    //     case JOB_APPLY_LOADING:
    //         state={...state,loading:true}
    //         break;
    //     case JOB_APPLY_ERROR:
    //         state={...state,error:action.payload}
    //         break;
    // case JOB_DETAIL_ERROR:
    //     state={...state,error:action.payload}
    //     break;

    // case DASHBOARD_JOBS_LOADING:
    //     state={...state,loading:true}
    //     break;
    // case JOBS_ERROR:
    //     state={...state,error:action.payload}
    //     break;
    // case RECOMMENDED_JOBS_LOADING:
    //     state={...state,loading:true}
    //     break;
    // case RECOMMENDED_JOBS_ERROR:
    //     state={...state,error:action.payload}
    //     break;

    //     case USER_DETAILS:
    //     state={...state,userdetails:{...action.payload},loading:false}
    //     break;
    // case JOBS:
    //     state={...state,jobs:{...action.payload},loading:false}
    //     break;
    // case JOBS_RECOMMENDED:
    //     state={...state,JOBS_RECOMMENDED:{...action.payload},loading:false}
    //     break;
    // case JOB_DETAIL:
    //     state={...state,JOB_DETAIL:{...action.payload},loading:false}
    //     default:
    //     break;
  }
  return state;
};
export default dashboard;
