import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import classnames from "classnames";
import SavedRows from "../../../components/SavedRows";
import Select from "react-select";
import ApiService from "../../../config/restapi";
import DateRangePicker from "../../../components/DateRangePicker";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Common from "../../../helpers/common";

const EmploymentTypes = [
  {
    value: 1,
    label: "Full-Time Employee",
  },
  {
    value: 2,
    label: "Part-Time Employee",
  },
  {
    value: 3,
    label: "Temporary Employee",
  },
  {
    value: 4,
    label: "Seasonal Employee",
  },
];

const EmployementDetails = (props) => {
  const [showError, setShowError] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [employmentRows, setEmploymentRows] = useState([
    {
      role: "",
      city: "",
      company: "",
      emp_type: "",
      start_year: "",
      grad_year: "",
      stillworking: false,
    },
  ]);

  useEffect(() => {
    props.onNextClickEmploymentCallBack(employmentRows, editIndex);
  }, [employmentRows, editIndex]);

  function checkValidity(e) {
    if (
      !e.city ||
      !e.company ||
      !e.role ||
      !e.start_year ||
      (!e.stillWorking && !e.grad_year) ||
      (!e.stillWorking || e.start_year > e.grad_year)
    ) {
      return false;
    } else {
      return true;
    }
  }
  function handleAddEmploymentRow() {
    if (!checkValidity(employmentRows[0])) {
      setShowError(true);
      toastr.error("Enter all the required information");
      return;
    }
    ApiService.saveEmploymentDetailsOnBoarding(employmentRows).then((res) => {
      if (res && res.status === 200 && res.data) {
        props.updateEmpData();
        const item1 = {
          emp_type_obj: "",
          role: "",
          company: "",
          emp_type: "",
          start_year: "",
          grad_year: "",
          city: "",
          stillworking: false,
        };

        let updateERow = [...employmentRows];
        updateERow.splice(updateERow.length - 1, 1);
        let addIdx = updateERow.length > 0 ? updateERow.length - 1 : 0;
        updateERow.splice(addIdx, 0, item1);

        setEmploymentRows(updateERow);
        setShowError(false);
      }
    });
  }

  function deleteEmploymentRow(type, index) {
    const tempERow = [...props.getSavedRows];
    ApiService.deleteEmploymentDetailsOnBoarding({
      id: Number(tempERow[index].id),
    }).then((res) => {
      if (res && res.status === 200) {
        props.updateEmpData();
      }
    });
  }

  function findWithAttr(array, attr, value) {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  function editEmpRow(type, index) {
    if (index > -1) {
      const tempRowData = [...props.getSavedRows];
      const tempEmpData = [...employmentRows];
      let empTypeIndex = findWithAttr(
        EmploymentTypes,
        "label",
        tempRowData[index].emp_type
      );
      tempRowData[index].emp_type_obj = EmploymentTypes[empTypeIndex];
      Object.assign(tempEmpData[0], tempRowData[index]);
      setEmploymentRows(tempEmpData);
      setEditIndex(index);
    }
  }

  const handleChange = (selectedOption, index) => {
    let tempRow = [...employmentRows];
    tempRow[index].emp_type = selectedOption.label;
    tempRow[index]["emp_type_obj"] = selectedOption;
    setEmploymentRows(tempRow);
  };

  const onChangeEmpDetails = (index, value, type) => {
    console.log(value, type);
    //JSON.parse(JSON.stringify(educationalRows));
    let tempRow = [...employmentRows];
    console.log(tempRow[index]);
    switch (type) {
      case "role":
        value = value.replace(/[^a-zA-Z\s]/gi, "");
        tempRow[index].role = Common.trimStart(value);
        break;
      case "company":
        value = value.replace(/[^a-zA-Z\s]/gi, "");
        tempRow[index].company = Common.trimStart(value);
        break;
      // case "emp_type":
      //     tempRow[index].emp_type = value;
      //     break;
      case "start_year":
        tempRow[index].start_year = value;
        break;
      case "grad_year":
        tempRow[index].grad_year = value;
        break;
      case "stillworking":
        tempRow[index].stillworking = !tempRow[index].stillworking;
        if (!tempRow[index].stillworking === false) {
          tempRow[index]["grad_yearDisabled"] = true;
          tempRow[index].grad_year = "";
        } else {
          tempRow[index]["grad_yearDisabled"] = false;
        }
        break;
      case "city":
        value = value.replace(/[^a-zA-Z\s]/gi, "");
        tempRow[index].city = Common.trimStart(value);
        break;
      default:
        break;
    }
    setEmploymentRows(tempRow);
  };

  const handleSaveEditedData = () => {
    const tempRowData = [...props.getSavedRows];
    const tempEmpData = [...employmentRows];

    let obj = {
      ...tempEmpData[0],
      id: tempRowData[editIndex].id,
    };

    if (!checkValidity(obj)) {
      console.log("Invalid");
      setShowError(true);
      return;
    }

    ApiService.editEmploymentDetailsOnBoarding(obj).then((res) => {
      if (res && res.status === 200 && res.data) {
        props.updateEmpData();
        setEditIndex(-1);
        tempEmpData[0].role = "";
        tempEmpData[0].company = "";
        tempEmpData[0].emp_type = "";
        tempEmpData[0].start_year = "";
        tempEmpData[0].grad_year = "";
        tempEmpData[0].city = "";
        tempEmpData[0].stillworking = false;
        tempEmpData[0].emp_type_obj = "";
        tempEmpData[0].UserId = "";
        tempEmpData[0].id = "";
        setEmploymentRows(tempEmpData);
        setShowError(false);
      }
    });
  };

  function CheckAndPrint(date) {
    if (date) {
      if (typeof date === "object") {
        return date;
      } else {
        return new Date(date);
      }
    }
  }
  return (
    <TabContent
      activeTab={props.activeTab}
      className="twitter-bs-wizard-tab-content"
    >
      <TabPane tabId={3} className="employment-details text-left">
        {props.getSavedRows.length > 0 &&
          props.getSavedRows.map((data, index) => {
            return (
              <SavedRows
                key={index}
                data={data}
                index={index}
                editRow={editEmpRow}
                deleteRow={deleteEmploymentRow}
                type="employment"
              />
            );
          })}

        {employmentRows.map((item1, idx) => {
          return (
            <Form key={idx} className={classnames("pb-3")}>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <Label>Job Role</Label>
                    <Input
                      type="text"
                      maxLength="40"
                      className="form-control"
                      value={item1.role}
                      onChange={(event) =>
                        onChangeEmpDetails(idx, event.target.value, "role")
                      }
                    />
                    {showError && !item1.role && (
                      <label style={{ "margin-bottom": "0", color: "red" }}>
                        Job Role field cannot be blank.
                      </label>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label>Company</Label>
                    <Input
                      type="text"
                      maxLength="40"
                      value={item1.company}
                      onChange={(event) =>
                        onChangeEmpDetails(idx, event.target.value, "company")
                      }
                    />
                    {showError && !item1.company && (
                      <label style={{ "margin-bottom": "0", color: "red" }}>
                        Company name cannot be blank.
                      </label>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label>Employement type</Label>
                    <Select
                      className="select-container"
                      value={item1.emp_type_obj}
                      onChange={(selectedOption) =>
                        handleChange(selectedOption, idx)
                      }
                      options={EmploymentTypes}
                      isSearchable
                      innerProps={{ className: "test" }}
                      placeholder="Select"
                    />
                    {showError && !item1.emp_type_obj && (
                      <label style={{ "margin-bottom": "0", color: "red" }}>
                        Choose an Employment type from the dropdown above.
                      </label>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-0 text-left">
                    <Label>Start Year</Label>
                    <InputGroup>
                      <DateRangePicker
                        proptype="start"
                        propsref="startcalendar"
                        propsid="startyear"
                        selected={CheckAndPrint(item1.start_year)}
                        onChange={(date) =>
                          onChangeEmpDetails(idx, date, "start_year")
                        }
                        startDate={item1.start_year}
                        endDate={new Date().getFullYear()}
                        maxDate={item1.grad_year!==""?item1.grad_year:new Date()}
                      />
                      <label htmlFor="startyear">
                        <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                      </label>
                    </InputGroup>
                    {showError && !item1.start_year && (
                      <label style={{ "margin-bottom": "0", color: "red" }}>
                        Enter the starting year of the job.
                      </label>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-0 text-left">
                    <Label>End Year</Label>
                    <InputGroup>
                      <DateRangePicker
                        proptype="end"
                        propsref="endcalendar"
                        propsid="endyear"
                        selected={CheckAndPrint(item1.grad_year)}
                        onChange={(date) =>
                          onChangeEmpDetails(idx, date, "grad_year")
                        }
                        startDate={item1.start_year}
                        endDate={item1.grad_year}
                        minDate={item1.start_year}
                        maxDate={new Date()}
                        readOnly={item1.grad_yearDisabled}
                        
                      />
                      <label htmlFor="endyear">
                        {" "}
                        <i className="mdi mdi-calendar-blank-outline cust-date-icon"></i>
                      </label>
                    </InputGroup>
                    {showError &&
                      !item1.grad_year &&
                      !item1.grad_yearDisabled && (
                        <label style={{ "margin-bottom": "0", color: "red" }}>
                          Enter the ending year of the job.
                        </label>
                      )}

                    <FormGroup className="pt-2" check>
                      <Label className="main" check>
                        <Input
                          type="checkbox"
                          onChange={(event) =>
                            onChangeEmpDetails(
                              idx,
                              event.target.checked,
                              "stillworking"
                            )
                          }
                        />{" "}
                        I'm still working
                        <span className="geekmark"></span>
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label for="basicpill-colg-input1">Location</Label>
                    <Input
                      type="text"
                      maxLength="40"
                      value={item1.city}
                      onChange={(event) =>
                        onChangeEmpDetails(idx, event.target.value, "city")
                      }
                    ></Input>
                    {showError && !item1.city && (
                      <label style={{ "margin-bottom": "0", color: "red" }}>
                        Enter the city name.
                      </label>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          );
        })}
        <div className="cursor-pointer add-other text-right">
          {editIndex > -1 && (
            <button
              className="waves-effect waves-light btn btn-outline-shadow w-md mr-2"
              type="button"
              onClick={() => handleSaveEditedData()}
            >
              Save
            </button>
          )}

          <button
            className="waves-effect waves-light btn btn-outline-shadow w-md"
            type="submit"
            disabled={editIndex > -1 ? true : false}
            onClick={() => handleAddEmploymentRow()}
          >
            + Add Other Employment Details
          </button>
        </div>
      </TabPane>
    </TabContent>
  );
};

export default EmployementDetails;
