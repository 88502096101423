export const EDUCATION_DETAILS = 'EDUCATION_DETAILS';
export const EDUCATION_DETAILS_UPDATE = 'EDUCATION_DETAILS_UPDATE';
export const EDUCATION_DETAILS_LOADING = 'EDUCATION_DETAILS_LOADING';
export const EDUCATION_DETAILS_ERROR = 'EDUCATION_DETAILS_DETAIL';
export const EDUCATION_UPDATE = 'EDUCATION_UPDATE';
export const EDUCATION_DELETE = 'EDUCATION_DELETE';


const initialState = {
    loading: true,
    error: null,
    education: {}
}

const education = (state = initialState, action) => {
    switch (action.type) {
        
        case EDUCATION_DETAILS:
            state = { loading: false, error: null, success: action.payload.success}
            break;
        case EDUCATION_UPDATE:
            state = { loading: false, error: null, education: { ...action.payload } }
            break;
        case EDUCATION_DELETE:
            state = { loading: false, error: null, education: { ...action.payload } }
            break;
        case EDUCATION_DETAILS_LOADING:
            state = { ...state, loading: true };
            break;
        case EDUCATION_DETAILS_ERROR:
            state = { ...state, loading: false, error: action.payload };
            break;
        default:
            break;
    }
    return state;
}
export default education;