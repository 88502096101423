import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import image from '../../assets/images/userdummyimage/attachment_56777363-e1485987246720.jpg';
import apple from '../../assets/images/google.png'
export default class GoogleCard extends Component {
    render() {
        return (
            <Link to={`/jobs/${this.props.id}`} className="rem-link-def">
            <div className="box-200 mt-2 card-trans">
                <div className="d-flex justify-content-center">
                    <img className="company-logos mt-3" src={apple} alt="image-1"></img>
                </div>
                <div className="text-center mb-2 f-16">Google.com</div>
                <div className="text-center mb-2 f-18">UI DESIGNER</div>
            <div className="black-border"></div>
            <div className="row w-90 mx-auto mt-1">
                <div className="col-12 ">
                        <div className="f-12 float-right" style={{opacity:'0.7'}}><i className="far fa-clock mb-2 mr-1"></i>2 minutes ago</div>
                        <div className="f-12 float-left " style={{opacity:'0.7'}}><i className="fas fa-map-marker-alt mb-2 mr-1"></i>Minneapolis,USA</div>
                        
                        </div></div>
       </div>
       </Link>
        )
    }
}
