import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from 'classnames';
import professional_icon from '../../assets/images/professional_icon.png';
import beginner_icon from '../../assets/images/begineer_icon.png';
import intermidiate_icon from '../../assets/images/intermidiate_icon.png';
import Select from 'react-select';
import { Skills } from '../../helpers/constant_variables';


const AddSkillsModal = (props) => {

    const [skillsLearned, setSkillsLearned] = useState(["Cloud Computing", "Software Development", "Information/Cyber Security"])
    const [proficiencyLevelArray, setProficiencyLevelArray] = useState([{ proficiency: 'beginner' }, {
        proficiency: 'intermidiate'
    }, { proficiency: 'professional' }]);
    
    const [searchBoxRow, setSearchBoxRow] = useState([1]);

    const [selectedOption, setSelectedOption] = useState();

    const [skillField, setSkillField] = useState([]);
    const [skillFieldData, setSkillFieldData] = useState([]);


    const handleChange = (selectedOption) => {
        let tempSkillField = [...skillField];
        if(!skillField.find((i)=>{return i.label===selectedOption.label})) {
            selectedOption['proficiency'] = 'beginner';
            tempSkillField.push(selectedOption);
            setSkillField(tempSkillField);
        }
    };

    const handleTopIndustySkills = (item) => {
        let option=Skills.find((i)=>{return i.label===item});
        let tempSkillField = [...skillField];
        option['proficiency'] = 'beginner';
        tempSkillField.push(option);
        console.log(tempSkillField);
        setSkillField(tempSkillField);
    };


    const dotHandler = (index, proficiency) => {
       const tempData = [...skillField];
        tempData.map((val, idx) => {
            if (idx === index) {
                val["proficiency"] = proficiency;
            }
        });
        setSkillField(tempData);
    }
    const deleteRow = (index) => {
        var tempData = [...skillField];
        tempData = tempData.filter((val, id) => {
            return (id !== index)
        })
        setSkillField(tempData);
    }
    const onSave = (type) => {
        if (type === 'save') {
            props.onModalSubmit({ searchValObj: selectedOption, proficiency: proficiencyLevelArray, skills: skillField }, type);
            setSkillField([]);
            setSkillFieldData(
                {
                    ...skillFieldData,
                    skillField: []
                }
            );
        }
    }

    // const handleChange = (value, type) => {
    //     switch (type) {
    //         case 'course':
    //             try {
    //                 if (!value) {
    //                     setSkillField([]);
    //                     setSkillFieldData({
    //                         ...skillFieldData,
    //                         skillField: value.map((e) => e.label),
    //                     });
    //                     break;
    //                 }
    //                 setSkillField(value.map((e) => e.label));
    //                 setSkillFieldData({
    //                     ...skillFieldData,
    //                     skillField: value.map((e) => e.label),
    //                 })
    //                 break;
    //             }
    //             catch (e) {
    //                 setSkillField([]);
    //                 setSkillFieldData({ ...skillFieldData, skillField: [] });
    //             }
    //             break;
    //         default:
    //             break;
    //     }
    // }

    
    return (
        <Modal
            size="lg"
            isOpen={props.modal_center}
            centered={props.centered}
            className="add-skills-modal"
        >
            <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                    Add Skills
                </h6>
                <button
                    type="button"
                    onClick={() => { props.tog_center(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {searchBoxRow && searchBoxRow.length > 0 && searchBoxRow.map((v, index) => {
                    return (

                        <Row key={index} className="add-skill-container">
                            <Col lg="12">
                          
                                {/* <FormGroup  className="text-left mb-1">
                                    <Label className="mb-1"> Add Skill  </Label>
                                    <Select
                                        option
                                        className="select-container multiple-select"
                                        value={skillFieldData ? Array.isArray(skillFieldData.skillField) ? skillFieldData.skillField.map(e => { return({value:e,label:e})}):[] : []}
                                        onChange={(selectedOption) => handleChange(selectedOption, 'course')}
                                        options={Skills.map((e) => { return({label:e.label, value:e.label})})}
                                        isSearchable 
                                        innerProps={{className:"string"}}
                                        placeholder="Select"
                                        isMulti={true}
                                    />
                                    <Input type="text" value={skillField || ''} onChange={(event)=> setSkillField(event.target.value) }/>
                                </FormGroup> */}
                            </Col>
                            <Col lg="4">
                                <FormGroup className="text-left mb-1">
                                    <Label className="mb-1"> Search & Add Skill  </Label>
                                    <Select
                                        className="select-container"
                                        value={"Search"}
                                        onChange={handleChange}
                                        options={Skills}
                                        isSearchable

                                        innerProps={{ className: "string" }}
                                        placeholder="Search"
                                        selectProps={{ "className": "Hi" }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup className="text-center mb-1">
                                    <Label className="mb-1 "> Select Proficiency Level </Label>
                                    <div>
                                      
                                    </div>
                                </FormGroup>
                            </Col>



                        </Row>

                    )
                })}
                {skillField.map((e, index) => {
                    return (

                        <Row key={index} className="add-skill-container">

                            <Col lg="4">
                                <FormGroup className="text-left mb-1">
                                    <Select
                                        className="select-container"
                                        value={e}
                                        isSearchable={false}

                                        onChange={(val) => { }}
                                        innerProps={{ className: "string" }}
                                        selectProps={{ "className": "Hi" }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="5">
                                <FormGroup className="text-left mb-1 ml-3">
                                    <div>
                                        {proficiencyLevelArray.map((data, i) => {
                                            return (
                                                <div className='proficiency-container float-left mr-3' key={i} onClick={() => dotHandler(index, data.proficiency)}>
                                                    {data.proficiency === 'beginner' && <img src={beginner_icon} alt='skills' />}
                                                    {data.proficiency === 'intermidiate' && <img src={intermidiate_icon} alt='skills' />}
                                                    {data.proficiency === 'professional' && <img src={professional_icon} alt='skills' />}
                                                    <div 
                                                        className={classnames('font-size-11 font-weight-light text-transform','custom_skill_level',{'custom_skill_level_highlight':e.proficiency === data.proficiency}, { 'font-weight-bold': e.proficiency === data.proficiency })}>
                                                        {data.proficiency === 'beginner' && "Beginner"}
                                                        {data.proficiency === 'intermidiate' && "Intermediate"}
                                                        {data.proficiency === 'professional' && "Professional"}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col lg="3" className='text-right'>
                                <button className="waves-effect waves-light btn btn-outline-shadow w-xs font-size-11 p-1" type="button" onClick={() => deleteRow(index)} >
                                    <i className="mdi mdi-delete-outline font-size-14 cursor-pointer label-required"></i> <span className='label-required'> REMOVE SKILL</span>
                                </button>
                            </Col>
                            <Col>
                            </Col>
                        </Row>

                    )
                })}


                <Row className="top-industry-container">
                    <Col lg="12">
                        <div className="industry">
                            <p className="mb-2 pt-2">
                                Top Industry Skills
                            </p>
                            <div>
                                <ul className="skills-learns">
                                    {skillsLearned && skillsLearned.map((item, idx) => {
                                        return (
                                            <li 
                                            className={classnames("d-inline-block mr-2 cursor-pointer", { 'mb-3': (skillsLearned.length - 1) !== idx })}
                                             key={idx}
                                             onClick={() => handleTopIndustySkills(item)}
                                             >
                                                <span className="skills-items"> {item} + </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => { props.tog_center() }}
                    className="btn font-light-green btn-outline-light-green waves-effect waves-light w-lg mr-2"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn bg-light-green waves-effect waves-light w-lg"
                    disabled={skillField && skillField.length > 0 ? false : true}
                    onClick={() => { onSave('save') }}
                >Add Skills </button>

            </div>

        </Modal>
    );
}

export default AddSkillsModal;