import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card1 from "../Cards/Card";
import { Card, CardBody, Row, Col } from "reactstrap";
import company_img from "../../assets/images/company.svg";
import location from "../../assets/images/location.svg";
import timeSince from '../../helpers/timeSinceFormatter.js';

const Jobscard = (props) => {


    return (
        <Card className='job-side-container ml-4 green-text-color'>
            <CardBody className='pl-0 pr-0 overflow-hidden' >
                <p className='pl-3 font-size-14 font-weight-medium mb-3 works'>
                    RECOMMENDED JOBS ({props.jobs.length})
                </p>
                {
                    props.jobs.length === 0 ? <div className='no-result'>Could not find Jobs</div> :
                        <ul className='pl-0 list-unstyled'>
                            {props.jobs.map((item, index) => {
                                return (
                                    <li key={index} className={`list-item-container ${props.selectedId === item.job.id ? 'selected' : ''}`} onClick={() => props.jobClickCallback(item.job.id)}>
                                        <div className='list-item'>
                                            <div className='font-size-11 text-muted font-weight-light text-right'>
                                                {timeSince(new Date(item.job.createdAt))} ago
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div className='font-size-16 cut-text-ellipsis'>
                                                    {item.job.title}
                                                </div>
                                            </div>
                                            <div className='mt-3 cut-text-ellipsis'>
                                                <img className='pr-1 mr-1' src={company_img} alt='company' />{item.job.company}
                                            </div>
                                            <div className='mt-2 cut-text-ellipsis'>
                                                <img src={location} className='pr-1 mr-1' alt='loc' />
                                                {item.job.is_remote_loc ? 'Work from home' : item.job.location}
                                            </div>

                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                }

            </CardBody>
        </Card>
    )
}

export default Jobscard;