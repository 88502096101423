import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import blankImg from "../../assets/images/users/blank_profile.svg";
import classnames from "classnames";
import phone_icon from "../../assets/images/phone_icon.png";
import email_icon from "../../assets/images/email_icon.png";
import linkedin_icon from "../../assets/images/linkedin_icon.png";
import ApiService from "../../config/restapi";
import api from "../../config/axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import s3api from "../../helpers/s3apis";

// Redux
//import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import About from "./About";
import Education from "./Education";
import Employment from "./Employment";
import Candidature from "./Candidature";
import Skills from "./Skills";
import Courses from "./Courses";
import Certification from "./Certification";
import EditProfileModal from "./EditProfileModal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllUserData } from "../../redux/actions/user";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import {
  addEducationData,
  updateEducationData,
  deleteEducationData,
} from "../../redux/actions/profile/education";
import {
  addEmpData,
  updateEmpData,
  deleteEmpData,
} from "../../redux/actions/profile/emp";
import ViewBackgroundVModal from "./ViewBackgroundVModal";

const Profile = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  //Edit Profile
  const [modal_center, setmodal_center] = useState(false);

  //View Document
  const [modal_bg, setmodal_bg] = useState(false);

  ///Education State
  const [savedRowsEducation, setSavedRowsEducation] = useState([]);
  const [educationalEditedData, setEducationalEditedData] = useState();

  //On Save or Edit Education Modal Data
  const createEducationalRow = (data, type) => {
    if (type === "save") {
      let obj = {
        college: data.college,
        degree: data.degree,
        course: data.course,
        start_year: data.sDate,
        grad_year: data.eDate,
        still_studying: data.stillStudying,
      };
      ApiService.saveEducationDetailsOnBoarding([obj]).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEducationData();
        }
      });
    } else {
      let obj = {
        id: Number(data.id),
        college: data.college,
        degree: data.degree,
        course: data.course,
        start_year: data.sDate,
        grad_year: data.eDate,
        still_studying: data.stillStudying,
      };
      ApiService.editEducationDetailsOnBoarding(obj).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEducationData();
        }
      });
    }
  };

  //Set Edited Data on Education Modal
  const editEducationalRow = (row, type) => {
    const tempEditedData = savedRowsEducation.filter((v) => v.id === row.id);
    if (tempEditedData.length > 0) setEducationalEditedData(tempEditedData[0]);
  };

  //Delete Education Row
  const deleteEducationalRow = (row) => {
    ApiService.deleteEducationDetailsOnBoarding({ id: Number(row.id) }).then(
      (res) => {
        if (res && res.status === 200) {
          updateEducationData();
        }
      }
    );
  };

  //Reload Education Data
  function updateEducationData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.status === 200 && res.data) {
        setSavedRowsEducation([...res.data.educations]);
      }
    });
  }

  const setEducationalDataNull = () => {
    if (educationalEditedData) setEducationalEditedData("");
  };

  //Employment State
  const [savedRowsEmp, setSavedRowsEmp] = useState([]);
  const [empEditedData, setEmpEditedData] = useState();

  //On Save or Edit Employment Modal Data
  const createEmpRow = (data, type) => {
    
    if (type === "save") {
      let obj = {
        role: data.jobrole,
        company: data.company,
        emp_type: data.empType,
        city: data.location,
        start_year: data.sDate,
        grad_year: data.eDate,
        stillWorking: data.stillWorking,
        description: data.description,
      };
      console.log(obj);

      ApiService.saveEmploymentDetailsOnBoarding([obj]).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEmpData();
        }
      });
    } else {
      let obj = {
        id: Number(data.id),
        role: data.jobrole,
        company: data.company,
        emp_type: data.empType,
        city: data.location,
        start_year: data.sDate,
        grad_year: data.eDate,
        stillWorking: data.stillWorking,
        description: data.description,
      };
      console.log(obj);
      ApiService.editEmploymentDetailsOnBoarding(obj).then((res) => {
        if (res && res.status === 200 && res.data) {
          updateEmpData();
        }
      });
    }
  };

  //Set Edited Data on Employment Modal
  const editEmpRow = (row, type) => {
    const tempEditedData = savedRowsEmp.filter((v) => v.id === row.id);
    if (tempEditedData.length > 0) setEmpEditedData(tempEditedData[0]);
  };

  //Delete Employment Row
  const deleteEmpRow = (row) => {
    try {
      ApiService.deleteEmploymentDetailsOnBoarding({ id: Number(row.id) }).then(
        (res) => {
          if (res && res.status === 200) {
            updateEmpData();
          } else {
            toastr.error("Something Went Wrong", "", { timeOut: 2000 });
          }
        }
      );
    } catch (e) {
      toastr.error("Something Went Wrong", "", { timeOut: 2000 });
    }
  };

  //Reload Employment Data
  function updateEmpData() {
    ApiService.getProfileData().then((res) => {
      if (res && res.status === 200 && res.data) {
        setSavedRowsEmp([...res.data.experiences]);
      }
    });
  }

  const setEmpDataNull = () => {
    if (empEditedData) setEmpEditedData("");
  };

  //Certificates
  const [savedRowsCertificates, setSavedRowsCertificate] = useState([]);
  const [certificateEditedData, setCertificateEditedData] = useState();

  const createCertificate = async (data, type) => {
    // api
    //   .post("api/jobs/upload/resume", data.cert, {
    //     headers: {
    //       token: `${localStorage.getItem("token")}`,
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   })
    //   .then((result) => {
    // data.cert = undefined;
    // data.link = result.data.data.Location;
    api
      .post("/api/certificates/add", data, {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(async (res) => {
        console.log("res");
        console.log(res);
        console.log(data);
        var ext = data.cert[0].name.split(".").pop();
        var id = res.data.id;
        if (data.cert[0].size < 5242880) {
          await s3api.upload("certificate", ext, data.cert[0], id);
        } else {
          toastr.error("Select a file of size less than 5 MB", "", {
            timeOut: 2000,
          });
        }

        toastr.success("Certificate Added Successfully!", "", {
          timeOut: 2000,
        });
        getAllCertificates();
      })
      .catch((e) => {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      });
  };
  const editCertificate = async (data) => {
    if (data.cert) {
      // api
      //   .post("api/jobs/upload/resume", data.cert, {
      //     headers: {
      //       token: `${localStorage.getItem("token")}`,
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   })
      //   .then((result) => {
      //     data.cert = undefined;
      //     data.link = result.data.data.Location;
      api
        .put("/api/certificates/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(async (res) => {
          console.log(data);
          console.log(data.cert[0]);
          var ext = data.cert[0].name.split(".").pop();
          var id = data.id;
          if (data.cert[0].size < 5242880) {
            await s3api.upload("certificate", ext, data.cert[0], id);
          } else {
            toastr.error("Select a file of size less than 5 MB", "", {
              timeOut: 2000,
            });
          }
          getAllCertificates();
          toastr.success("Certificate Updated Successfully!", "", {
            timeOut: 2000,
          });
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    } else {
      api
        .put("/api/certificates/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log(data);
          // console.log(data.cert[0]);
          // var ext = data.cert[0].name.split(".").pop();
          // var id = data.id;
          // if (data.cert[0].size < 5242880) {
          //   s3api.upload("certificate", ext, data.cert[0], id);
          // } else {
          //   toastr.error("Select a file of size less than 5 MB", "", {
          //     timeOut: 2000,
          //   });
          // }
          getAllCertificates();
          toastr.success("Certificate Updated Successfully!", "", {
            timeOut: 2000,
          });
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    }
  };

  //Courses
  const [savedRowsCourses, setSavedRowsCourses] = useState([]);
  const [courseEditedData, setCourseEditedData] = useState();
  const [years, setYears] = useState();

  const createCourse = (data, type) => {
    // api
    //   .post("api/jobs/upload/resume", data.cert, {
    //     headers: {
    //       token: `${localStorage.getItem("token")}`,
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   })
    //   .then((result) => {
    //     data.cert = undefined;
    //     data.link = result.data.data.Location;
    api
      .post("/api/courses/add", data, {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(async (res) => {
        console.log(data);
        console.log(data.cert[0]);
        var ext = data.cert[0].name.split(".").pop();
        var id = res.data.id;
        if (data.cert[0].size < 5242880) {
          await s3api.upload("course", ext, data.cert[0], id);
        } else {
          toastr.error("Select a file of size less than 5 MB", "", {
            timeOut: 2000,
          });
        }
        toastr.success("Course Added Successfully!", "", { timeOut: 2000 });
        getAllCourses();
      })
      .catch((e) => {
        toastr.error("Something Went Wrong", "", { timeOut: 2000 });
      });
    // });
  };
  const editCourse = (data) => {
    if (data.cert) {
      // api
      //   .post("api/jobs/upload/resume", data.cert, {
      //     headers: {
      //       token: `${localStorage.getItem("token")}`,
      //       "Access-Control-Allow-Origin": "*",
      //     },
      //   })
      //   .then((result) => {
      //     data.cert = undefined;
      //     data.link = result.data.data.Location;
      api
        .put("/api/courses/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then(async (res) => {
          console.log(data);
          console.log(data.cert[0]);
          var ext = data.cert[0].name.split(".").pop();
          var id = data.id;
          if (data.cert[0].size < 5242880) {
            await s3api.upload("course", ext, data.cert[0], id);
          } else {
            toastr.error("Select a file of size less than 5 MB", "", {
              timeOut: 2000,
            });
          }
          toastr.success("Course Updated Successfully!", "", {
            timeOut: 2000,
          });
          getAllCourses();
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    } else {
      api
        .put("/api/courses/update", data, {
          headers: {
            token: `${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log(data);
          // console.log(data.cert[0]);
          // var ext = data.cert[0].name.split(".").pop();
          // var id = data.id;
          // if (data.cert[0].size < 5242880) {
          //   s3api.upload("course", ext, data.cert[0], id);
          // } else {
          //   toastr.error("Select a file of size less than 5 MB", "", {
          //     timeOut: 2000,
          //   });
          // }
          toastr.success("Course Updated Successfully!", "", { timeOut: 2000 });
          getAllCourses();
        })
        .catch((e) => {
          toastr.error("Something Went Wrong", "", { timeOut: 2000 });
        });
    }
  };

  //Edit Profile

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const openModal = () => {
    tog_center();
  };

  function tog_bg() {
    setmodal_bg(!modal_bg);
    removeBodyCss();
  }

  const updateProfileData = (data, type) => {
    if (type === "edit") {
      ApiService.editProfileData(data).then((res) => {
        if (res && res.status === 200 && res.data) {
          props.getAllUserData();
          if (!props.error) {
            tog_center(false);
            toastr.success("Profile Updated", "", { timeOut: 2000 });
          }
        }
      });
    }
  };

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }
  useEffect(() => {
    getAllCertificates();
    getAllCourses();
  }, []);
  const getAllCourses = () => {
    api
      .get("/api/courses/all", {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("HERERER");
        setSavedRowsCourses(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log("HERERER");
        setSavedRowsCourses([]);
        console.log(e);
      });
  };
  const getAllCertificates = () => {
    api
      .get("/api/certificates/all", {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("HERERER");
        setSavedRowsCertificate(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log("HERERER");
        setSavedRowsCertificate([]);
        console.log(e);
      });
  };

  useEffect(() => {
    props.getAllUserData();
    if (activeTab === "2") {
      let yt = [];
      props.user.educations.map((ed)=>{
        yt.push({start_year:new Date(ed.start_year).getFullYear(),end_year:new Date(ed.grad_year).getFullYear()})
      })
      console.log(yt);
      setYears(yt)
      setSavedRowsEducation(props.user.educations);
    }
    if (activeTab === "3") {
      setSavedRowsEmp(props.user.experiences);
    }
  }, [activeTab]);

  return (
    <React.Fragment>
      <div className="page-content pro-padding">
        <Container className="profile-page" fluid>
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <Row className="pt-3">
                  <Col
                    xs="12"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="profile-logo-container profile-user-wid mb-4">
                      <div className="profile-logo-content">
                        <img
                          src={
                            props.user.image_url
                              ? props.user.image_url
                              : blankImg
                          }
                          alt=""
                          className="avatar-sm"
                        />
                      </div>
                    </div>
                    <span className="right-pencil-section">
                      <i
                        className="bx bx-pencil font-size-18 cursor-pointer"
                        onClick={() => {
                          openModal();
                        }}
                      ></i>
                    </span>
                  </Col>
                </Row>
                <CardBody className="pt-0 text-center">
                  <Row>
                    <Col sm={12}>
                      <p className="font-size-16 text-truncate mb-1 font-weight-light">
                        {props.user.name}
                      </p>
                      <p className="font-size-18 mb-0 font-weight-normal light-green-text-color">
                        {props.user.designation}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="overflow-hidden">
                <div className="font-size-16 pl-4 pt-4 pr-4 mb-2">
                  Contact Info
                </div>
                <CardBody className="text-left pt-0">
                  <Row>
                    <Col sm={12}>
                      <div className="mb-2">
                        <img src={email_icon} alt="phone" />
                        <span className="font-size-10 text-muted pl-2">
                          {props.user.email}
                        </span>
                      </div>
                      <div className="mb-2">
                        <img src={phone_icon} alt="phone" />
                        <span className="font-size-10 text-muted pl-2">
                          {props.user.isd_code
                            ? "+" + getCountryCallingCode(props.user.isd_code)
                            : ""}{" "}
                          {props.user.phone ? props.user.phone : "--"}
                        </span>
                      </div>
                      {
                        props.user.linked_in_url && (
                          <div className="mb-2" style={{paddingLeft:"1px"}}>
                            <img src={linkedin_icon} alt="phone"/>
                            <span className="font-size-10 text-muted pl-2">
                              {props.user.linked_in_url}
                            </span>
                          </div>
                        )
                      }
                      
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="overflow-hidden">
                <div className="font-size-16 pl-4 pt-4 pr-4 mb-2">
                  Documents
                </div>
                <CardBody className="text-left pt-0">
                  <Row>
                    <Col sm={12}>
                      <button
                        className="waves-effect waves-light btn btn-outline-shadow w-xs font-size-11"
                        type="button"
                        onClick={() => tog_bg()}
                      >
                        View
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8" className="scrollable">
              <Nav tabs className="scrollable">
                <NavItem>
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    About
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      ("cursor-pointer",
                      classnames({
                        active: activeTab === "2",
                      }))
                    }
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Educational Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      ("cursor-pointer",
                      classnames({
                        active: activeTab === "3",
                      }))
                    }
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Employment Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      ("cursor-pointer",
                      classnames({
                        active: activeTab === "4",
                      }))
                    }
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Candidature
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      ("cursor-pointer",
                      classnames({
                        active: activeTab === "5",
                      }))
                    }
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    Skills
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={
                      ("cursor-pointer",
                      classnames({
                        active: activeTab === "6",
                      }))
                    }
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    Courses
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      ("cursor-pointer",
                      classnames({
                        active: activeTab === "7",
                      }))
                    }
                    onClick={() => {
                      toggle("7");
                    }}
                  >
                    Certifications
                  </NavLink>
                </NavItem>
              </Nav>
              <About
                activeTab={activeTab}
                userData={props.user}
                getAllUserData={() => props.getAllUserData()}
              />
              <Education
                activeTab={activeTab}
                savedRowsEducation={savedRowsEducation}
                createEducationalRow={createEducationalRow}
                editEducationalRow={editEducationalRow}
                educationEditedData={educationalEditedData}
                setEducationalDataNull={setEducationalDataNull}
                deleteEducationalRow={deleteEducationalRow}
                years={years}
              />
              <Employment
                activeTab={activeTab}
                savedRowsEmp={savedRowsEmp}
                createEmpRow={createEmpRow}
                editEmpRow={editEmpRow}
                empEditedData={empEditedData}
                setEmpDataNull={setEmpDataNull}
                deleteEmpRow={deleteEmpRow}
              />
              <Candidature
                activeTab={activeTab}
                userData={props.user}
                getAllUserData={() => props.getAllUserData()}
              />
              <Skills activeTab={activeTab} userData={props.user} />
              <Courses
                activeTab={activeTab}
                userData={props.user}
                savedRowsCourses={savedRowsCourses}
                setSavedRowsCourses={setSavedRowsCourses}
                createCourse={createCourse}
                editCourse={editCourse}
                courseEditedData={courseEditedData}
                setEmpDataNull={setEmpDataNull}
                deleteEmpRow={deleteEmpRow}
                getAllCourses={getAllCourses}
              />
              <Certification
                activeTab={activeTab}
                userData={props.user}
                savedRowsCertificates={savedRowsCertificates}
                setSavedRowsCertificate={setSavedRowsCertificate}
                createCertificate={createCertificate}
                editCertificate={editCertificate}
                certificateEditedData={certificateEditedData}
                setEmpDataNull={setEmpDataNull}
                deleteEmpRow={deleteEmpRow}
                getAllCertificates={getAllCertificates}
              />
              {/* {activeTab === "1" && <div className='text-right mt-2'>
                                <button
                                    type="button"
                                    className="btn bg-green waves-effect waves-light"
                                    onClick={() => { openModal() }}
                                    >Edit Profile
                                </button>
                            </div>} */}
            </Col>
          </Row>
        </Container>
      </div>
      <EditProfileModal
        modal_center={modal_center}
        centered={true}
        tog_center={() => tog_center()}
        onModalSubmit={updateProfileData}
        editedData={props.user}
      />
      <ViewBackgroundVModal
        modal_center={modal_bg}
        centered={true}
        tog_center={() => tog_bg()}
        editedData={props.user && props.user.background_d}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.user,
    ...state.education,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllUserData: getAllUserData,

      addEducationData: addEducationData,
      updateEducationData: updateEducationData,
      deleteEducationData: deleteEducationData,

      addEmpData: addEmpData,
      updateEmpData: updateEmpData,
      deleteEmpData: deleteEmpData,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
