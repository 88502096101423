import React, { Component } from 'react'
import image from '../../assets/images/userdummyimage/attachment_56777363-e1485987246720.jpg';
export default class AssessmentCard extends Component {
    render() {
        return (
            <div>
                 <div className="row">
                <div className="box-200 width-250 mt-1">
                <div className="ml-4">
                    <img className="company-logos-assessment my-3" src={image} ></img>
                </div>
                <div className="ml-4 mb-1">Macular Degeneration in AR</div>
                <div className="mb-2 ml-4 ">Lorem Ipsum is simply dummy text of the printing and ince the 150</div>
            <div className="d-flex">
                <div className="ml-4 mr-2 gray-round-box">Design</div>
                <div className="mx-2 gray-round-box">Problem Solving</div>
                <div className="mx-2 gray-round-box">+2</div>
            </div>
            <div className="d-flex justify-content-between box-ass py-3"> 
                 <div className="d-flex ml-4">
                     <div>Sponsored by</div>
                     <div className="gray-round-box-r ml-2">F</div>
                 </div>
                 <div>
                 <i class="far fa-share-square mr-3 fa-lg"></i>
                 </div>
            </div>
       </div>
                </div>
            </div>
        )
    }
}
